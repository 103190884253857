
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import {
	Row,
	Col,
	FormGroup,
	Label,
	Input,
	Button,
	Spinner,
} from "reactstrap";

class Attendance extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaded: true,
			error: false,
			selectStudent:'',
		};
		this.onInputchange = this.onInputchange.bind(this);
	}

	setLoading = e => {
		this.setState({ loaded: e })
	};

	setError = e => {
		this.setState({ error: e })
	};

	onInputchange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});

	}


	onFormSubmit(e) {
		e.preventDefault();
		this.setLoading(false);
		this.setError(false);
		axios.get("https://script.google.com/macros/s/AKfycbxpYsO7UlX461m7HJl2VVq8fFTYs5pG6CEtD4djrTUX90dfEz9MuUvttmG47FLlwD7N/exec?email=" + this.props.profile.email + "&startDate=" + this.state.AttendanceStartDate + "&endDate=" + this.state.AttendanceEndDate + "&name=" + this.state.AttendanceName + "&emailtoproduce=" + this.state.AttendanceFor)
			.then(res => {
				const pdfWindow = window.open();
				pdfWindow.location.href = res.data; 
				this.setLoading(true);
				this.state.AttendanceName = ""
				this.state.AttendanceStartDate = ""
				this.state.AttendanceEndDate = ""
				this.forceUpdate();
			})
			.catch(error => {
				console.log(error);
				this.setLoading(true);
				this.setError(false);
			})
	}


	render() {

		const {auth, profile,usersDB,t} = this.props;



		if (!auth.uid) {
			return <Redirect to="/signin" />;
		}
		if (!usersDB) {
			return <Redirect to="/" />;
		}

		const userSort =  [...usersDB ] ;

		return (
			<div className="dashboard container mx-auto">

				<h4 className="my-4">{t('attendance_title')}</h4>

				<div className="row mt-3">
					<div className="col-lg-8 col-md-12">
						<form onSubmit={(e) => this.onFormSubmit(e)}>
						{(profile.role != null && (profile.role.indexOf("admin") > -1 || profile.role.indexOf("studentAdmin") > -1 )) && (
						
							<FormGroup row>
								<Label sm={3}>{t('attendance_choose_student')}</Label>
								<Col sm={9}>
									<Input
										type="select"
										name="AttendanceFor"
										id="AttendanceFor"
										onChange={this.onInputchange}
										value={this.state.AttendanceFor}
										style={{ height: '50px' }} // Adjust this value as needed
									>
									<option value="" disabled selected>
									{t('attendance_select_student')}
												</option>
									{userSort.sort((a, b) => a.firstname.localeCompare(b.firstname)).map(item => {
										return <option value={item.email}>{item.firstname}  {item.lastname} - ({item.email})</option>;
									})}
									</Input>
								</Col>
							</FormGroup>
							)}
							<FormGroup row>
								<Label sm={3}>{t('attendance_file_name')}</Label>
								<Col sm={9}>
									<Input
										required
										type="text"
										name="AttendanceName"
										id="AttendanceName"
										onChange={this.onInputchange}
										value={this.state.AttendanceName}
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={3}>{t('attendance_start_date')}</Label>
								<Col sm={9}>
									<Input
										required
										type="date"
										min={new Date()}
										name="AttendanceStartDate"
										id="AttendanceStartDate"
										onChange={this.onInputchange}
										value={this.state.AttendanceStartDate}

									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={3}>{t('attendance_end_date')}</Label>
								<Col sm={9}>
									<Input
										required
										type="date"
										min={new Date()}
										name="AttendanceEndDate"
										id="AttendanceEndDate"
										onChange={this.onInputchange}
										value={this.state.AttendanceEndDate}

									/>
								</Col>
							</FormGroup>
							<Row>
								<Col sm={{ size: 9, offset: 3 }}>
									{!this.state.loaded ? (
										<Button variant="primary" disabled>
											<Spinner
												as="span"
												animation="grow"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											 {t('attendance_processing_creation')}
										</Button>) : (
											<Button color="primary" block
											>
												{t('attendance_generate')}
											</Button>
										)}
									{this.state.error ? (
										<div className="center red-text-big">{t('attendance_oups')}</div>
									) : (<div></div>)}

								</Col>
							</Row>
						</form>
						<center><h4 className="my-4">{t('attendance_attendance_available')}</h4></center>


						{profile.attendanceRecord != null && profile.attendanceRecord.sort((a, b) => a.productionDate> b.productionDate ? -1 : 1).map(attendance => {
							return (
								<div className="card mt-3">
									<div className="card-body black-text">
										<Row className="align-items-center">
											<Col md="4" sm="3">

												<span>
													<center>
														<strong>{t('attendance_name')}</strong><br></br>
														<a href={attendance.link} target="_blank">{attendance.name}</a>
													</center>
												</span>

											</Col>
											<Col md="4" sm="3">

												<span>
													<center>
														<strong>{t('attendance_date_range')}</strong><br></br>
														{attendance.startDate}{" to "}{attendance.endDate}</center>
												</span>
											</Col>
											<Col md="4" sm="6">
												<span>
													<center>
														<strong>{t('attendance_production_date')}</strong><br></br>
														{attendance.productionDate}
													</center>
												</span>
											</Col>
										</Row>
									</div>
								</div>)
						})
						}
					</div>
				</div>
				<br></br>
											<center><b>{this.props.t('sprint_need_help')}<a href="mailto:hello@groupellipse.com"> hello@groupellipse.com</a></b></center>
											<br></br>
			</div>

		);
	}
}

const mapState = state => {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		usersDB: state.firestore.ordered.users,
	};
};




export default compose(
	connect(mapState),
	firestoreConnect([{ collection: "users" }]),
	withTranslation()
)(Attendance);

