import React, { Component } from "react";
import SprintAdministration from "./sprintsAdministrationFunction";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class SprintAdministrationComponent extends Component {
	render() { 
		const { auth, profile,meetings} = this.props;

		if (!auth.uid) {
			return <Redirect to="/signin" />;
		}
		return (
			<div className="dashboard container">
				<div className="row">
					<div className="col s12">
					<SprintAdministration
					profile={profile}
					sprintsDB={this.props.sprint}
				/>

					</div>
				</div>
			</div>
		);
	}
}

const mapState = state => {
	return {
		sprint: state.firestore.ordered.sprints,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
	};
};

export default compose(
	connect(mapState),
	firestoreConnect([{ collection: "sprints" }])
)(SprintAdministrationComponent);