import social from "firebase/app";
import fire from "firebase/app";
import axios from 'axios';
require("firebase/firestore");
import emailjs from '@emailjs/browser';
import { fa } from "faker/lib/locales";

// Login Action
/*export const signIn = (credentials) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const firestore = fire.firestore()
        await firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password).then((user) => {
            firestore.collection('users').doc(user.user.uid).get().then(resp => {
                if (!resp.exists) {
                    firestore.collection('users').where("email", "==", user.user.email).get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                // doc.data() is never undefined for query doc snapshots
                                const data = doc.data();

                                doc.ref.delete();

                                const newUser = firestore.collection('users').doc(user.user.uid).set({
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    role: data.role === undefined ? "" : data.role,
                                    numberHourBuy: data.numberHourBuy,
                                    numberHoursRemaining: data.numberHoursRemaining,
                                    email: user.user.email,
                                    uid: user.user.uid,
                                    Professors: data.Professors,
                                    company: data.company === undefined ? "" : data.company,
                                    mastermind: data.mastermind === undefined ? "" : data.mastermind,
                                    linkLearningLog: data.linkLearningLog === undefined ? "" : data.linkLearningLog,
                                    AdressCP: data.AdressCP === undefined ? "" : data.AdressCP,
                                    AdressCity: data.AdressCity === undefined ? "" : data.AdressCity,
                                    AdressCountry: data.AdressCountry === undefined ? "" : data.AdressCountry,
                                    AdressLine1: data.AdressLine1 === undefined ? "" : data.AdressLine1,
                                    AddressLine2: data.AddressLine2 === undefined ? "" : data.AddressLine2,
                                    AddressState: data.AddressState === undefined ? "" : data.AddressState,
                                    Level: data.Level === undefined ? "" : data.Level,
                                    bootCamp: data.bootCamp === undefined ? "" : data.bootCamp,
                                    bootCampEndDate: data.bootCampEndDate === undefined ? "" : data.bootCampEndDate,
                                    bootCampRegistered: data.bootCampRegistered === undefined ? "" : data.bootCampRegistered,
                                    bootCampStartDate: data.bootCampStartDate === undefined ? "" : data.bootCampStartDate,
                                    lastProductSubcribe: data.lastProductSubcribe === undefined ? "" : data.lastProductSubcribe,
                                    updateTime: data.updateTime === undefined ? "" : data.updateTime
                                })

                                return newUser
                            });

                        })
                        .catch((error) => {

                        });
                }
            })

            dispatch({ type: 'LOGIN_SUCCESS' })
        }).catch(err => dispatch({ type: 'LOGIN_ERROR', err }))
    }
}*/

// Logout Action
export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .signOut()
            .then(() => {
                localStorage.clear();
                dispatch({ type: "SIGNOUT_SUCCESS" });
            });
    };
};

//  Update Action
export const updateUser = (newUser, contractState) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        let id = newUser.id;
        let stringedUser = JSON.stringify(newUser);
        let convertedobj = JSON.parse(stringedUser);
        delete convertedobj.id;
        let docId = "";
        if (convertedobj.contracts != null) {
            if (convertedobj.contracts.length === 0) {
                // ! New Contract
                //console.log("new contract");
                docId = firestore.collection("contracts").doc().id;
                await firestore
                    .collection("contracts")
                    .doc(docId)
                    .set({ ...contractState });
                convertedobj.contracts = [docId];
            }
        }
        let doc = await firestore.collection("users").doc(id).get();

        if (doc.data().contracts === null) {
            // ! USER Have no contact
            //console.log("user's new contract");
            firestore
                .collection("users")
                .doc(id)
                .set(newUser)
                .then(() => {

                })
                .catch(error => console.log(error.message));
        } else {
            // ! Already have Contract, Adding New / Editing Old
            if (docId !== "") {
                // ! New Doc/Contract Uploaded
                let data = doc.data();
                let { contracts } = data;
                contracts.push(docId);
                convertedobj["contracts"] = contracts;
            } else {
                let i = 0;
                let ids = contractState.map(cur => cur.id);
                //console.log(ids);
                for (let nid of ids) {
                    delete contractState[i]["id"];
                    await firestore
                        .collection("contracts")
                        .doc(nid)
                        .update({ ...contractState[i] });
                    i++;
                }
                // for (let contract of contracts) {

                // 	console.log(data.data());
                // 	console.log("updated contract ", { ...contractState[i][id] });
                // 	i++;
                // }
            }
            firestore
                .collection("users")
                .doc(id)
                .set(convertedobj)
                .then(() => {

                });
        }
    };
};





// Create User
export const createUser = (newUser, contractState) => {
    //console.log(contractState);
    //console.log(newUser);
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        let ref = firestore.collection("users").doc();

        let docId = "";
        if (newUser.contracts.length === 0) {
            // ! New Contract
            console.log("new contract");
            docId = firestore.collection("contracts").doc().id;
            await firestore
                .collection("contracts")
                .doc(docId)
                .set({ ...contractState });
            newUser.contracts = [docId];
        }
        let userID = "";
        await firestore
            .collection("users")
            .doc(ref.id)
            .set(newUser)
            .then(() => {

            })
            .catch(error => console.log(error.message));


        //console.log("Send to API" + ref.id);
        axios.get("https://script.google.com/macros/s/AKfycbz8tniiy3F3TQ4ElK_pDveKsDGXwkm_DsjyBvFoOBCeGdLFNh4dASYL/exec?studentName=" + newUser.firstname + " " + newUser.lastname +
            "&email=" + newUser.email + "&entreprise=" + newUser.company + "&idUser=" + ref.id)
            .then(res => {
                //console.log(res);
                console.log(res.data);
            })
    };

};

export const forgotPassword = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(credentials.email)
    };
};

export const clearErrorMessage = () => {
    return (dispatch) => {
        console.log("Clear Error Message")
        dispatch({ type: 'LOGIN_CHANGE_SUCCESS' })
    };
};


// Sign Up Action
export const signUp2 = (newUser) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()

        await firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password).then((resp) => {
            if (!resp.exists) {
                firestore.collection('users').where("email", "==", resp.user.email).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            const data = doc.data();

                            doc.ref.delete();

                            //let roleUser = data.role === "undefined" ?"":data.role;
                            //console.log("This is the role")
                            // console.log(roleUser)

                            const newUser = firestore.collection('users').doc(resp.user.uid).set({
                                firstname: data.firstname,
                                lastname: data.lastname,
                                role: data.role === undefined ? "" : data.role,
                                numberHourBuy: data.numberHourBuy,
                                numberHoursRemaining: data.numberHoursRemaining,
                                email: resp.user.email,
                                uid: resp.user.uid,
                                Professors: data.Professors,
                                company: data.company === undefined ? "" : data.company,
                                mastermind: data.mastermind === undefined ? "" : data.mastermind,
                                linkLearningLog: data.linkLearningLog === undefined ? "" : data.linkLearningLog,
                                AdressCP: data.AdressCP === undefined ? "" : data.AdressCP,
                                AdressCity: data.AdressCity === undefined ? "" : data.AdressCity,
                                AdressCountry: data.AdressCountry === undefined ? "" : data.AdressCountry,
                                AdressLine1: data.AdressLine1 === undefined ? "" : data.AdressLine1,
                                AddressLine2: data.AddressLine2 === undefined ? "" : data.AddressLine2,
                                AddressState: data.AddressState === undefined ? "" : data.AddressState,
                                Level: data.Level === undefined ? "" : data.Level,
                                bootCamp: data.bootCamp === undefined ? "" : data.bootCamp,
                                bootCampEndDate: data.bootCampEndDate === undefined ? "" : data.bootCampEndDate,
                                bootCampRegistered: data.bootCampRegistered === undefined ? "" : data.bootCampRegistered,
                                bootCampStartDate: data.bootCampStartDate === undefined ? "" : data.bootCampStartDate,
                                lastProductSubcribe: data.lastProductSubcribe === undefined ? "" : data.lastProductSubcribe,
                                updateTime: data.updateTime === undefined ? "" : data.updateTime,
                                subscribeBootCamp: data.subscribeBootCamp === undefined ? "" : data.subscribeBootCamp
                            })

                            return newUser
                        });
                    })
                    .catch((error) => {
                        //console.log("Error getting documents: ", error);
                    });
            }
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' })
        }).catch((err) => dispatch({ type: 'SIGNUP_ERROR', err }))
    }
}

export const signUpWithGoogle2 = async () => {
    const firestore = fire.firestore()
    console.log("Google Sign Up")
    var provider = new social.auth.GoogleAuthProvider();
    await social.auth()
        .signInWithPopup(provider)
        .then((result) => {
            console.log(result)
            return firestore.collection('users').doc(result.user.uid).get().then(resp => {
                if (!resp.exists) {
                    firestore.collection('users').where("email", "==", result.user.email).get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                // doc.data() is never undefined for query doc snapshots
                                const data = doc.data();

                                doc.ref.delete();

                                const newUser = firestore.collection('users').doc(result.user.uid).set({
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    role: data.role === undefined ? "" : data.role,
                                    numberHourBuy: data.numberHourBuy,
                                    numberHoursRemaining: data.numberHoursRemaining,
                                    email: result.user.email,
                                    uid: result.user.uid,
                                    Professors: data.Professors,
                                    company: data.company === undefined ? "" : data.company,
                                    mastermind: data.mastermind === undefined ? "" : data.mastermind,
                                    linkLearningLog: data.linkLearningLog === undefined ? "" : data.linkLearningLog,
                                    AdressCP: data.AdressCP === undefined ? "" : data.AdressCP,
                                    AdressCity: data.AdressCity === undefined ? "" : data.AdressCity,
                                    AdressCountry: data.AdressCountry === undefined ? "" : data.AdressCountry,
                                    AdressLine1: data.AdressLine1 === undefined ? "" : data.AdressLine1,
                                    AddressLine2: data.AddressLine2 === undefined ? "" : data.AddressLine2,
                                    AddressState: data.AddressState === undefined ? "" : data.AddressState,
                                    Level: data.Level === undefined ? "" : data.Level,
                                    bootCamp: data.bootCamp === undefined ? "" : data.bootCamp,
                                    bootCampEndDate: data.bootCampEndDate === undefined ? "" : data.bootCampEndDate,
                                    bootCampRegistered: data.bootCampRegistered === undefined ? "" : data.bootCampRegistered,
                                    bootCampStartDate: data.bootCampStartDate === undefined ? "" : data.bootCampStartDate,
                                    lastProductSubcribe: data.lastProductSubcribe === undefined ? "" : data.lastProductSubcribe,
                                    updateTime: data.updateTime === undefined ? "" : data.updateTime
                                })

                                return newUser
                            });
                        })
                        .catch((error) => {
                            console.log("Error getting documents: ", error);
                        });
                }
            })
        })
}

export const signUpWithFacebook = async () => {
    const firestore = fire.firestore()
    //console.log("Facebook Sign Up")
    var provider = new social.auth.FacebookAuthProvider();
    await social.auth()
        .signInWithPopup(provider)
        .then((result) => {
            return firestore.collection('users').doc(result.user.uid).get().then(resp => {
                if (!resp.exists) {
                    firestore.collection('users').where("email", "==", result.user.email).get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                // doc.data() is never undefined for query doc snapshots
                                const data = doc.data();

                                doc.ref.delete();

                                const newUser = firestore.collection('users').doc(result.user.uid).set({
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    role: data.role === undefined ? "" : data.role,
                                    numberHourBuy: data.numberHourBuy,
                                    numberHoursRemaining: data.numberHoursRemaining,
                                    email: result.user.email,
                                    uid: result.user.uid,
                                    Professors: data.Professors,
                                    company: data.company === undefined ? "" : data.company,
                                    mastermind: data.mastermind === undefined ? "" : data.mastermind,
                                    linkLearningLog: data.linkLearningLog === undefined ? "" : data.linkLearningLog,
                                    AdressCP: data.AdressCP === undefined ? "" : data.AdressCP,
                                    AdressCity: data.AdressCity === undefined ? "" : data.AdressCity,
                                    AdressCountry: data.AdressCountry === undefined ? "" : data.AdressCountry,
                                    AdressLine1: data.AdressLine1 === undefined ? "" : data.AdressLine1,
                                    AddressLine2: data.AddressLine2 === undefined ? "" : data.AddressLine2,
                                    AddressState: data.AddressState === undefined ? "" : data.AddressState,
                                    Level: data.Level === undefined ? "" : data.Level,
                                    bootCamp: data.bootCamp === undefined ? "" : data.bootCamp,
                                    bootCampEndDate: data.bootCampEndDate === undefined ? "" : data.bootCampEndDate,
                                    bootCampRegistered: data.bootCampRegistered === undefined ? "" : data.bootCampRegistered,
                                    bootCampStartDate: data.bootCampStartDate === undefined ? "" : data.bootCampStartDate,
                                    lastProductSubcribe: data.lastProductSubcribe === undefined ? "" : data.lastProductSubcribe,
                                    updateTime: data.updateTime === undefined ? "" : data.updateTime
                                })

                                return newUser
                            });
                        })
                        .catch((error) => {
                            console.log("Error getting documents: ", error);
                        });
                }
            })
        })
}

export const signUpWithApple = () => {
    const firestore = fire.firestore()
    // console.log("Apple Sign Up")
    var provider = new social.auth.OAuthProvider('apple.com');
    social.auth()
        .signInWithPopup(provider)
        .then((result) => {
            console.log(result)
            return firestore.collection('users').doc(result.user.uid).set({
                firstname: result.user.displayName,
                numberOfHourBuy: 5,
                numberOfHoursRemaining: 5,
                email: result.user.email,
                uid: result.user.uid
            })
        })
}





/*export const loginWithGoogle = async () => {
    const firestore = fire.firestore()
    var provider = new social.auth.GoogleAuthProvider();
    await social.auth()
        .signInWithPopup(provider)
        .then((result) => {
            return firestore.collection('users').doc(result.user.uid).get().then(resp => {
                console.log(resp)
                if (!resp.exists) {

                    firestore.collection('users').where("email", "==", result.user.email).get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                // doc.data() is never undefined for query doc snapshots
                                const data = doc.data();



                                const newUser = firestore.collection('users').doc(result.user.uid).set({
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    role: data.role === undefined ? "" : data.role,
                                    numberHourBuy: data.numberHourBuy,
                                    numberHoursRemaining: data.numberHoursRemaining,
                                    email: result.user.email,
                                    uid: result.user.uid,
                                    Professors: data.Professors,
                                    company: data.company === undefined ? "" : data.company,
                                    mastermind: data.mastermind === undefined ? "" : data.mastermind,
                                    linkLearningLog: data.linkLearningLog === undefined ? "" : data.linkLearningLog,
                                    AdressCP: data.AdressCP === undefined ? "" : data.AdressCP,
                                    AdressCity: data.AdressCity === undefined ? "" : data.AdressCity,
                                    AdressCountry: data.AdressCountry === undefined ? "" : data.AdressCountry,
                                    AdressLine1: data.AdressLine1 === undefined ? "" : data.AdressLine1,
                                    AddressLine2: data.AddressLine2 === undefined ? "" : data.AddressLine2,
                                    AddressState: data.AddressState === undefined ? "" : data.AddressState,
                                    Level: data.Level === undefined ? "" : data.Level,
                                    bootCamp: data.bootCamp === undefined ? "" : data.bootCamp,
                                    bootCampEndDate: data.bootCampEndDate === undefined ? "" : data.bootCampEndDate,
                                    bootCampRegistered: data.bootCampRegistered === undefined ? "" : data.bootCampRegistered,
                                    bootCampStartDate: data.bootCampStartDate === undefined ? "" : data.bootCampStartDate,
                                    lastProductSubcribe: data.lastProductSubcribe === undefined ? "" : data.lastProductSubcribe,
                                    updateTime: data.updateTime === undefined ? "" : data.updateTime

                                })

                                doc.ref.delete();
                                console.log(result.user.email)
                                return newUser
                            });

                        })
                        .catch((error) => {
                            console.log("Error getting documents: ", error);
                        });
                }
            })

        })
}*/



export const loginWithFacebook = async () => {
    console.log("Facebook Sign In")
    const firestore = fire.firestore()
    var provider = new social.auth.FacebookAuthProvider();
    await social.auth()
        .signInWithPopup(provider)
        .then((result) => {
            return firestore.collection('users').doc(result.user.uid).get().then(resp => {
                if (!resp.exists) {
                    firestore.collection('users').where("email", "==", result.user.email).get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                // doc.data() is never undefined for query doc snapshots
                                const data = doc.data();

                                doc.ref.delete();

                                const newUser = firestore.collection('users').doc(result.user.uid).set({
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    role: data.role === undefined ? "" : data.role,
                                    numberHourBuy: data.numberHourBuy,
                                    numberHoursRemaining: data.numberHoursRemaining,
                                    email: result.user.email,
                                    uid: result.user.uid,
                                    Professors: data.Professors,
                                    company: data.company === undefined ? "" : data.company,
                                    mastermind: data.mastermind === undefined ? "" : data.mastermind,
                                    linkLearningLog: data.linkLearningLog === undefined ? "" : data.linkLearningLog,
                                    AdressCP: data.AdressCP === undefined ? "" : data.AdressCP,
                                    AdressCity: data.AdressCity === undefined ? "" : data.AdressCity,
                                    AdressCountry: data.AdressCountry === undefined ? "" : data.AdressCountry,
                                    AdressLine1: data.AdressLine1 === undefined ? "" : data.AdressLine1,
                                    AddressLine2: data.AddressLine2 === undefined ? "" : data.AddressLine2,
                                    AddressState: data.AddressState === undefined ? "" : data.AddressState,
                                    Level: data.Level === undefined ? "" : data.Level,
                                    bootCamp: data.bootCamp === undefined ? "" : data.bootCamp,
                                    bootCampEndDate: data.bootCampEndDate === undefined ? "" : data.bootCampEndDate,
                                    bootCampRegistered: data.bootCampRegistered === undefined ? "" : data.bootCampRegistered,
                                    bootCampStartDate: data.bootCampStartDate === undefined ? "" : data.bootCampStartDate,
                                    lastProductSubcribe: data.lastProductSubcribe === undefined ? "" : data.lastProductSubcribe,
                                    updateTime: data.updateTime === undefined ? "" : data.updateTime
                                })

                                return newUser
                            });
                        })
                        .catch((error) => {
                            console.log("Error getting documents: ", error);
                        });
                }
            })
        })
}

export const loginWithApple = () => {
    console.log("Apple Sign In")
    const firestore = fire.firestore()
    var provider = new social.auth.OAuthProvider('apple.com');
    social.auth()
        .signInWithPopup(provider)
        .then((result) => {
            return firestore.collection('users').doc(result.user.uid).get().then(resp => {
                if (!resp.exists) {
                    return firestore.collection('users').doc(result.user.uid).set({
                        firstname: result.user.displayName,
                        numberOfHourBuy: 5,
                        numberOfHoursRemaining: 5,
                        email: result.user.email,
                        uid: result.user.uid
                    })
                }
            })
        })
}


//----------------------------------------------------------------------NOUVELLE VERSION-----------------------------------------------------------------------------------------------

//****************************************************EMAIL******************************************************************* */
//Connexion by Email
export const signIn = (credentials) => {
    return async (dispatch, getState, { getFirebase }) => {
        console.log("Login")
        const firebase = getFirebase();
        const firestore = firebase.firestore();

        try {
            const userCredential = await firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password);
            const user = userCredential.user;

            const userDocRef = firestore.collection('users').doc(user.uid);
            const userDocSnapshot = await userDocRef.get();

            if (!userDocSnapshot.exists) {
                const querySnapshot = await firestore.collection('users').where("email", "==", user.email).get();

                querySnapshot.forEach(async (doc) => {
                    const data = doc.data();
                    doc.ref.delete();

                    await userDocRef.set({
                        firstname: data.firstname || "",
                        lastname: data.lastname || "",
                        // Add other properties similarly
                        updateTime: data.updateTime || ""
                    });
                });
            }

            dispatch({ type: 'LOGIN_SUCCESS' });
        } catch (error) {
            console.error("Login error:", error);
            social.auth().signOut(); // Déconnectez l'utilisateu
            dispatch({ type: 'LOGIN_ERROR', err: error.message });

        }
    }
}

//Create a account with Email
export const signUp = (newUser) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()
        let userData = {};
        console.log("Sign Up")
        firestore.collection('users')
            .where("email", "==", newUser.email.toLowerCase())
            .get()
            .then(querySnapshot => {
                                            // Gérer les erreurs éventuelles de la requête Firestore ici
                                            var templateParams = {
                                                email: '*' + newUser.email + '*',
                                                error: "Le query a fonctionné. Nombre de document trouvé : " + querySnapshot.size + "  email : " + newUser.email
                                            };
                
                                            emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
                                                .then(function (response) {
                                                    console.log('SUCCESS!', response.status, response.text);
                                                }, function (error) {
                                                    console.log('FAILED...', error);
                                                });

                if (querySnapshot.size > 0) {
                    // L'utilisateur existe, il y a au moins un document correspondant
                    firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password).then((resp) => {
                        if (!resp.exists) {
                            querySnapshot.forEach(doc => {
                                userData = doc.data();
                                doc.ref.delete();
                            });

                            const ancienID = userData.id;

                            // Créez un nouvel utilisateur avec les mêmes données et le même UID
                            const newUserId = resp.user.uid;
                            const newUserRef = firestore.collection('users').doc(newUserId);
                            userData.uid = newUserId;
                            userData.id = newUserId;


                            console.log("ancienID : " + ancienID);
                            console.log("nouveauID : " + newUserId);

                            //Si on a changer le email alors il faut remmapper le learning log, le confidence et aussi les meeting
                            if (userData.emailChanged !== undefined && userData.emailChanged === true) {
                                //remap learning log
                                console.log("remap learning log");

                                // Référence à votre collection Firebase (remplacez "votreCollection" par le nom réel de votre collection)
                                const learningLogRef = firestore.collection('learningLogs');

                                // Requête pour récupérer les documents où userID est égal à ancienID
                                learningLogRef.where('userId', '==', ancienID).get()
                                    .then(snapshot => {
                                        snapshot.forEach(doc => {
                                            console.log("doc.id : " + doc.id);
                                            // Mettre à jour le document avec le nouveau ID
                                            learningLogRef.doc(doc.id).update({ userId: newUserId });

                                        });
                                    })
                                    .catch(error => {
                                        console.error("Erreur lors de la mise à jour du learning log :", error);
                                    });

                                //remap confidence
                                console.log("remap confidence");

                                // Référence à votre collection Firebase (remplacez "votreCollection" par le nom réel de votre collection)
                                const confidenceRef = firestore.collection('confidenceResults');

                                // Requête pour récupérer les documents où userID est égal à ancienID
                                confidenceRef.where('idUser', '==', ancienID).get()
                                    .then(snapshot => {
                                        snapshot.forEach(doc => {
                                            console.log("doc.id : " + doc.id);
                                            // Mettre à jour le document avec le nouveau ID
                                            confidenceRef.doc(doc.id).update({ idUser: newUserId, email: userData.email });

                                        });

                                    })
                                    .catch(error => {
                                        console.error("Erreur lors de la mise à jour du meetings :", error);
                                    });

                                //remap meeting
                                console.log("remap mettings");

                                // Référence à votre collection Firebase (remplacez "votreCollection" par le nom réel de votre collection)
                                const meetingsRef = firestore.collection('calendlyMeeting');

                                // Requête pour récupérer les documents où userID est égal à ancienID
                                meetingsRef.where('idUser', '==', ancienID).get()
                                    .then(snapshot => {
                                        snapshot.forEach(doc => {
                                            console.log("doc.id : " + doc.id);
                                            // Mettre à jour le document avec le nouveau ID
                                            meetingsRef.doc(doc.id).update({ idUser: newUserId });

                                        });

                                    })
                                    .catch(error => {
                                        console.error("Erreur lors de la mise à jour du meetings :", error);
                                    });

                            }
                            userData.emailChanged = false;

                            return newUserRef.set(userData)
                                .then(() => {
                                    console.log("Nouvel utilisateur créé avec succès.");
                                })
                                .catch(error => {
                                    var templateParams = {
                                        email: '*' + newUser.email + '*',
                                        error: "(2) Erreur lors de la création du nouvel utilisateur:  Erreur : " + error.message
                                    };

                                    emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
                                        .then(function (response) {
                                            console.log('SUCCESS!', response.status, response.text);
                                        }, function (error) {
                                            console.log('FAILED...', error);
                                        });
                                    console.error("Erreur lors de la création du nouvel utilisateur :", error);
                                });
                        }
                    })
                        .catch(error => {
                            // Gérer les erreurs éventuelles de la requête Firestore ici
                            var templateParams = {
                                email: '*' + newUser.email + '*',
                                error: "(3)Erreur lors de la création de l'utilisateur :  Erreur : " + error.message
                            };

                            emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
                                .then(function (response) {
                                    console.log('SUCCESS!', response.status, response.text);
                                }, function (error) {
                                    console.log('FAILED...', error);
                                });
                            console.error("Erreur lors de la création de l'utilisateur : Erreur : ", error.message);
                            dispatch({ type: 'LOGIN_ERROR', err: error.message });
                        });

                } else {
                    var templateParams = {
                        email: '*' + newUser.email + '*',
                        error: "(6)l'utilisateur n'existe pas. Login avec email"
                    };

                    emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
                        .then(function (response) {
                            console.log('SUCCESS!', response.status, response.text);
                        }, function (error) {
                            console.log('FAILED...', error);
                        });
                    // Aucun document correspondant, l'utilisateur n'existe pas
                    dispatch({ type: 'LOGIN_ERROR', err: "Ce courriel n'est pas reconnu chez Ellipse. Veuillez utiliser l'adresse courriel que vous avez fourni lors de l'inscription. " });
                }
            })
            .catch(error => {
                // Gérer les erreurs éventuelles de la requête Firestore ici
                var templateParams = {
                    email: '*' + newUser.email + '*',
                    error: "(4)Erreur lors de la recherche de l'utilisateur :  Erreur : " + error.message
                };

                emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                    }, function (error) {
                        console.log('FAILED...', error);
                    });
                console.error("Erreur lors de la recherche de l'utilisateur :", error);
                dispatch({ type: 'LOGIN_ERROR', err: error });
            });
    }
}

//****************************************************GOOGLE******************************************************************* */

//Connexion by Google
export const loginWithGoogle = () => {
    return async (dispatch) => { // Reçoit la fonction dispatch en tant que paramètre
        console.log("Login with Google");
        const firestore = fire.firestore();
        const provider = new social.auth.GoogleAuthProvider();
        let errorDetected = false;

        try {
            const result = await social.auth().signInWithPopup(provider);
            const user = result.user;
            const userEmail = user.email;
            let userData = {};
            const querySnapshot = await firestore.collection('users').where("email", "==", userEmail).get();
            console.log("Query Snapshot", querySnapshot.size)

            if (querySnapshot.size === 0) {
                var templateParams = {
                    email: '*' + userEmail + '*',
                    error: "(5)l'utilisateur n'existe pas. Login avec google"
                };

                emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                    }, function (error) {
                        console.log('FAILED...', error);
                    });
                    console.log("L'e-mail n'existe pas, vous pouvez annuler l'authentification");
                dispatch({ type: 'LOGIN_ERROR', err: "Ce courriel n'est pas reconnu chez Ellipse. Veuillez utiliser l'adresse courriel que vous avez fourni lors de l'inscription." });
                errorDetected=true
                social.auth().signOut(); // Déconnectez l'utilisateu
            } else {
                console.log("L'e-mail existe dans Firestore, poursuite de l'authentification.");
                // Créez un nouvel utilisateur avec les mêmes données et le même UID
                const newUserId = result.user.uid;
                console.log(result.user.uid)
                //Vérifier si l'utilisateur existe déjà avec ce UID
                firestore.collection('users').doc(result.user.uid).get().then(resp => {
                    console.log("L'utilisateur existe déja", resp.exists, "avec l'UID", result.user.uid)
                    if (!resp.exists) {
                        const newUserRef = firestore.collection('users').doc(newUserId);

                        querySnapshot.forEach((doc) => {
                            userData = doc.data();
                            //Delete l'ancien utilisateur
                            doc.ref.delete();
                        });

                        return newUserRef.set(userData)
                            .then(() => {
                                console.log("Nouvel utilisateur créé avec succès.");
                            })
                            .catch(error => {
                                console.error("Erreur lors de la création du nouvel utilisateur :", error);
                            });
                    }
                })
            }


            if (!errorDetected) {
                dispatch({ type: 'LOGIN_SUCCESS' });
            }
        } catch (error) {
            console.error("Error during login:", error);
            social.auth().signOut(); // Déconnectez l'utilisateu
            // Votre code d'authentification avec Firebase ici
            dispatch({ type: 'LOGIN_ERROR', err: error.message });
        }
    };
}

//Create a account with Google
export const signUpWithGoogle = async (dispatch) => {

    const firestore = fire.firestore()
    let userData = {};
    console.log("Google Sign Up")
    var provider = new social.auth.GoogleAuthProvider();
    await social.auth()
        .signInWithPopup(provider)
        .then(result => {

            const user = result.user;
            const userEmail = user.email;
            console.log(userEmail)
            return firestore.collection('users').where("email", "==", userEmail).get()
                .then((querySnapshot) => {
                    if (querySnapshot.size === 0) {
                        // L'e-mail n'existe pas, vous pouvez annuler l'authentification
                        console.log("L'e-mail n'existe pas dans Firestore, annulation de l'authentification.");
                        var templateParams = {
                            email: '*' + newUser.email + '*',
                            error: "(1)L'e-mail n'existe pas dans Firestore, annulation de l'authentification.. Login avec google"
                        };

                        emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
                            .then(function (response) {
                                console.log('SUCCESS!', response.status, response.text);
                            }, function (error) {
                                console.log('FAILED...', error);
                            });
                        dispatch({ type: 'LOGIN_ERROR', err: "Ce courriel n'est pas reconnu chez Ellipse. Veuillez utiliser l'adresse courriel que vous avez fourni lors de l'inscription. " });
                        return social.auth().signOut(); // Déconnectez l'utilisateur
                    } else {
                        // L'e-mail existe, vous pouvez continuer l'authentification
                        console.log("L'e-mail existe dans Firestore, poursuite de l'authentification.");
                        querySnapshot.forEach((doc) => {
                            userData = doc.data();
                            doc.ref.delete();
                        });

                        // Créez un nouvel utilisateur avec les mêmes données et le même UID
                        const newUserId = result.user.uid;
                        const newUserRef = firestore.collection('users').doc(newUserId);
                        userData.uid = newUserId;


                        return newUserRef.set(userData)
                            .then(() => {
                                console.log("Nouvel utilisateur créé avec succès.");
                            })
                            .catch(error => {
                                console.error("Erreur lors de la création du nouvel utilisateur :", error);
                            });
                        dispatch({ type: 'LOGIN_SUCCESS' });
                    }
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                    dispatch({ type: 'LOGIN_ERROR', err: error });
                });
        })
}




