import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import i18n from '../../i18n';
import firebase from 'firebase/app';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Table
} from 'reactstrap';

const SignedInLinks = props => {
	const [modal, setModal] = useState(false);
	const [invoices, setInvoices] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const toggleModal = () => {
		if (!modal) {
			fetchInvoices(); // Load invoices when modal is opened
		}
		setModal(!modal);
	};

	const fetchInvoices = () => {
		setIsLoading(true);
		// Build the query string
		const queryParams = new URLSearchParams({
			email: props.profile.email,
			action: 'getInvoices'
		});

		// Use the queryParams string in the URL
		fetch(`https://script.google.com/macros/s/AKfycbwZtZ_i6TnyVpQeCJtCx9uk0eNjgmjmhxRI-CmspEwHITXl25zZ18FdEQ9ks0bVKuZPXQ/exec?${queryParams.toString()}`, {
			method: 'POST'
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then(data => {
				console.log(data);
				setInvoices(data.data);  // Ensure that data is structured correctly to be set here
				setIsLoading(false);
			})
			.catch(error => {
				console.error('Error fetching invoices:', error);
				setIsLoading(false);
			});
	};

	  // Filtrer les factures qui ont un lien avant de les afficher
	  const filteredInvoices = invoices.filter(invoice => invoice.InvoiceLink);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		const firestore = firebase.firestore();
		props.profile.language = lng;
		firestore
			.collection("users")
			.doc(props.profile.id)
			.update(props.profile)
			.then(() => {
				console.log("User language updated");
			})
			.catch(error => console.log(error.message));
	};

	return (
		<>
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav caret>
					{props.profile.firstname} {props.profile.lastname} ({props.profile.email})
				</DropdownToggle>
				<DropdownMenu right>
					<DropdownItem disabled>
						<i className="fa fa-user-circle" aria-hidden="true"></i>&nbsp; My profile
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={toggleModal}>
						<i className="fas fa-file-invoice-dollar" aria-hidden="true"></i>&nbsp; My invoices
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem>
						<NavLink onClick={() => changeLanguage('en')}>
							{i18n.language == 'en' && (<b>
								English
							</b>)}
							{i18n.language == 'fr' && (
								<>English</>
							)}
						</NavLink>
					</DropdownItem>
					<DropdownItem>
						<NavLink onClick={() => changeLanguage('fr')}>
							{i18n.language == 'fr' && (<b>
								Français
							</b>)}
							{i18n.language == 'en' && (<>
								Français
							</>
							)}

						</NavLink>
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem>
						<NavLink onClick={props.signOut}>
							<i className="fa fa-sign-out" aria-hidden="true"></i> &nbsp;Logout
						</NavLink>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>

			{/* Modal for displaying invoices */}
			<Modal isOpen={modal} toggle={toggleModal} className="invoice-modal">
      <ModalHeader toggle={toggleModal}>My Invoices</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
		  <div className="spinner-grow text-primary" role="status">
			<span className="sr-only">Loading...</span>
		  </div>
		  <p>Loading invoices...</p>  {/* Additional text to inform the user */}
		</div>
        ) : filteredInvoices.length > 0 ? (
          <Table responsive hover className="text-left">
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredInvoices.map((invoice, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <a href={invoice.InvoiceLink} target="_blank" rel="noopener noreferrer">
                      {invoice.InvoiceID}
                    </a>
                  </td>
                  <td className="text-center">${invoice.TotalAmount}</td>
                  <td className="text-center">{invoice.TxnDate}</td>
                  <td className="text-center" style={{ color: invoice.PaymentStatus === 'Paid' ? 'green' : 'red' }}>
                    {invoice.PaymentStatus === 'Paid' ? 'PAID' : 'UNPAID'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center">
            <p>No invoices found.</p>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>Close</Button>
      </ModalFooter>
    </Modal>
		</>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		signOut: () => dispatch(signOut()),
	};
};

export default connect(null, mapDispatchToProps)(SignedInLinks);
