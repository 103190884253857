import React, { useEffect, useState } from 'react';
import { compose } from "redux";
import Card2 from './card.js'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AddRessource from "../Ressources/addModifyRessource";
import Container from '@mui/material/Container';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { connect } from "react-redux";




function ResourcesView(props) {

  const { ressourceCategory, onUpdate } = props;

  const CustomModal = props => {
    const { isOpen } = props;
    // If we only put the modal in the render tree when it's open, multiple modals
    // will open in the expected order
    return isOpen ? <Modal {...props} /> : null;
  };

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [ressource, setRessource] = useState(null);

  const handleClickDelete = (ressource) => {
    props.handleDelete(ressource, ressourceCategory.name);
  };


  const openModal = () => {
    setRessource(null)
    setEdit(false)
    setShow(true);
  };
  const openModalEdit = (ressource) => {
    setRessource(ressource)
    setEdit(true)
    setShow(true);
  };
  const closeModal = () => {
    onUpdate();
    setShow(false);

  };

  const handleClickRessource = (url) => {

    window.open(url);

  }


  return (

    <div className="app" name='upload_file'>
      {(props.profile.role != null && props.profile.role !== undefined && (props.profile.role.indexOf("admin") > -1 || props.profile.role.indexOf("ressourcesAdmin") > -1)) &&
        <div align="right">
          <div>
            <i className={`icon-container fas fa-plus-circle`} onClick={() => openModal()}>
            </i>
          </div>
        </div>}
      {ressourceCategory.ressources &&
        <div >
          <Container>
            <Box sx={{ m: 2 }}>
              <Grid container justifyContent="center" spacing={2}>
                {ressourceCategory.ressources.map((ressource, index) => (
                  <Grid key={index} item md={4} xs={12} sm={6} >
                    <Card2 title={ressource.title}
                      description={ressource.description}
                      urlImage={ressource.urlImage}
                      urlDownload={ressource.urlDownload}
                      imageHeight={140} onClick={() => handleClickRessource(ressource.urlRessource)}
                      showDelete={(props.profile.role != null && props.profile.role !== undefined && (props.profile.role.indexOf("admin") > -1 || props.profile.role.indexOf("ressourcesAdmin") > -1))}
                      showAvatar={true}
                      avatarText={ressource.pilier}
                      cardheight={"'200px'"}
                      ressource={ressource}
                      categoryName={ressourceCategory.name}
                      close={() => closeModal()}
                      isCategories={false}
                      handleDelete={handleClickDelete}
                      maxLenght={100}
                      openModalEdit={openModalEdit}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </div>}
      {(ressourceCategory != null && (ressourceCategory.ressources == undefined || ressourceCategory.ressources.length === 0)) && <div>Il n'y a pas de ressources</div>}




      <CustomModal isOpen={show} size="lg" toggle={() => setShow(false)}>
        <ModalBody>
          <AddRessource ressourceCategory={ressourceCategory}
            edit={edit}
            type={2}
            close={() => closeModal()}
            ressourceModify={ressource} />
        </ModalBody>
      </CustomModal>
    </div>

  );
}

const mapToProps = state => {
  return {
    ressources: state.firestore.ordered.freeRessources,
    profile: state.firebase.profile
  };
};


export default compose(
  connect(mapToProps),

)
  (ResourcesView);