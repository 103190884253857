
import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Form,
    FormGroup,
    Label,
    Button,
} from "reactstrap";
import input from "./input";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ShowMessage from "./showMessage";

function AddStudent({
    company,
    objUserFind,
    onCancel,
    onSaveStudent,
    onSaveUser,
    student,
    isModify

}) {
    const schema = yup.object({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        email: yup.string().required("Email is required"),
        email: yup.string().email("Invalide email format"),

    });
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const [studentObj, setStudentObj] = useState({

        firstName: "",
        lastName: "",
        email: "",
        isPaused: false,
        startDatePaused: "",
    });



    const handleSubmit = async evt => {
        const isValid = await schema.validate(evt);
        studentObj.firstName = evt.firstName
        studentObj.lastName = evt.lastName
        studentObj.email = evt.email
        studentObj.dateAdded = new Date()

        let array = JSON.parse(JSON.stringify(company.students));

        let obj = array.find(
            item => item.email.toLowerCase() === studentObj.email.toLowerCase()
        );

        if (!isModify) {
            if (obj != null) {
                setDisplayConfirmationModal(true);
            } else {
                let arrayTmp = company.students.map(object => ({ ...object }))
                arrayTmp.push(studentObj)

                //array.map(value => value.dateAdded = moment(value.dateAdded))

                company.students = arrayTmp

                if (objUserFind) {
                    objUserFind.email = studentObj.email.toLowerCase()
                    objUserFind.firstname = studentObj.firstName
                    objUserFind.lastname = studentObj.lastName

                    onSaveUser()
                    onSaveStudent()
                }

                onCancel()

            }

        } else {
            obj.firstName = evt.firstName
            obj.lastName = evt.lastName
            obj.email = evt.email

            company.students = array
            onCancel()
        }
    };

    return (
        <div className="form">
            <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={student || {}}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isInvalid,
                    errors,
                }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>First Name</Label>
                                <Field name="firstName" type="text" component={input} placeholder="First Name" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Last Name</Label>
                                <Field name="lastName" type="text" component={input} placeholder="Last Name" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email</Label>
                                <Field name="email" type="email" component={input} placeholder="Email" />
                            </FormGroup>


                            <Button type="submit" style={{ marginRight: "10px" }} >
                                Save
            </Button>
                            <Button type="button" onClick={() => {
                                onCancel()
                            }}>
                                Cancel
            </Button>
                        </Form>
                    )}
            </Formik>

            <ShowMessage showModal={displayConfirmationModal} hideModal={hideConfirmationModal} message="This Email is already used" title="Error"  type='alert alert-danger' />


        </div>
    );
}

AddStudent.propTypes = {
    company: PropTypes.object,
    users: PropTypes.object,
    objUserFind: PropTypes.object,
    onCancel: PropTypes.func,
    onSaveStudent: PropTypes.func,
    onSaveUser: PropTypes.func,
    student: PropTypes.object,
    idCompany: PropTypes.string,
    displayAddStudent: PropTypes.bool,
    isModify: PropTypes.bool,

};



export default connect(
)(AddStudent);

