import authReducer from "./authReducer";
import professorReducer from "./professorReducer";

import companyReducer from "./companyReducer";
import usersReducer from "./usersReducer";
import mastermindReducer from "./mastermindReducer";
import rolesReducer from "./rolesReducer";

import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

import { combineReducers } from "redux";
import contractReducer from "./contractReducer";

const rootReducer = combineReducers({
	auth: authReducer,
	professors: professorReducer,
	company: companyReducer,
	contracts: contractReducer,
	mastermind: mastermindReducer,
	users: usersReducer,
	firestore: firestoreReducer,
	firebase: firebaseReducer,
	roles: rolesReducer,
});

export default rootReducer;
