import React from 'react'
import { Modal, Button,ModalFooter,ModalHeader,ModalBody } from "reactstrap";
 
const ShowMessage = ({ showModal, hideModal,message,title,type }) => {
  
    return (

      <Modal isOpen={showModal} toggle={hideModal} backdrop='false'>
      <ModalHeader toggle={hideModal}>{title}</ModalHeader>
      <ModalBody>
      <div className={type}>{message}</div>
      </ModalBody>
      <ModalFooter>
      <Button variant="default" onClick={hideModal}>
            Ok
          </Button>
      </ModalFooter>
    </Modal>

    )
}
 
export default ShowMessage;