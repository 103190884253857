import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Row,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	Spinner,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import 'moment/locale/fr';
import i18n from '../../i18n';

const MeetingList = props => {
	const meetings = props.meetings;
	const loaded = props.loaded;

	let upcomings = [];
	let past = [];

	meetings.forEach(element => {
		const now = moment();
		const meet_time = moment(element.start_time);

		const diff = moment.duration(meet_time.diff(now)).asMinutes();

		if (diff > 0) {
			upcomings.push(element);
		}
		if (diff < 0) {
			past.push(element);
		}
	});

	const professors = props.professors;

	const [activeTab, setActiveTab] = useState("1");
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [range, setRange] = useState();

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const { t, i18n } = useTranslation();

	const handleSubmit = e => {
		e.preventDefault();

		const startD = moment(startDate);
		const endD = moment(endDate);

		let rangeArray = [];
		meetings.forEach(element => {
			const eventDate = moment(element.start_time.split("T")[0]);
			if (eventDate >= startD && eventDate <= endD) {
				rangeArray.push(element);
			}
		});

		setRange(rangeArray);
	};
	return (
		<div className="project-list section">
			<h4 className="my-4">{t('meeting_list_my_meetings')}</h4>
			{loaded ? (
				<Row>
					<Col md="12">
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "1" })}
									onClick={() => {
										toggle("1");
									}}
								>
									{t('meeting_list_all_meetings')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "2" })}
									onClick={() => {
										toggle("2");
									}}
								>
									{t('meeting_list_upcoming_meetings')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "3" })}
									onClick={() => {
										toggle("3");
									}}
								>
									{t('meeting_list_past_meetings')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "4" })}
									onClick={() => {
										toggle("4");
									}}
								>
									{t('meeting_list_date_range')}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<Row className="mt-3">
									{meetings &&
										meetings.map(meeting => {
											return (
												<Col sm="12" key={meeting.id}>
													<div className="card mt-2">
														<div className="card-body black-text">
															<Row className="align-items-center">
																<Col md="4" sm="3">
																	{meeting.isCancel ? (
																		<s>
																			<span>
																				{meeting.start_time &&
																					getMeetingDateTime(meeting)}
																			</span>
																		</s>
																	) : (
																		<span>
																			{meeting.start_time &&
																				getMeetingDateTime(meeting)}
																		</span>
																	)}
																</Col>
																<Col md="6" sm="6">
																	{meeting.isCancel ? (
																		<span>
																			<s>
																				<strong>
																					{getProfessorName(
																						meeting.professorId,
																						professors
																					)}
																				</strong>{" "}
																				<br />
																				{meeting.event_name}
																				<br />
																			</s>
																			<div className="red-text">
																			{t('meeting_list_cancel_meeting')}
																			</div>
																		</span>
																	) : (
																		<span>
																			<strong>
																				{getProfessorName(
																					meeting.professorId,
																					professors
																				)}
																			</strong>{" "}
																			<br />
																			{meeting.event_name}{" "}
																		</span>
																	)}
																</Col>
																<Col md="2" sm="3">
																	<Link
																		to={"/meeting/" + meeting.id}
																		key={meeting.id}
																	>
																		{t('meeting_list_detail')}
																	</Link>
																</Col>
															</Row>
														</div>
													</div>
												</Col>
											);
										})}
									{!meetings || !meetings.length ? (
										<div className="center">{t('meeting_list_no_meeting_found')}</div>
									) : (
										""
									)}
								</Row>
							</TabPane>
							<TabPane tabId="2">
								<Row className="mt-3">
									{upcomings &&
										upcomings.map(meeting => {
											return (
												<Col sm="12" key={meeting.id}>
													<div className="card mt-2">
														<div className="card-body black-text">
															<Row className="align-items-center">
																<Col md="4" sm="3">
																	{meeting.isCancel ? (
																		<s>
																			<span>
																				{meeting.start_time &&
																					getMeetingDateTime(meeting)}
																			</span>
																		</s>
																	) : (
																		<span>
																			{meeting.start_time &&
																				getMeetingDateTime(meeting)}
																		</span>
																	)}
																</Col>
																<Col md="6" sm="6">
																	{meeting.isCancel ? (
																		<span>
																			<s>
																				<strong>
																					{getProfessorName(
																						meeting.professorId,
																						professors
																					)}
																					{console.log(meeting)	}
																				</strong>{" "}
																				<br />
																				{meeting.event_name}
																				<br />
																			</s>
																			<div className="red-text">
																			{t('meeting_list_cancel_meeting')}
																			</div>
																		</span>
																	) : (
																		<span>
																			<strong>
																				{getProfessorName(
																					meeting.professorId,
																					professors
																				)}{console.log(meeting)	}
																			</strong>{" "}
																			<br />
																			{meeting.event_name}{" "}
																		</span>
																	)}
																</Col>
																<Col md="2" sm="3">
																	<Link
																		to={"/meeting/" + meeting.id}
																		key={meeting.id}
																	>
																		{t('meeting_list_detail')}
																	</Link>
																</Col>
															</Row>
														</div>
													</div>
												</Col>
											);
										})}
									{!upcomings || !upcomings.length ? (
										<div className="center">{t('meeting_list_no_upcoming_meeting_found')}</div>
									) : (
										""
									)}
								</Row>
							</TabPane>
							<TabPane tabId="3">
								<Row className="mt-3">
									{past &&
										past.map(meeting => {
											return (
												<Col sm="12" key={meeting.id}>
													<div className="card mt-2">
														<div className="card-body black-text">
															<Row className="align-items-center">
																<Col md="4" sm="3">
																	{meeting.isCancel ? (
																		<s>
																			<span>
																				{meeting.start_time &&
																					getMeetingDateTime(meeting)}
																			</span>
																		</s>
																	) : (
																		<span>
																			{meeting.start_time &&
																				getMeetingDateTime(meeting)}
																		</span>
																	)}
																</Col>
																<Col md="6" sm="6">
																	{meeting.isCancel ? (
																		<span>
																			<s>
																				<strong>
																					{getProfessorName(
																						meeting.professorId,
																						professors
																					)}
																				</strong>{" "}
																				<br />
																				{meeting.event_name}
																				<br />
																			</s>
																			<div className="red-text">
																			{t('meeting_list_cancel_meeting')}
																			</div>
																		</span>
																	) : (
																		<span>
																			<strong>
																				{getProfessorName(
																					meeting.professorId,
																					professors
																				)}
																			</strong>{" "}
																			<br />
																			{meeting.event_name}{" "}
																		</span>
																	)}
																</Col>
																<Col md="2" sm="3">
																	<Link
																		to={"/meeting/" + meeting.id}
																		key={meeting.id}
																	>
																		{t('meeting_list_detail')}
																	</Link>
																</Col>
															</Row>
														</div>
													</div>
												</Col>
											);
										})}
									{!past || !past.length ? (
										<div className="text-center">{t('meeting_list_no_past_meeting_found')}</div>
									) : (
										""
									)}
								</Row>
							</TabPane>
							<TabPane tabId="4">
								<Form
									onSubmit={e => {
										handleSubmit(e);
									}}
								>
									<Row form className="align-items-center mt-5">
										<Col sm="4">
											<FormGroup>
												<Label for="startDate">{t('meeting_list_select_start_date')}</Label>
												<Input
													type="date"
													name="startDate"
													id="startDate"
													onChange={e => setStartDate(e.target.value)}
													placeholder={t('meeting_list_select_start_date')}
												/>
											</FormGroup>
										</Col>
										<Col sm="4">
											<FormGroup>
												<Label for="endDate">{t('meeting_list_select_end_date')}</Label>
												<Input
													type="date"
													min={startDate}
													name="endDate"
													id="endDate"
													onChange={e => setEndDate(e.target.value)}
													placeholder={t('meeting_list_select_end_date')}
												/>
											</FormGroup>
										</Col>
										<Col sm="4" className="text-center">
											<Button disabled={!startDate || !endDate} color="primary">
												{t('meeting_list_find_event')}
											</Button>
										</Col>
									</Row>
								</Form>

								<Row className="mt-3">
									{range &&
										range.map(meeting => {
											return (
												<Col sm="12" key={meeting.id}>
													<div className="card mt-2">
														<div className="card-body black-text">
															<Row className="align-items-center">
																<Col md="4" sm="3">
																	{meeting.isCancel ? (
																		<s>
																			<span>
																				{meeting.start_time &&
																					getMeetingDateTime(meeting)}
																			</span>
																		</s>
																	) : (
																		<span>
																			{meeting.start_time &&
																				getMeetingDateTime(meeting)}
																		</span>
																	)}
																</Col>
																<Col md="6" sm="6">
																	{meeting.isCancel ? (
																		<span>
																			<s>
																				<strong>
																					{getProfessorName(
																						meeting.professorId,
																						professors
																					)}
																				</strong>{" "}
																				<br />
																				{meeting.event_name}
																				<br />
																			</s>
																			<div className="red-text">
																			{t('meeting_list_cancel_meeting')}
																			</div>
																		</span>
																	) : (
																		<span>
																			<strong>
																				{getProfessorName(
																					meeting.professorId,
																					professors
																				)}
																			</strong>{" "}
																			<br />
																			{meeting.event_name}{" "}
																		</span>
																	)}
																</Col>
																<Col md="2" sm="3">
																	<Link
																		to={"/meeting/" + meeting.id}
																		key={meeting.id}
																	>
																		{t('meeting_list_detail')}
																	</Link>
																</Col>
															</Row>
														</div>
													</div>
												</Col>
											);
										})}
									<Col sm={12} className="text-center">
										{!range || !range.length ? (
											<div className="text-center">{t('meeting_list_no_meeting_found')}</div>
										) : (
											""
										)}
									</Col>
								</Row>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			) : (
				<div className="text-center mt-3">
					<Spinner />
				</div>
			)}
			{}
			<br></br>
											<center><b>{t('sprint_need_help')}<a href="mailto:hello@groupellipse.com"> hello@groupellipse.com</a></b></center>
											<br></br>
		</div>
	);
};

function getProfessorName(id, list) {
	const professor = list.find(prof => prof.id === id);

	if (professor) {
		return professor.name;
	}
	return "No Professor Found.";
}

function getMeetingDateTime(meeting) {
	moment.locale(i18n.language);
	const localStartTime = moment(meeting.start_time).local().format("HH:mm A")
	const localEndTime = moment(meeting.end_time).local().format("HH:mm A")
	const localDate = moment(meeting.start_time).local().format("MMMM DD YYYY")
	const localDateFr = moment(meeting.start_time).local().format("DD MMMM YYYY")

	if (i18n.language == 'fr')
		return localDateFr + " " + localStartTime + " - " + localEndTime

	return localDate + " " + localStartTime + " - " + localEndTime		

}

const mapToProps = state => {
	const professors = state.firestore.ordered.professor;
	return {
		professors: professors,
	};
};

export default compose(
	connect(mapToProps),
	firestoreConnect([{ collection: "professor" }])
)(MeetingList);
