import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Form,
    FormGroup,
    Label,
    Button,
} from "reactstrap";
import input from "./input";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ShowMessage from "./showMessage";

function AddStudent({
    company,
    objUserFind,
    onCancel,
    onSaveStudent,
    student,
    isModify,
    existingUsers // Utilisateurs existants à vérifier
}) {
    const schema = yup.object({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        email: yup.string().email("Invalid email format").required("Email is required"),
    });

    // État local pour le message d'erreur
    const [errorMessage, setErrorMessage] = useState("");

    // Fonction de soumission
    const handleSubmit = async (evt) => {
        try {
            // Valide les données avec `yup`
            await schema.validate(evt);

            // Vérifie si l'email existe déjà parmi les utilisateurs existants
            const participantExists = existingUsers.some(
                (user) => user.email === evt.email
            );

            if (participantExists) {
                // Définir un message d'erreur sans fermer la modale
                setErrorMessage("Ce participant existe déjà.");
                return; // Arrête la soumission
            }

            // Si aucune erreur, on peut sauvegarder
            const newStudent = {
                firstName: evt.firstName,
                lastName: evt.lastName,
                email: evt.email,
                dateAdded: isModify ? student.dateAdded : new Date(), // Utilise `dateAdded` existant en modification
            };

            // Appel de la fonction de sauvegarde avec les données du nouvel étudiant
            onSaveStudent(newStudent, isModify);
            onCancel(); // Ferme la modale uniquement si aucune erreur n'est détectée

        } catch (err) {
            // Gérer les erreurs de validation de `yup`
            setErrorMessage(err.message);
        }
    };

    return (
        <div className="form">
            <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={student || {}}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isInvalid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>First Name</Label>
                            <Field name="firstName" type="text" component={input} placeholder="First Name" />
                            {touched.firstName && errors.firstName && (
                                <div style={{ color: 'red' }}>{errors.firstName}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>Last Name</Label>
                            <Field name="lastName" type="text" component={input} placeholder="Last Name" />
                            {touched.lastName && errors.lastName && (
                                <div style={{ color: 'red' }}>{errors.lastName}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Field name="email" type="email" component={input} placeholder="Email" />
                            {touched.email && errors.email && (
                                <div style={{ color: 'red' }}>{errors.email}</div>
                            )}
                            {errorMessage && (
                                <div style={{ color: 'red' }}>{errorMessage}</div>
                            )}
                        </FormGroup>
                        <Button type="submit" style={{ marginRight: "10px" }}>
                            Save
                        </Button>
                        <Button type="button" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

AddStudent.propTypes = {
    company: PropTypes.object,
    objUserFind: PropTypes.object,
    onCancel: PropTypes.func,
    onSaveStudent: PropTypes.func,
    student: PropTypes.object,
    isModify: PropTypes.bool,
    existingUsers: PropTypes.arrayOf(PropTypes.object), // Utilisateurs existants à vérifier
};

export default connect()(AddStudent);
