
import React, { Component, useEffect } from "react";
import { compose } from "redux";
import logo from "./logoIntact.png";




class IntactSurvey extends Component {

    constructor(props) {
        super(props);
      }


  render() {
      

    
    return (
      <div className="dashboard container mx-auto">

        <center>
        <img src={logo} width="200" alt="Intact" />
        </center>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScO-fXhZY62ErRfYTGTeXP-i3i8JAzlc6HSIpDIZRPfH92KBg/viewform?embedded=true" width="100%" height="5483" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

    

      </div>

    );
  }
}


export default compose()(IntactSurvey);