import React, { useState } from 'react';
import './SidePanel.css'; // Import the CSS file for styling

const SidePanel = (props) => {
    const { profile } = props;


    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAction, setIsOpenAction] = useState(false);

    const togglePanel = () => {
        setIsOpen(!isOpen);
        setIsOpenAction(false)
    };

    const togglePanelAction = () => {
        setIsOpen(false);
        setIsOpenAction(!isOpenAction)
    };

    return (
        <div>
            {/* Label to display when the panel is closed */}
            {<div className={`closed-label ${isOpen || isOpenAction ? 'open' : ''}`} onClick={togglePanel}>Confidence Objectives</div>}
            {<div className={`closed-label-actions ${isOpen || isOpenAction ? 'open' : ''}`} onClick={togglePanelAction}>Action plan</div>}

            {/* Sliding side panel */}
            <div className={`side-panel ${isOpen ? 'open' : ''}`}>

                {/* Content of the panel */}
                <h4>Confidence Objectives</h4>
                {profile && profile.confidenceObjective1 && (
                <p><ul>
                    <li>{profile.confidenceObjective1}</li>
                    <li>{profile.confidenceObjective2}</li>
                    <li>{profile.confidenceObjective3}</li>
                </ul></p>)}
                {profile && !profile.confidenceObjective1 && (
                <p>No objectives defined</p>)}
            </div>

            {/* Sliding side panel */}
            <div className={`side-panel ${isOpenAction ? 'open' : ''}`}>

                {/* Content of the panel */}
                <h4>Action Plan</h4>
                <p><ul>
                    {profile && profile.actionPlans &&
                        profile.actionPlans
                        .filter(actionPlan => actionPlan.retired === false || !actionPlan.retired)
                        .map((actionPlan, index) => (
                            <li>{actionPlan.description}</li>

                        ))}
                    {profile && !profile.actionPlans &&
                       (
                            <div className="content">
                            No action defined
                        </div>

                        )}                        
                </ul></p>
            </div>
        </div>
    );
};

export default SidePanel;