import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './PlanningCalendrier.css';
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import firebase from "firebase";

const SprintPlanning = ({ sprints, professors, sprintPlanningData }) => {
  const [date, setDate] = useState(new Date());
  const [plannings, setPlannings] = useState([]);
  const firestore = firebase.firestore();
  const [errors, setErrors] = useState([]); // Pour stocker les messages d'erreur
  const errorRefs = useRef({}); // Utilisez un objet pour stocker les refs de manière dynamique
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Gère l'état d'ouverture de la liste déroulante
  const [filterEnglish, setFilterEnglish] = useState(true);
  const [filterFrench, setFilterFrench] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState(''); // État pour le thème sélectionné
  const [englishSkillsOptions, setEnglishSkillsOptions] = useState([]); // État pour les options de compétences en anglais filtrées


  const onChange = date => {
    setDate(date);
    setErrors(null)
  };

  // Fonction pour désactiver les tuiles des fins de semaine (samedi et dimanche)
  const disableWeekends = ({ date, view }) => {
    // view === 'month' assure que la désactivation s'applique uniquement à la vue mensuelle
    return view === 'month' && (date.getDay() === 0 || date.getDay() === 6);
  };

  const toggleExpansion = index => {
    setExpandedItems(currentExpandedItems => {
      const isCurrentlyExpanded = currentExpandedItems.includes(index);
      if (isCurrentlyExpanded) {
        // Si déjà étendu, le retirer
        return currentExpandedItems.filter(itemIndex => itemIndex !== index);
      } else {
        // Sinon, l'ajouter à l'array des éléments étendus
        return [...currentExpandedItems, index];
      }
    });
  };

  const handleThemeChange = (index, value) => {
    const updatedPlannings = [...plannings];
    updatedPlannings[index].sujet = value;
    setPlannings(updatedPlannings);
    const theme = event.target.value;
    setSelectedTheme(theme); 
    filterEnglishSkillsByTheme(theme);
  };

    // Fonction pour filtrer les compétences en anglais en fonction du thème sélectionné
    const filterEnglishSkillsByTheme = (theme) => {
      const firestore = firebase.firestore();
      console.log("theme",theme)
  
      firestore.collection('sprintPlanningData').where('theme', '==', theme).get()
        .then(querySnapshot => {
          const skillsOptions = [];
          querySnapshot.forEach(doc => {
            // Supposer que 'englishSkills' est la clé pour les compétences en anglais
            const docData = doc.data();
            const docId = doc.id; // Obtenir l'ID du document
            skillsOptions.push({
              id: docId,
              ...docData // Ceci copie toutes les données du document dans l'objet
            });
          });
          
          setEnglishSkillsOptions(skillsOptions);
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });
    };

    const handleSkillsChange = (index, value) => {
      // Trouver l'objet compétence en anglais sélectionné par son ID
      const selectedSkill = englishSkillsOptions.find(skill => skill.id === value);

      console.log("value",value)
    
      if (!selectedSkill) {
        console.error("Selected skill not found");
        return;
      }
    
      // Mettre à jour le planning avec les informations de l'objet compétence sélectionné
      const updatedPlannings = [...plannings];
      updatedPlannings[index] = {
        ...updatedPlannings[index],
        englishSkillId: selectedSkill.id,
        description: selectedSkill.englishSkill, // ou toute autre propriété pertinente de l'objet
        fileCode: selectedSkill.fileCode, // Assurez-vous que cette propriété existe dans votre objet
        presentationLink: selectedSkill.presentationLink, // Assurez-vous que cette propriété existe dans votre objet
        iceBreaker: selectedSkill.iceBreaker, // Assurez-vous que cette propriété existe dans votre objet
      };
    
      setPlannings(updatedPlannings);
    };

  const handlePlanningChange = (index, field, value) => {
    const updatedPlannings = [...plannings];
    updatedPlannings[index][field] = value;
    setPlannings(updatedPlannings);
  };

  const savePlanningsToFirestore = async () => {
    if (validatePlannings()) {
      const planningDate = date.toISOString().split('T')[0]; // Format YYYY-MM-DD
      const planningsToSave = plannings.map(({ expanded, ...keepAttrs }) => keepAttrs);
      await firestore.collection('sprintPlannings').doc(planningDate).set({
        plannings: planningsToSave
      }, { merge: true });

      console.log('All plannings saved to Firestore, excluding the expanded state.');
      setErrors([]);
    } else {
      console.error('Validation failed, plannings not saved');
    }
  };

  // Cette fonction est appelée lors de la soumission du formulaire
  const handleSubmit = e => {
    e.preventDefault();
    const validationErrors = validatePlannings();
    if (validationErrors.length > 0) {
      // Si des erreurs sont présentes, naviguez vers le premier champ avec erreur
      const firstErrorField = validationErrors[0];
    } else {
      savePlanningsToFirestore();
    }
  };

  const validatePlannings = () => {
    const newErrors = [];
    const maxErrors = 3; // Limite le nombre d'erreurs à afficher à la fois

    for (let index = 0; index < plannings.length; index++) {
      const planning = plannings[index];
      if (newErrors.length >= maxErrors) break; // Arrête la vérification si le nombre max d'erreurs est atteint

      // Validation pour le champ 'sujet'
      if (!planning.sujet.trim()) {
        newErrors.push({ message: `Theme is required for sprint ${index + 1}`, refKey: `sprint-${index}-sujet` });
      }

      // Répétez pour les autres validations...
      // Assurez-vous d'inclure la condition pour vérifier le nombre d'erreurs avant chaque ajout
      if (!planning.description.trim() && newErrors.length < maxErrors) {
        newErrors.push({ message: `English Skills are required for sprint ${index + 1}`, refKey: `sprint-${index}-description` });
      }

      if (!planning.fileCode.trim() && newErrors.length < maxErrors) {
        newErrors.push({ message: `File code is required for sprint ${index + 1}`, refKey: `sprint-${index}-fileCode` });
      }

      if (!planning.presentationLink.trim() && newErrors.length < maxErrors) {
        newErrors.push({ message: `Presentation Link is required for sprint ${index + 1}`, refKey: `sprint-${index}-presentationLink` });
      }

      if (!planning.iceBreaker.trim() && newErrors.length < maxErrors) {
        newErrors.push({ message: `Ice Breaker is required for sprint ${index + 1}`, refKey: `sprint-${index}-iceBreaker` });
      }
    }

    setErrors(newErrors); // Mettez à jour l'état des erreurs avec les nouvelles erreurs
    return newErrors.length === 0; // Retourne vrai si aucune erreur n'a été trouvée
  };

  const handleErrorClick = (error) => {
    const planningIndex = parseInt(error.refKey.split('-')[1], 10); // Extrait l'index du planning à partir de refKey

    // Vérifie si le planning associé à l'erreur est déjà expandu
    if (!plannings[planningIndex].expanded) {
      // Si non, modifiez d'abord l'état pour l'expandre
      toggleExpansion(planningIndex); // Utilisez votre fonction existante pour changer l'état d'expansion

      // Attendre un peu pour que l'animation d'expansion soit terminée pourrait être nécessaire
      setTimeout(() => {
        scrollToError(error.refKey);
      }, 300); // Ajustez ce délai en fonction de la durée de votre animation d'expansion
    } else {
      // Si le planning est déjà expandu, défiler directement vers le champ
      scrollToError(error.refKey);
    }
  };

  // Fonction pour faire défiler jusqu'au champ en erreur
  const scrollToError = (refKey) => {
    const errorElement = errorRefs.current[refKey];
    console.log('errorElement:', refKey);
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    const loadOrGeneratePlanning = async () => {
      const formattedDate = moment(date).format("YYYY-MM-DD");

      // Tentative de chargement des données existantes pour la date sélectionnée
      const docRef = firestore.collection('sprintPlannings').doc(formattedDate);
      const doc = await docRef.get();
      if (doc.exists) {
        console.log("Planning data found for selected date:", doc.data());
        setPlannings(doc.data().plannings); // Met à jour l'état avec les plannings chargés
      } else {
        // Aucun planning trouvé, génération d'un nouveau planning
        console.log("No planning found for selected date, generating new planning.");

        if (sprints) {
          const sprintData = sprints.map(sprint => ({
            language: sprint.language,
            sprintLevel: sprint.levelSprint,
            sprintId: sprint.id,
            startTime: sprint.startTime,
            endTime: sprint.endTime,
            sujet: '',
            description: '',
            fileCode: '',
            presentationLink: '',
            iceBreaker: '',
            professor: '',
            date: formattedDate, // Ajoutez la date formatée à chaque planning
            expanded: false // Initialisé à false pour chaque planning
          }));

          // Tri des sprints par heure de début
          sprintData.sort((a, b) => moment(a.startTime, "HH:mm").diff(moment(b.startTime, "HH:mm")));
          setPlannings(sprintData);
        }
      }
    };

    loadOrGeneratePlanning();
  }, [date, firestore, sprints]); // Ajoutez 'sprints' comme dépendance si son état peut changer


  function getSprintDateTime(startTime, endTime) {
    const date = new Date();
    moment.locale(i18n.language);
    const newDate = moment(date).format("DD-MM");

    if (i18n.language.startsWith('fr')) {
      return `${newDate.split("-")[0]} ${moment(newDate.split("-")[1], "M").format("MMMM")} ${moment.utc(startTime, ["h:mm"]).local().format("HH:mm A")} - ${moment.utc(endTime, ["h:mm"]).local().format("HH:mm A")}`;
    }

    return `${moment(newDate.split("-")[1], "M").format("MMMM")} ${newDate.split("-")[0]} ${moment.utc(startTime, ["h:mm"]).local().format("HH:mm A")} - ${moment.utc(endTime, ["h:mm"]).local().format("HH:mm A")}`;
  }


  // Fonction pour copier les données du premier planning vers les plannings sélectionnés
  const copyDataToSelectedPlannings = () => {
    const sourceData = plannings[0];
    const updatedPlannings = plannings.map((planning, index) => {
      if (selectedTargets.includes(index)) {
        // Remplacer par les champs spécifiques que vous souhaitez copier
        return {
          ...planning, sujet: sourceData.sujet,
          description: sourceData.description,
          fileCode: sourceData.fileCode,
          presentationLink: sourceData.presentationLink,
          iceBreaker: sourceData.iceBreaker,
          professor: sourceData.professor
        };
      }
      toggleDropdown(); // Fermer la liste déroulante après la copie
      return planning;
    });

    setPlannings(updatedPlannings);
    // Optionnel: Réinitialiser les sélections après la copie
    setSelectedTargets([]);
  };

  // Bascule l'état de sélection pour un planning
  const toggleSelection = (index) => {
    setSelectedTargets(current => {
      if (current.includes(index)) {
        // Si déjà sélectionné, le retirer
        return current.filter(item => item !== index);
      } else {
        // Sinon, l'ajouter à la sélection
        return [...current, index];
      }
    });
  };

  // Affiche ou masque la liste déroulante personnalisée
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFilterEnglishChange = () => {
    setErrors(null)
    setFilterEnglish(!filterEnglish);
  };

  const handleFilterFrenchChange = () => {
    setErrors(null)
    setFilterFrench(!filterFrench);
  };

  const filteredPlannings = plannings.filter(planning => {
    if (filterEnglish && planning.language === 'en') {
      return true;
    }
    if (filterFrench && planning.language === 'fr') {
      return true;
    }
    if (!filterEnglish && !filterFrench) {
      // Si aucun filtre n'est actif, afficher tous les sprints
      return true;
    }
    return false;
  });

  return (
    <div className="container mt-5">
      {/* Affichage des messages d'erreur */}
      <h2 className="text-center mb-4">Sprint Planning</h2>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="calendar-wrapper mb-4">
            <Calendar
              onChange={onChange}
              value={date}
              tileDisabled={disableWeekends} // Utilisation de la fonction pour désactiver les tuiles
            />
          </div>
          <div className="filters-container">
            <input
              type="checkbox"
              id="filterEnglish"
              className="filter-checkbox"
              checked={filterEnglish}
              onChange={handleFilterEnglishChange}
            />
            <label htmlFor="filterEnglish" className="filter-label">English Sprint</label>

            <input
              type="checkbox"
              id="filterFrench"
              className="filter-checkbox"
              checked={filterFrench}
              onChange={handleFilterFrenchChange}
            />
            <label htmlFor="filterFrench" className="filter-label">French Sprint</label>
          </div>

          <div className="text-center">
            <button type="submit" className="beautiful-button" onClick={handleSubmit}>Save</button>
          </div>
          <h3 className="text-center mb-3">{date.toDateString()}</h3>
          <div className="form-wrapper">
            {errors && errors.map((error, index) => (
              <div key={index} className="alert alert-danger" role="alert" onClick={() => handleErrorClick(error)}>
                {error.message}
              </div>
            ))}
            {filteredPlannings.map((planning, index) => (
              <div key={index} className="card mb-3">
                <div className="card-header" onClick={() => toggleExpansion(index)}>
                  <h5 className="mb-0">
                    <span> {index + 1} - {planning.sprintLevel === 'a' ? 'Advanced - Intermediate Sprint' : ' Beginner/Pre-Intermediate Sprint'}&nbsp;(<small>{planning.language}</small>) - </span>
                    <small>({getSprintDateTime(planning.startTime, planning.endTime)})</small>
                    <span className="expand-icon">&nbsp;&nbsp;&nbsp;{planning.expanded ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}</span>
                  </h5>
                </div>
                {expandedItems.includes(index) && (
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Theme:</label>
                        <select className="form-control"
                          value={planning.sujet}
                          style={{ height: '3em' }}
                          onChange={e => handleThemeChange(index, e.target.value)}
                          ref={el => errorRefs.current[`sprint-${index}-sujet`] = el}>
                          <option value="">Select theme</option>
                          <option value="Pronunciation">Pronunciation</option>
                          <option value="Reading Out-loud">Reading Out-loud</option>
                          <option value="Speaking">Speaking</option>
                          <option value="Numbers">Numbers</option>
                          <option value="Vocabulary Builder">Vocabulary Builder</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>English Skills:</label>
                        {/* Sélecteur de compétences en anglais, conditionnellement rendu et rempli basé sur le thème sélectionné */}
                        {selectedTheme && (
                          <select  className="form-control"
                          style={{ height: '3em' }}
                          value={planning.description}
                          onChange={e => handleSkillsChange(index, e.target.value)}>
                            <option value="">Select English Skills</option>
                            {englishSkillsOptions.map((skill, index) => (
                              <option key={index} value={skill.id}>{skill.fileCode} - {skill.englishSkill}</option>
                            ))}
                          </select>
                        )}
                      </div>
                      <div className="form-group">
                        <label>File code:</label>
                        <input className="form-control" type="url" value={planning.fileCode} onChange={e => handlePlanningChange(index, 'fileCode', e.target.value)} />
                      </div>
                      <div className="form-group">
                        <label>Presentation Link:</label>
                        <input className="form-control" type="url" value={planning.presentationLink} onChange={e => handlePlanningChange(index, 'presentationLink', e.target.value)} />
                      </div>
                      <div className="form-group">
                        <label>Ice Breaker:</label>
                        <input className="form-control" type="url" value={planning.iceBreaker} onChange={e => handlePlanningChange(index, 'iceBreaker', e.target.value)} />
                      </div>
                      <div className="form-group">
                        <label>Professor:</label>
                        <select className="form-control" value={planning.professor} style={{ height: '3em' }} onChange={e => handlePlanningChange(index, 'professor', e.target.value)}>
                          <option value="">Select professor</option>
                          {professors && professors.slice().sort((a, b) => a.name.localeCompare(b.name)).map(professor => (
                            <option key={professor.id} value={professor.id}>{professor.name}</option>
                          ))}
                        </select>
                      </div>
                      <button type="button" className="beautiful-button" onClick={toggleDropdown}>Copy to other Sprint</button>
                      {isDropdownOpen && (
                        <div style={{ border: '1px solid #ccc', padding: '10px' }}>
                          {filteredPlannings.map((planning, index2) => {
                            // Exclure le sprint actuellement sélectionné pour la copie
                            if (index2 === index) return null;
                            return (
                              <div key={index2}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedTargets.includes(index2)}
                                    onChange={() => toggleSelection(index2)}
                                  />
                                  <small>&nbsp; {index2 + 1} - {planning.sprintLevel === 'a' ? 'Advanced - Intermediate Sprint' : ' Beginner/Pre-Intermediate Sprint'} - ({getSprintDateTime(planning.startTime, planning.endTime)}</small>)
                                </label>
                              </div>
                            );
                          })}
                          <button type="button" className="beautiful-button" onClick={copyDataToSelectedPlannings}>Copy to selected planning</button>
                        </div>
                      )}



                    </form>
                  </div>
                )}
              </div>
            ))}
            <div className="text-center">
              <button type="submit" className="beautiful-button" onClick={handleSubmit}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    sprints: state.firestore.ordered.sprints,
    professors: state.firestore.ordered.professor,
    sprintPlanningData: state.firestore.ordered.sprintPlanningData,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "sprints" },
    { collection: "sprintPlanningData" },
    {
      collection: "professor",
      where: ['retired', '==', false]
    }
  ])
)(SprintPlanning);
