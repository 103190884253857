import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider, useSelector } from "react-redux";
import firebase from "firebase/app";
import './i18n';
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
	createFirestoreInstance,
	reduxFirestore,
	getFirestore,
} from "redux-firestore";
import {
	ReactReduxFirebaseProvider,
	getFirebase,
	isEmpty,
	isLoaded,
} from "react-redux-firebase";
import fbConfig from "./config/fbConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "firebase/auth";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//TODO: Check for deprecated
const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk.withExtraArgument({ getFirebase, getFirestore })],
});

/*const store = createStore(
  rootReducer,
  compose(
	  composeEnhancers(
		  applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))
	  ),
	  reduxFirestore(fbConfig) // redux bindings for firestore,
	  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);*/



const rrfConfig = {
	userProfile: "users",
	useFirestoreForProfile: true,
	attachAuthIsReady: true, // attaches auth is ready promise to store
};

const rrfProps = {
	firebase,
	config: rrfConfig,
	fbConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
};

function AuthIsLoaded({ children }) {
	const auth = useSelector(state => state.firebase.auth);
	const profile = useSelector(state => state.firebase.profile);
	console.log("isEmpty(auth) ", isEmpty(auth));
	console.log("isEmpty(profile) ", isEmpty(profile));
	if (!isLoaded(auth) && !isLoaded(profile) && isEmpty(profile)) return <div className="center"></div>;
	return children;
}


const roleQuery = {
	collection: "roles",
	collection: "users",
	collection: "confidencePlanActions",

};

ReactDOM.render(
	// <React.StrictMode>
	<Provider store={store}>
		<ReactReduxFirebaseProvider {...rrfProps}>
			<AuthIsLoaded>
				<App />
			</AuthIsLoaded>
		</ReactReduxFirebaseProvider>
	</Provider>,
	// </React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
