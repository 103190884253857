
import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";
import moment from "moment";
import {
    Form,
    FormGroup,
    Label,
    Button,
} from "reactstrap";
import input from "../administration/input";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Spinner } from "reactstrap";
import firebase from "firebase/app";
require("firebase/firestore");


function RessourceForm(props) {


    // Set up some additional local state
    const [isAdd, setIsAdd] = useState(!props.edit);
    const [isModify, setIsModify] = useState(props.edit);
    const [selectedFile, setSelectedFile] = useState();
    const { ressourceCategory, edit, type, ressources, ressourceModify, close } = props;
    const [downloadURL, setDownloadURL] = useState();
    const storage = firebase.storage();
    const [imageUrl, setImageUrl] = useState(ressourceModify != null?ressourceModify.urlImage:null);

    const [ressource, setRessource] = useState({
        name: "",
        title: "",
        description: "",
        urlImage: "",
        urlRessource: "",
        createdTime: new Date(),
        uid: Math.random().toString(36).substr(2, 9),
    });


    const schema = yup.object({
        description: yup.string().required("Description is required"),
        title: yup.string().required("Title is required"),
        urlRessource: yup.string().required("Ressource is required"),
    });


    const save = async (event) => {

        const isValid = await schema.validate(event);

        if (!isValid) {
            return;
        }

        if (selectedFile) {
            const uploadTask = storage.ref("/Ressources/" + ressourceCategory.name + "/" + selectedFile.name).put(selectedFile)

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    console.error(error);
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

                        if (isAdd)
                            ressource.name = event.title
                        ressource.title = event.title
                        ressource.description = event.description
                        ressource.urlImage = downloadURL
                        ressource.urlRessource = event.urlRessource
                        ressource.createdTime = new Date()
                        if (isAdd)
                            ressource.uid = Math.random().toString(36).substr(2, 9)
                        else
                            ressource.uid = ressourceModify.uid
                        ressource.pilier = event.pilier

                        saveInDB()

                    });
                }

            );
        } else {
            if (isAdd)
                ressource.name = event.title
            ressource.title = event.title
            ressource.description = event.description
            ressource.urlImage = ressourceModify.urlImage
            ressource.urlRessource = event.urlRessource
            ressource.createdTime = new Date()
            if (isAdd)
                ressource.uid = Math.random().toString(36).substr(2, 9)
            else
                ressource.uid = ressourceModify.uid
            ressource.pilier = event.pilier
            saveInDB()
        }

    };

    const spaceStyle = {
        marginRight: '4px', // Ajouter un espace de 4 pixels avant le mot
    };

    const saveInDB = async () => {
        const firestore = firebase.firestore();
        let categories = []
        let ressourceTmp = []
        let docid = ""
        await firestore.collection('freeRessources', 'category').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    docid = doc.id

                    doc.data().categories.forEach((category) => {
                        if (category.name === ressourceCategory.name) {
                            if (isAdd) {
                                if (!category.ressources)
                                    category.ressources = []
                                category.ressources.push(ressource)
                                categories.push(category)
                            }
                            if (isModify) {
                                category.ressources.forEach((ressourceDB) => {
                                    if (ressourceDB.uid != ressource.uid)
                                        ressourceTmp.push(ressourceDB)

                                })
                                ressourceTmp.push(ressource)
                                category.ressources = ressourceTmp
                                categories.push(category)
                            }
                        } else
                            categories.push(category)

                    })
                });
            })


        if (docid !== "" && docid !== undefined && categories !== []) {
            firestore.collection('freeRessources').doc(docid).update({
                categories
            }).catch((error) => {
                console.log(error)
            }
            )
        }
        close()
    };


    const onFileChange = async event => {

        setSelectedFile(event.target.files[0]);


        setImageUrl(URL.createObjectURL(event.target.files[0]));

    };


    if (!isLoaded(ressources)) {
        return (
            <div className="text-center mt-3">
                <Spinner />
            </div>
        )
    } else {


        return (
            <div className="form">
                {!isAdd && !isModify && (
                    <div align="right">
                        <div>
                            <i className={`icon-container fas fa-plus-circle`} onClick={() => addRow()}>
                            </i>
                        </div>
                    </div>)}


                {isAdd && (<div align="center">
                    <div>
                        <h4>Add a new ressource to '{ressourceCategory.title}'</h4>
                    </div>
                </div>)}

                {isModify && (<div align="center">
                    <div>
                        <h4>Update the entry</h4>
                    </div>
                </div>)}


                {(isAdd || isModify) && (
                    <Formik
                        validationSchema={schema}
                        initialValues={isAdd ? ressource : ressourceModify}
                        onSubmit={save}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isInvalid,
                            errors,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <Field name="title" type="text"
                                        component={input} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Description</Label>
                                    <Field name="description" type="text"
                                        component={input} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Image</Label>
                                    <Field name="selectedFile1" type="file"
                                        component={input} onChange={onFileChange} accept="image/*" />

                                    {imageUrl && <div><br></br><img alt="preview image" src={imageUrl} style={{ height: 100, width: 100 }} /> </div>}
                                </FormGroup>

                                <FormGroup>
                                    <Label>Resource link</Label><br></br>
                                    <Field name="urlRessource" type="text"
                                        component={input} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Pilier</Label><br></br>
                                    <Field type="checkbox" name="pilier" value="1" /><span style={spaceStyle}></span>
                                    Mindset &nbsp;
                                    <Field type="checkbox" name="pilier" value="2" /><span style={spaceStyle}></span>
                                    Fluency &nbsp;
                                    <Field type="checkbox" name="pilier" value="3" /><span style={spaceStyle}></span>
                                    Stress Management &nbsp;
                                    <Field type="checkbox" name="pilier" value="4" /><span style={spaceStyle}></span>
                                    Active Listening &nbsp;
                                    <Field type="checkbox" name="pilier" value="5" /><span style={spaceStyle}></span>
                                    Non-Verbal Communication &nbsp;
                                    <Field type="checkbox" name="pilier" value="6" /><span style={spaceStyle}></span>
                                    Risk Taking &nbsp;
                                    <Field type="checkbox" name="pilier" value="7" /><span style={spaceStyle}></span>
                                    Commitment &nbsp;
                                </FormGroup>

                                <Button type="submit" style={{ marginRight: "10px" }}  >
                                    Save
                                </Button>
                                <Button type="button" onClick={() => {
                                    setIsAdd(false)
                                    setIsModify(false)
                                    close()
                                }}>
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>)}
            </div>
        );
    }
}

const mapToProps = state => {
    return {
        ressources: state.firestore.ordered.freeRessources,
    };
};
export default connect(
    mapToProps,
    firestoreConnect([{ collection: "freeRessources" }])
)(RessourceForm);

