
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, Link } from "react-router-dom";
import logoSprint from "./images/EllipseSprint.png";
import calendar from "./images/Sprint Calendar.png";
import ressources from "./images/Ressources.png";
import startSprint from "./images/Get Ready - Subscription.png";
import notSign from "./images/No-Ended Subscription.png";
import hollidayCalendar from "./images/calendar_red.png";
import summerSprint from "./images/SummerSprint.jpg";
import summerSprintFr from "./images/SummerSprint-fr.jpg";
import christmasCalendar from "./images/calendar-christmas.jpg";
import moment from "moment";
import firebase from "firebase/app";
import Alert from 'react-bootstrap/Alert';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { v4 as uuidv4 } from 'uuid';
import {
	Row,
	Col,
	Button,
	Spinner,
	Container,
} from "reactstrap";

import ShowMessage from "../administration/showMessage";

class EllipseSprintV3 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopup: false,  // Initialement, le popup est visible
		  };
	}

	closePopup = () => {
		this.setState({ showPopup: false });
	  };

	render() {

		const { sprints, auth, profile, loaded, sprintsHoliday, t } = this.props;
		const { showPopup } = this.state;


		const Popup = ({ closePopup }) => {
			return (
			  <div className="popup-container">
				<div className="popup-content">
				  <button className="close-button" onClick={closePopup}><i className="fas fa-times"></i> </button>
				<img src={profile.sprintLanguageAccess ==="fr"?summerSprintFr:summerSprint}  alt="Popup" className="popup-image" />
				</div>
			  </div>
			);
		  };


		let availableSprint
		let holiday

		const day = ['Monday', 'Tuesday', 'Wednesday', 'Thusday', 'Friday', 'Saturday', 'Sunday']



		let today = new Date()
		const currentDayOfWeekNumber = new Date().getDay(); // Obtient le numéro du jour de la semaine (0 pour dimanche, 1 pour lundi, ..., 6 pour samedi)

		if (isLoaded(sprints) && sprints) {
			availableSprint = sprints
				.filter(sprint =>
					sprint.disabled === "false" &&
					(
						(profile.sprintLanguageAccess === undefined && sprint.language === "en") ||
						(profile.sprintLanguageAccess !== undefined && sprint.language === profile.sprintLanguageAccess) ||
						(profile.sprintLanguageAccess === "all")
					)
					&& sprint.selectedDays.includes(currentDayOfWeekNumber)
				)
				.sort((a, b) => a.startTime > b.startTime ? 1 : -1);
		}

		if (sprintsHoliday) {
			holiday = sprintsHoliday[0];
		}
		if (!auth.uid) {
			return <Redirect to="/signin" />;
		}

		if (!isLoaded(sprints) || !isLoaded(profile) || sprintsHoliday == null) {
			return (
				<div className="text-center mt-3">
					<Spinner />
				</div>
			)
		} else {

			return (

				<div className="dashboard container mx-auto">
					{showPopup && <Popup closePopup={this.closePopup} />}
					{!isLoaded ? (
						<div className="text-center mt-3">
							<Spinner />
						</div>
					) : (
						<div >
							<div >
								<div className="card-body black-text">
									<Row className="justify-content-md-center">
										<Col>
										</Col>
										<Col  >
											<span className="center">
												<img src={logoSprint} width="70%" className="logo" alt="Ellipse" />
											</span>
										</Col>
										<Col>
										</Col>
									</Row>
									{isUserSubscribeToSprint(profile) ? (
										<Row >
											<Col >
											</Col>
											<Col >
												<span >
													<p><small className="text-muted">{getTypeSubcriptionAndAvailibility(profile, t)}</small></p>
												</span>
											</Col>
											<Col ></Col>

										</Row>) :
										("")}
									<center><p style={{ borderRadius: '5px' }} className="sprint-text_attendance"> {getAttendanceSprintNumber(profile, t)}</p></center>
									{profile.role != null && profile.role.indexOf("admin") > -1 && profile.company !== null && profile.company !== "" ? (
										<div className="text-right">
											<p><span className="bold-and-large flashing" style={{ color: 'red' }}>Coming soon </span><b>Friendly Business Challenge</b> <i className="fa fa-trophy"></i></p>
										</div>
									) :
										("")}
								</div>
								{isVacation(holiday) ? (
									<Alert variant="danger">
										<Alert.Heading>{holiday.vacationTitle}</Alert.Heading>
										<p>
											{holiday.messageVacation}
										</p>
									</Alert>) :
									("")}

							</div>
							{isUserSubscriptionActive(profile) || isUserSubscribeToSprintStartSoon(profile) ? (
								<div >
									{!holiday.isHoliday && !isVacation(holiday) ? (
										<div >

											{availableSprint.map(result => (
												<div style={{
													border: '1px solid #ccc',
													borderRadius: '10px',
													boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
													marginBottom: '20px',
													backgroundColor: result.sprintColor || '#ffffff',
													padding: '20px',
													textAlign: 'center', // Centrer le contenu
												}} className="card mt-5"   key={uuidv4()}>
													<div className="card-body black-text">
														<Container fluid>
															<Row className="justify-content-md-center" style={{ backgroundColor: result.levelSprint === 'b' ? '#B0D50B' : '#f0eeee', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
																{isSprintCancel(result.cancelUntilDate) ? (
																	<div>
																		<h4 className="mb-3"><strong className="red-text-bigger">{this.props.t('sprint_canceled')}</strong></h4>
																		<strong >{result.level}</strong>
																	</div>
																) : (
																	<h4><strong><p style={{ marginTop: '20px' }}>{result.level}</p></strong></h4>
																)}
															</Row>
															<Row className="align-items-center" style={{ backgroundColor: result.levelSprint === 'b' ? '#B0D50B' : '#f0eeee', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
																<Col md="4" sm="3">
																	<span>
																		{isDateTimePass(result.startTime) || isSprintCancel(result.cancelUntilDate) ? (
																			<s>{getBootCampDateTime(result.startTime, result.endTime)}</s>) :
																			(getBootCampDateTime(result.startTime, result.endTime))}
																	</span>
																	{isSprintCancel(result.cancelUntilDate) && (
																		<div>
																			<br /><small className="red-text">{this.props.t('sprint_erreur_message')}</small>
																		</div>
																	)}
																</Col>
																<Col md="6" sm="6">
																	<span>
																		<strong>{result.title}</strong> {`(Language - ${result.language === 'en' ? 'English' : 'Français'})`}
																	</span>
																</Col>
																<Col md="2" sm="1">
																	<Button
																		className="btn white-text"
																		color="primary"
																		size="lg"
																		disabled={isDateTimePass(result.startTime) || isSprintCancel(result.cancelUntilDate) || isUserSubscribeToSprintStartSoon(profile)}
																		onClick={() => linkClick(result.startTime, profile, result.joinUrl)}
																		style={{ marginBottom: '30px' }}
																	>
																		{isUserSubscribeToSprintStartSoon(profile) ? (
																			<span className="small-text">{this.props.t('sprint_get_ready')} (<b>{profile.bootCampStartDate}</b>)</span>
																		) : (
																			<span>{this.props.t('sprint_join_us')}</span>
																		)}
																	</Button>
																</Col>
															</Row>

															<Row className="justify-content-md-center">
																<a href={result.calendarLink} target="_blank"><img width="100" src={calendar} alt="Calendar" /></a>
																<a href={result.ressourcesLink} target="_blank"><img width="100" src={ressources} alt="Resources" /></a>

															</Row>
														</Container>
													</div>
												</div>

											))}
											<br></br>
											<center><b>{this.props.t('sprint_need_help')}<a href="mailto:hello@groupellipse.com"> hello@groupellipse.com</a></b></center>
											<br></br>
										</div>
									) : (<div className="card-body black-text">
										<Row className="align-items-center">
											<Col md="4" sm="3">
											</Col>
											<Col md="6" sm="6">
												<div className="card  mt-5 card-sprint center">
													<img className="card-img-top" src={holiday.titleHoliday === 'holiday season' ? christmasCalendar : hollidayCalendar} alt="Card image cap" />
													<div claclassNamess="card-body">
														<h5 className="card-title">{holiday.titleHoliday}</h5>
														<p className="card-text">{holiday.holiday}</p>
													</div>

												</div>
											</Col>
										</Row>
									</div>)}
								</div >
							) :
								(<div >
									{isUserSubscribeToSprint(profile) ? (
										<div className="card-body black-text">
											<Row className="align-items-center">
												<Col md="4" sm="3">
												</Col>
												<Col md="6" sm="6">
													<div className="card  mt-5 card-sprint center">
														<img className="card-img-top" src={notSign} alt="Card image cap" />
														{isUserSubscribeToSprintStartSoon(profile) ? (
															<div className="card-body">
																<h5 className="card-title">{this.props.t('sprint_subscription')}</h5>
																<p className="card-text">{this.props.t('sprint_get_ready')} (<b>{profile.bootCampStartDate}</b>).</p>
															</div>
														) : (
															<div>
																{isUserSubscribeToSprintPause(profile) ? (
																	<div className="card-body">
																		<h5 className="card-title">{this.props.t('sprint_subscription_paused')}</h5>
																		<p className="card-text">{this.props.t('sprint_subscription_paused_until', { date: profile.pauseSprintUntil })}</p>
																		<a href="http://www.groupellipse.com/ellipse-sprint" className="btn btn-primary">{this.props.t('sprint_ellipse_sprint')}</a>
																	</div>
																) : (
																	<div className="card-body">
																		<h5 className="card-title">{this.props.t('sprint_subscription_ended')}</h5>
																		<p className="card-text">{this.props.t('sprint_subscription_ended_until', { date: getSprintEndDate(profile) })}</p>
																		<a href="http://www.groupellipse.com/ellipse-sprint" className="btn btn-primary">{this.props.t('sprint_ellipse_sprint')}</a>
																	</div>)}
															</div>
														)}
													</div>
												</Col>
											</Row>
										</div>) :

										(<div className="card-body black-text">
											<Row className="align-items-center">
												<Col md="4" sm="3">
												</Col>
												<Col md="6" sm="6">
													<div className="card  mt-5 card-sprint center">
														<img className="card-img-top" src={startSprint} alt="Subscription" />
														<div className="card-body">
															<h5 className="card-title">{this.props.t('sprint_no_subscription')}</h5>
															<p className="card-text">{this.props.t('sprint_no_subscription_message')}</p>
															<a href="http://www.groupellipse.com/ellipse-sprint" className="btn btn-primary">Ellipse Sprint</a>
														</div>
													</div>
												</Col>
											</Row>
										</div>)}
								</div>)}

						</div>
					)
					}
				</div >

			);
		}
	}
}

function linkClick(hour, profile, pathName) {
	const firestore = firebase.firestore();

	window.open(pathName);

	firestore.collection('users').where("email", "==", profile.email).get().then(resp => {

		//firestore.collection('users').doc(profile.id).get().then(resp => {
		let data = [];
		let id = null;
		resp.forEach((doc) => {
			data = doc.data();
			id = doc.id;
		})

		let sprintAttendance;

		if (data.sprintAttendance == null) {

			let date = new Date()
			sprintAttendance = []
			date.setHours(moment.utc(hour, ["h:mm"]).local().format("HH:mm").split(":")[0])
			date.setMinutes(moment.utc(hour, ["h:mm"]).local().format("HH:mm").split(":")[1])
			date.setSeconds(0)
			date.setMilliseconds(0)

			sprintAttendance.push(date)
			sprintAttendance = [date]
		}
		else {
			sprintAttendance = data.sprintAttendance
			let date = new Date()

			date.setHours(moment.utc(hour, ["h:mm"]).local().format("HH:mm").split(":")[0])
			date.setMinutes(moment.utc(hour, ["h:mm"]).local().format("HH:mm").split(":")[1])
			date.setSeconds(0)
			date.setMilliseconds(0)

			let exist = false;

			if (sprintAttendance)
				sprintAttendance.forEach(element => element.toDate().getTime() === date.getTime() ? exist = true : false);
			if (!exist)
				sprintAttendance.push(date)
		}

		data.sprintAttendance = sprintAttendance


		firestore
			.collection("users")
			.doc(id)
			.update(data)
			.then(() => {

			})
			.catch(error => console.log(error.message))
	})
	//
}


function getBootCampDateTime(startTime, endTime) {
	const date = new Date();

	moment.locale(i18n.language);

	const newDate = moment(date).format("DD-MM");

	if (i18n.language == 'fr')
		return (
			newDate.split("-")[0] +
			" " +
			moment(newDate.split("-")[1], "M").format("MMMM") +
			" " +
			moment.utc(startTime, ["h:mm"]).local().format("HH:mm A") +
			" - " +
			moment.utc(endTime, ["h:mm"]).local().format("HH:mm A")
		);

	return (
		moment(newDate.split("-")[1], "M").format("MMMM") +
		" " +
		newDate.split("-")[0] +
		" " +
		moment.utc(startTime, ["h:mm"]).local().format("HH:mm A") +
		" - " +
		moment.utc(endTime, ["h:mm"]).local().format("HH:mm A")
	);
}


function isDateTimePass(endTime) {
	const date = new Date();

	const newDate = moment(date).format("DD-MM");

	return (
		moment.utc(endTime, ["h:mm"]).local().add(30, 'minutes').format("HH:mm A") < moment.utc(date, ["h:mm"]).local().format("HH:mm A")
	);
}

function isVacation(holiday) {
	const date = new Date();

	const newDate = moment(date).format("YYYY-MM-DD");
	const vacationStart = moment(holiday.startDateVacation + "T00:00:00").format("YYYY-MM-DD");
	const vacationEnd = moment(holiday.endDateVacation + "T00:00:00").format("YYYY-MM-DD");

	return (
		(moment.utc(vacationStart).local().format("YYYY-MM-DD") <= moment.utc(newDate).local().format("YYYY-MM-DD") &&
			moment.utc(vacationEnd).local().format("YYYY-MM-DD") >= moment.utc(newDate).local().format("YYYY-MM-DD"))
	);
}


function isUserSubscribeToSprintPause(profile) {
	return false
}

function getSprintNextPayment(date) {
	const newDate = moment(date).add(1, 'month').format("YYYY-MM-DD");

	return (
		newDate
	);
}

function isUserSubscribeToSprintStartSoon(profile) {
	if (profile.bootCampStartDate != null && profile.bootCampStartDate != "") {
		let isStartSoon = moment(new Date(profile.bootCampStartDate + "T00:00:00")).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD")
		return isStartSoon
	}
	return false
}

function getSprintEndDate(profile) {
	let endDate
	if (profile.subscribeBootCamp == null)
		return profile.bootCampEndDate

	if (profile.subscribeBootCamp.toLowerCase() === 'yearly' || profile.subscribeBootCamp.toLowerCase() === 'yes') {
		endDate = profile.bootCampEndDate
	}


	if (profile.subscribeBootCamp === 'monthly') {

		endDate = getSprintNextPayment(profile.lastPaymentSprint)
	}

	return (
		endDate
	);
}

function isUserSubscribeToSprint(profile) {


	let Subscribe = false

	if (profile.subscribeBootCamp != null && profile.subscribeBootCamp != "") {
		Subscribe = profile.subscribeBootCamp.toLowerCase() === 'yearly'
			|| profile.subscribeBootCamp.toLowerCase() === 'monthly'
			|| profile.subscribeBootCamp.toLowerCase() === 'company'
			|| profile.subscribeBootCamp.toLowerCase() === 'yes'
	} else {
		Subscribe = profile.bootCampStartDate != null && profile.bootCampStartDate != ""
	}

	return (
		Subscribe
	);
}


function isUserSubscriptionActive(profile) {
	let isActive = false
	let nowFormat = moment(new Date()).format("YYYY-MM-DD")

	if (profile.pauseSprintFrom != null && profile.pauseSprintUntil != null) {
		let startpauseDate = moment(profile.pauseSprintFrom).format("YYYY-MM-DD")
		let endpauseDate = moment(profile.pauseSprintUntil).format("YYYY-MM-DD")

		if (startpauseDate <= nowFormat && nowFormat <= endpauseDate) {
			return false
		}
	}

	if (profile.subscribeBootCamp == null || profile.subscribeBootCamp === "" || profile.subscribeBootCamp.toLowerCase() === 'yearly' || profile.subscribeBootCamp.toLowerCase() === 'yes') {

		let endDateFormat = moment(new Date(profile.bootCampEndDate + "T00:00:00")).format("YYYY-MM-DD")
		let startDateFormat = moment(new Date(profile.bootCampStartDate + "T00:00:00")).format("YYYY-MM-DD")


		isActive = nowFormat <= endDateFormat && nowFormat >= startDateFormat

		return isActive
	}


	if (profile.subscribeBootCamp.toLowerCase() === 'monthly' && moment(profile.lastPaymentSprint).add(1, 'month').add(1, 'day') >= new Date()) {

		isActive = true
	}

	if (profile.subscribeBootCamp.toLowerCase() === 'company') {

		isActive = true
	}


	return (
		isActive
	);
}

function isSprintCancel(dateCancel) {
	const date = new Date();

	if (dateCancel === null)
		return false;

	//Check morning Cancellation
	if (moment(date, ["YYYY-MM-DD h:mm"]) < moment(dateCancel, ["YYYY-MM-DD h:mm"])) {
		return true

	}

	return false


}

function getTypeSubcriptionAndAvailibility(profile, t) {

	if (profile.subscribeBootCamp != null && profile.subscribeBootCamp.toLowerCase() === 'company') {
		return (<strong>{t('sprint_subscription_company')}</strong>)

	} else {

		return (
			profile.subscribeBootCamp != null && profile.subscribeBootCamp.toLowerCase() === 'monthly' ? (<strong>{t('sprint_monthly_subscription')} {moment(profile.lastPaymentSprint).add(1, 'month').format("YYYY-MM-DD")}</strong>)
				: (<strong>{t('sprint_available_from')} {profile.bootCampStartDate} {t('sprint_to')} {profile.bootCampEndDate} </strong>)
		);

	}


}

function getAttendanceSprintNumber(profile, t) {

	var sprintNumber = 0;
	var sprintCase = 0
	var message = ""

	if (profile.sprintAttendance != null) {
		sprintNumber = profile.sprintAttendance.length
	}

	if (sprintNumber % 5 == 0 || sprintNumber == 0 || sprintNumber == 1 || sprintNumber == 3) {

		if (profile.email === 'hilma@groupellipse.com') {
			sprintNumber = 20
		}

		if (sprintNumber > 100) {
			sprintCase = 100
		} else {
			sprintCase = sprintNumber
		}
		message = t('attendance_' + sprintCase, { nb: sprintNumber, name: profile.firstname })

		if (profile.email === 'lanm80@gmail.com') {
			message = 'WOW Martin you are the best! Now you have assist more sprint than anyone else!'
		}

	}


	return message


}


const mapState = state => {
	return {
		bootCamp: state.firestore.ordered.bootCamp,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		sprints: state.firestore.ordered.sprints,
		sprintsHoliday: state.firestore.ordered.sprintsHoliday,
	};
};

export default compose(
	connect(mapState),
	firestoreConnect([{ collection: "bootCamp" }, { collection: "sprints" }, { collection: "sprintsHoliday" }]),
	withTranslation()
)(EllipseSprintV3);

