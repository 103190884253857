import React, { useEffect, useState } from "react";
import {
	Col,
	FormGroup,
	Input,
	Label,
	Button,
	Row,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Modal, ModalHeader, ModalBody, Spinner,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import HourByProfessor from "./hourByProfessor";
import Sprints from "./sprintsAdministration";
import Companies from "./companyAdministration";
import firebase from "firebase";
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';


import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Avatar from '@material-ui/core/Avatar';
import MenuItem from "@material-ui/core/MenuItem";
import classnames from "classnames";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';

import {
	fetchCompany,
	fetchProfessor,
	fetchUsers,
	fetchMastermind,
	fetchContracts,
	searchUsers,
	fetchBootCamp,
	fetchRoles,
} from "../../store/actions/miscActions";

import { createUser, updateUser } from "../../store/actions/authActions";







const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),

		[theme.breakpoints.down("sm")]: {
			minWidth: 389,
			maxWidth: 389,
		},
		[theme.breakpoints.up("md")]: {
			minWidth: 396,
			maxWidth: 396,
		},
		[theme.breakpoints.up("lg")]: {
			minWidth: 389,
			maxWidth: 389,
		},
		[theme.breakpoints.down("xs")]: {
			minWidth: 331,
			maxWidth: 331,
		},
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name, personName, theme) {
	if (personName)
		return {
			fontWeight:
				personName.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
}

const Administration = props => {
	const classes = useStyles();
	const theme = useTheme();

	const [show, setShow] = useState(false);

	//LEARNING LOG FORM
	const openModal = () => {
		setShow(true);
	};
	const closeModal = () => {
		setShow(false);
	};

	const [userObj, setUserObj] = useState({
		email: "",
		firstname: "",
		lastname: "",
		numberHourBuy: 0,
		numberHoursRemaining: 0,
		Professors: [],
		contracts: [],
		role: [],
		company: "",
		mastermind: "",
		bootCamp: "",
		bootCampEndDate: "",
		subscribeBootCamp: "",
		bootCampStartDate: "",
		pauseSprintFrom: "",
		pauseSprintUntil: "",
		lastPaymentSprint: null,
		confidenceObjective1: "",
		confidenceObjective2: "",
		confidenceObjective3: "",
		actionPlans: [],
	});

	///Action Plan
	// Utilisation de l'état (state) pour suivre la nouvelle valeur en cours de saisie
	const [nouvelleValeurActionPlan, setNouvelleValeurActionPlan] = useState({
		description: "",
		id: "",
	});


	// Gérer la sélection des cases à cocher
	const handleCheckboxChange = (actionIndex, isChecked) => {

		const updatedUserObj = { ...userObj }; // Créez une copie de l'objet userObj

		if (!updatedUserObj.actionPlans) {
			updatedUserObj.actionPlans = []; // Initialisez l'array actionPlans s'il est inexistant
		}

		if (isChecked) {
			// Ajoutez l'action à l'array actionPlans si la case est cochée
			updatedUserObj.actionPlans.push(actionPlansDB[0].actions[actionIndex]);
		} else {
			// Retirez l'action de l'array actionPlans si la case est décochée (en supposant que l'id est unique)
			updatedUserObj.actionPlans = updatedUserObj.actionPlans.filter(
				(actionPlan) => actionPlan.id !== actionPlansDB[0].actions[actionIndex].id
			);
		}

		setUserObj(updatedUserObj);
	};


	// Fonction pour ajouter une nouvelle valeur à la liste
	const ajouterActionPlan = () => {
		const updatedUserObj = { ...userObj }; // Créez une copie de l'objet userObj
		const nouvellesActionPlans = [...actionPlansDB[0].actions, nouvelleValeurActionPlan];

		if (!updatedUserObj.actionPlans) {
			updatedUserObj.actionPlans = []; // Initialisez l'array actionPlans s'il est inexistant
		}

		// Ajoutez l'action à l'array actionPlans si la case est cochée
		updatedUserObj.actionPlans.push(nouvelleValeurActionPlan);


		setUserObj(updatedUserObj);
		setNouvelleValeurActionPlan({ ...nouvelleValeurActionPlan, description: "", id: "" })

	};



	// Fonction pour supprimer une valeur de la liste
	const handleDeleteActionPlan = (index, e) => {
		e.preventDefault();

		const nouvellesValeurs = [...actionPlansDB[0].actions];
		nouvellesValeurs.splice(index, 1);

		const nouvellesActionPlansDB = JSON.parse(JSON.stringify(actionPlansDB));

		nouvellesActionPlansDB[0].actions = nouvellesValeurs;

		const firestore = firebase.firestore();


		firestore
			.collection("confidencePlanActions")
			.doc(actionPlansDB[0].id)
			.update(nouvellesActionPlansDB[0])
			.then(() => {

			})
			.catch(error => console.log(error.message))
	};

	const actionPlansDB = props.actionPlansDB
	const [isUpdate, setUpdate] = useState(false);
	const [isSearch, setSearch] = useState(false);
	const { profile } = props;
	const [student, setStudent] = useState("");
	const [studentName, setStudentName] = useState("");


	const [searchObj, setSearchObj] = useState({
		email: "",
		firstname: "",
		lastname: "",
		company: "",
		mastermind: "",
	});

	const [activeTab, setActiveTab] = useState(profile.role != null && profile.role.indexOf("admin") > -1 ? "1" : "2");

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	useEffect(() => {
		// Effectuez des actions supplémentaires ici en fonction de la mise à jour de actionPlans

	}, [userObj]); // Spécifiez actionPlans comme dépendance

	const { auth } = props;
	useEffect(() => {
		console.log("useEffect");
		props.fetchRoles();
		props.fetchCompany();
		props.fetchProfessor();
		props.fetchUsers();
		props.fetchMastermind();
		props.fetchContracts();
		props.fetchBootCamp();
	}, []);
	const handleChange = e => {

		let value = "";

		if (e.target.name === "numberHourBuy" || e.target.name === "numberHoursRemaining") {
			value = Number(e.target.value);
			//updateRemainingHour(value);
		} else {
			value = e.target.value;
		}

		if (e.target.name === "email") {
			value = value.trimEnd().toLowerCase()
		}

		if (e.target.name === "accountManager") {
			value = userObj.role
			if (value == null)
				value = []
			value.push("account manager")
			setUserObj({ ...userObj, role: value });
		} else {

			setUserObj({ ...userObj, [e.target.name]: value });
		}
	};


			//  Update number hour remaining
			const updateRemainingHour = (value) => {
				let nbHourInLearningLog = 0
		
				let allLearningLog = learningLogsDB.filter(ll => ll.userId == userObj.id).filter(ll => (ll.retired == false || ll.retired == null)).sort((a, b) => a.date > b.date ? -1 : 1)
		
		
				allLearningLog.forEach(elt => nbHourInLearningLog += elt.duration === '' ? 0 : elt.duration)
		
		
				console.log("nbHourInLearningLog", nbHourInLearningLog)
				userObj.numberHoursRemaining = value - nbHourInLearningLog;
		
			};


	const handleSearchChange = e => {
		setSearch(false);
		setSearchObj({ ...searchObj, [e.target.name]: e.target.value });
	};


	const handleSubmit = async e => {
		e.preventDefault();

		let obj = props.users.find(
			item => item.email === userObj.email
		);
		if (obj != null && isUpdate === false) {
			alert("This email already exist. Search before and then update the user")
		} else {
			if (JSON.parse(localStorage.getItem("is_new")) === true) {
				if (isUpdate === true) {
					await props.updateUser(userObj);
				} else {
					await props.createUser(userObj);
				}
			} else {
				if (isUpdate === true) {
					const firestore = firebase.firestore();
					firestore
						.collection("users")
						.doc(userObj.id)
						.update(userObj)
						.then(() => {

						})
						.catch(error => console.log(error.message))

					//await props.updateUser(userObj, editContractState);
				} else {
					await props.createUser(userObj);
				}
			}
			setUserObj({
				email: "",
				firstname: "",
				lastname: "",
				numberHourBuy: 0,
				numberHoursRemaining: 0,
				Professors: [],
				role: [],
				company: "",
				mastermind: "",
				contracts: [],
				bootCamp: "",
				bootCampEndDate: "",
				bootCampStartDate: "",
				subscribeBootCamp: "",
				lastPaymentSprint: null,
			});
			setUpdate(false);
			alert("Update/Create Sucessfull")
		}
	};

	if (!auth.uid || profile.firstname === null) {
		return <Redirect to="/signin" />;
	}

	if (profile.isEmpty) {
		return <Redirect to="/" />;
	}

	const getSearchResults = () => {
		let searchResults = [];
		searchResults = props.users.filter(user => {
			let flag = false;



			if ((searchObj.firstname === "" || (user.lastname != null && user.firstname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchObj.firstname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))) &&
				(searchObj.lastname === "" || (user.lastname != null && user.lastname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchObj.lastname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))) &&
				(searchObj.email === "" || (user.email != null && user.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchObj.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))))
			) {
				flag = true;
			}

			if (profile.role != null && profile.role.indexOf("admin") <= -1) {
				if ((searchObj.firstname === "" && searchObj.lastname === "" && searchObj.email === "") || (searchObj.firstname.length > 0 && searchObj.firstname.length < 4) || (searchObj.lastname.length > 0 && searchObj.lastname.length < 4))
					flag = false;
			}

			return flag;
		});
		return searchResults;
	};

	const getMastermindName = mastermindId => {
		let name = "";
		let obj = props.mastermind.find(it => it.id === mastermindId);
		name = obj != null ? obj.name : "N/A";
		return name;
	};

	const getCompanyName = companyId => {
		let name = "";
		let obj = props.company.find(it => it.id === companyId);
		name = obj != null ? obj.name : "N/A";
		return name;
	};
	const getContractDateString = contractId => {
		let constractDate = "";
		let obj = props.contracts.find(it => it.id === contractId);
		constractDate = obj != null ? obj.startDate + " to " + obj.endDate : "";
		return constractDate;
	};


	const getLabelProfessor = value => {
		let obj = props.professors.find(item => item.id === value);

		if (obj != null)
			return obj.name;
		else
			return "";

	};
	const getPictureUrlProfessor = value => {
		let obj = props.professors.find(item => item.id === value);

		if (obj != null) {
			return obj.pictureUrl;
		}
		else {
			return "";
		}
	};
	if (!isLoaded(props.roles) || !isLoaded(props.users) || !isLoaded(props.mastermind) || !isLoaded(props.company) || !isLoaded(props.professors) || !isLoaded(props.contracts)) {

		return (
			<div className="text-center mt-3">
				<Spinner />
			</div>
		)
	} else {

		return (
			<>
				<div className="dashboard container my-4">
					<Nav tabs>
						{profile.role != null && (profile.role.indexOf("admin") > -1 || profile.role.indexOf("studentAdmin") > -1) && (
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "1" })}
									onClick={() => {
										toggle("1");
									}}
								>
									Add Member
								</NavLink>
							</NavItem>
						)}
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggle("2");
								}}
							>
								Search Member
							</NavLink>
						</NavItem>
						{profile.role != null && profile.role.indexOf("admin") > -1 && (
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "3" })}
									onClick={() => {
										toggle("3");
									}}
								>
									Add coach
								</NavLink>
							</NavItem>
						)}
						{profile.role != null && profile.role.indexOf("admin") > -1 && (
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "4" })}
									onClick={() => {
										toggle("4");
									}}
								>
									Get hours by coach
								</NavLink>
							</NavItem>
						)}
						{profile.role != null && (profile.role.indexOf("admin") > -1 || profile.role.indexOf("sprint") > -1) && (
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "5" })}
									onClick={() => {
										toggle("5");
									}}
								>
									Sprints
								</NavLink>
							</NavItem>
						)}
						{profile.role != null && profile.role.indexOf("admin") > -1 && (
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "6" })}
									onClick={() => {
										toggle("6");
									}}
								>
									Companies
								</NavLink>
							</NavItem>
						)}
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div className="row mt-3">
								<div className="col-lg-6 col-md-12">
									<form onSubmit={handleSubmit}>
										<FormGroup row>
											<Label sm={3}>Email</Label>
											<Col sm={9}>
												<Input
													required
													value={userObj.email}
													type="email"
													onChange={handleChange}
													name="email"
												/>
												<Button
													className="d-block d-lg-none mt-2"
													onClick={() => {
														if (userObj.email === "") {
															alert("Provide an email before searching");
															return;
														}
														let obj = props.users.find(
															item => item.email === userObj.email
														);
														if (obj != null) {
															setUserObj(obj);
															setUpdate(true);
														} else {
															setUpdate(false);

															alert(
																"User with this email doesnt exists create a new user"
															);
															setUserObj({
																...userObj,
																firstname: "",
																lastname: "",
																numberHourBuy: 0,
																numberHoursRemaining: 0,
																Professors: [],
																contracts: [],
																company: "",
																mastermind: "",
																bootCampEndDate: "",
																bootCampStartDate: "",
																subscribeBootCamp: "",
																lastPaymentSprint: null,
															});
														}
													}}
													block
													color="primary"
												>
													Search
												</Button>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>First name</Label>
											<Col sm={9}>
												<Input
													required
													value={userObj.firstname}
													type="text"
													onChange={handleChange}
													name="firstname"
												/>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Last name</Label>
											<Col sm={9}>
												<Input
													required
													value={userObj.lastname}
													type="text"
													onChange={handleChange}
													name="lastname"
												/>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Hour Bought</Label>
											<Col sm={9}>
												<Input
													required
													value={Number(userObj.numberHourBuy)}
													step="0.5"
													type="number"
													min="0"
													onChange={handleChange}
													name="numberHourBuy"
												/>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Hour remaining</Label>
											<Label sm={3}>{userObj.numberHoursRemaining}</Label>
										</FormGroup>

										<FormGroup row>
											<Label sm={3}>Professor</Label>
											<Col sm={9}>
												{ }

												<FormControl variant="outlined" style={{ width: "100%" }}>
													<Select
														multiple
														name="Professors"
														value={userObj.Professors}
														onChange={handleChange}
														renderValue={selected => (
															<div className={classes.chips}>
																{selected.map(value => (
																	<Chip
																		avatar={<Avatar alt={getLabelProfessor(value)}
																			src={getPictureUrlProfessor(value)} />}
																		key={value}
																		label={getLabelProfessor(value)}
																		className={classes.chip}
																	/>
																))}
															</div>
														)}
														MenuProps={MenuProps}
													>
														{props.professors.filter(ll => (ll.retired == false || ll.retired == null)).sort((a, b) => a.name < b.name ? -1 : 1).map((item, ind) => (
															<MenuItem
																key={ind}
																value={item.id}
																style={getStyles(
																	item.name,
																	userObj.Professors,
																	theme
																)}
															>
																{item.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Company</Label>
											<Col sm={9}>
												<Input
													value={userObj.company}
													type="select"
													onChange={handleChange}
													name="company"
												>
													<option value="" disabled selected>
														Select Company
													</option>
													{props.company.map(item => {
														return <option value={item.id}>{item.name}</option>;
													})}
												</Input>
												<br></br>
												<Label for="startDate">Account manager of the company</Label>
												<Input
													type="select"
													name="accountManager"
													id="accountManager"
													onChange={handleChange}
													value={userObj.role != null && userObj.role != "" ? userObj.role.some(role => role === "account manager") : ""}>
													<option value="false" selected>No</option>
													<option value="true">Yes</option>
												</Input>

											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Mastermind</Label>
											<Col sm={9}>
												<Input
													type="select"
													value={userObj.mastermind}
													onChange={handleChange}
													name="mastermind"
												>
													<option value="" >
														Aucun
													</option>

													{props.mastermind.filter(mm => (mm.retired === false || mm.retired === null)).sort((a, b) => a.name < b.name ? -1 : 1).map(item => {
														return <option value={item.id}>{item.name}</option>;
													})}
												</Input>
											</Col>
										</FormGroup>
										{(profile.role.indexOf("admin") > -1) ? (
											<FormGroup row>
												<Label sm={3}>Role</Label>
												<Col sm={9}>
													<FormControl variant="outlined" style={{ width: "100%" }}>
														<Select
															multiple
															name="role"
															value={userObj.role != null && userObj.role.length > 0 ? userObj.role : []}
															onChange={handleChange}
															MenuProps={MenuProps}
														>
															{props.roles[0].role.map((item, ind) => (
																<MenuItem key={ind}
																	value={item}	>
																	{item}
																</MenuItem>
															))}
														</Select>
													</FormControl>

												</Col>
											</FormGroup>) : ("")}
										<FormGroup row>
											<Label sm={3}>Ellipse Sprint</Label>
											<Col sm={9}>
												<br></br>
												<Label for="subscribeBootCamp">Subscribe to Ellipse Sprint</Label>
												<Input
													type="select"
													name="subscribeBootCamp"
													id="subscribeBootCamp"
													onChange={handleChange}
													value={userObj.subscribeBootCamp}

												>	<option value="" disabled selected>
														Select a value
													</option>
													<option>Monthly</option>
													<option>Yearly</option>
													<option>Company</option>
													<option>No</option>
												</Input>
												<br></br>
												{userObj.subscribeBootCamp !== "Company" ? (
													<div>
														<Label for="startDate">Select start Date</Label>
														<Input
															type="date"
															min={new Date()}
															name="bootCampStartDate"
															id="bootCampStartDate"
															onChange={handleChange}
															value={userObj.bootCampStartDate}

														/>
														<br></br>
														<Label for="endDate">Select End Date</Label>
														<Input
															type="date"
															min={new Date()}
															name="bootCampEndDate"
															id="bootCampEndDate"
															onChange={handleChange}
															value={userObj.bootCampEndDate}

														/>
														<br></br></div>) : ("")}
												{userObj.subscribeBootCamp === "Company" ? (
													<div>
														<Label for="pauseSprintFrom">Pause Sprint from</Label>
														<Input
															type="date"
															min={new Date()}
															name="pauseSprintFrom"
															id="pauseSprintFrom"
															onChange={handleChange}
															value={userObj.pauseSprintFrom}

														/>
														<br></br>
														<Label for="pauseSprintUntil">Pause Sprint until</Label>
														<Input
															type="date"
															min={new Date()}
															name="pauseSprintUntil"
															id="pauseSprintUntil"
															onChange={handleChange}
															value={userObj.pauseSprintUntil}

														/>
														<br></br></div>) : ("")}

												{userObj.subscribeBootCamp === "Monthly" ? (
													<div>
														<Label for="lastPayment">Last date of payment for the Sprint</Label>
														<Input
															type="date"
															min={new Date()}
															name="lastPaymentdDate"
															id="lastPaymentdDate"
															onChange={handleChange}
															value={userObj.lastPaymentSprint}

														/>
													</div>) : ("")}

											</Col>

										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Confidence objective</Label>
											<Col sm={9}>
												<br></br>
												<Label for="confidenceObjective1">Objective 1</Label>
												<Input
													type="textarea"
													name="confidenceObjective1"
													id="confidenceObjective1"
													onChange={handleChange}
													value={userObj.confidenceObjective1 == null ? "" : userObj.confidenceObjective1}
													rows={2}>
												</Input>
												<br></br>
												<Label for="confidenceObjective2">Objective 2</Label>
												<Input
													type="textarea"
													name="confidenceObjective2"
													id="confidenceObjective2"
													onChange={handleChange}
													value={userObj.confidenceObjective2 == null ? "" : userObj.confidenceObjective2}
													rows={2}>
												</Input>
												<br></br>
												<Label for="confidenceObjective3">Objective 3</Label>
												<Input
													type="textarea"
													name="confidenceObjective3"
													id="confidenceObjective3"
													onChange={handleChange}
													value={userObj.confidenceObjective3 == null ? "" : userObj.confidenceObjective3}
													rows={2}>
												</Input>

											</Col>

										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Confidence Action Plan</Label>
											<Col sm={9}>
												<br />
												<Label for="confidenceActionsPlan">Add a plan to the list</Label>
												<Grid container alignItems="center">
													<Grid item>
														<Input
															type="textarea"
															name="confidenceActionsPlan"
															id="confidenceActionsPlan"
															onChange={(e) => setNouvelleValeurActionPlan({ ...nouvelleValeurActionPlan, description: e.target.value, id: uuidv4() })}
															value={nouvelleValeurActionPlan.description}
															rows={2}
															style={{ width: '340px' }}
														/>
													</Grid>
													<Grid item>
														<Button color="primary" onClick={ajouterActionPlan}>
															<AddIcon />
														</Button>
													</Grid>
												</Grid>
												<br />
												<ul>
													{userObj.actionPlans && userObj.actionPlans.map((mapObject, index) => {
														const isChecked = (userObj.actionPlans && userObj.actionPlans.filter(actionPlanUser => actionPlanUser.id === mapObject.id).length > 0) || false;
														return (

															<li
																key={index}
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																	marginBottom: '5px',
																	padding: '8px',
																	border: '1px solid #ccc',
																	borderRadius: '4px',
																	backgroundColor: '#f8f8f8',
																	position: 'relative',
																}}
															>
																<span>{mapObject.description}</span>
																<IconButton
																	style={{
																		position: 'absolute',
																		top: '50%', // Ajuster le positionnement vertical
																		transform: 'translateY(-50%)', // Centrer verticalement l'icône
																		right: '-40px', // Ajuster cette valeur pour déplacer le bouton vers la droite
																		padding: '8px',

																	}}
																	onClick={(e) => {
																		e.preventDefault(); // Empêcher le comportement par défaut du bouton
																		handleDeleteActionPlan(index, e);
																	}}
																>
																	<DeleteIcon />
																</IconButton>
																{userObj.uid && (<input
																	type="checkbox"
																	checked={isChecked}
																	onChange={(e) => handleCheckboxChange(index, e.target.checked)}
																/>)}

															</li>
														)
													})}
												</ul>
											</Col>
										</FormGroup>
										<FormGroup>

										</FormGroup>
										<Row>
											<Col sm={{ size: 9, offset: 3 }}>
												<Button color="primary" block>
													Update/Create
												</Button>
											</Col>
										</Row>
									</form>
								</div>
								<div className="col-lg-6 col-md-12">
									<Button
										className="d-none d-lg-block"
										onClick={() => {
											localStorage.setItem("is_new", JSON.stringify(false));
											let obj = props.users.find(
												item => item.email === userObj.email
											);
											if (obj != null) {
												setUserObj(obj);
												setUpdate(true);
											} else {
												setUpdate(false);

												alert(
													"User with this email doesnt exists create a new user"
												);
												setUserObj({
													...userObj,
													email: "",
													firstname: "",
													lastname: "",
													numberHourBuy: 0,
													numberHoursRemaining: 0,
													Professors: [],
													contracts: [],
													mastermind: "",
													company: "",
													bootCamp: "",
													bootCampEndDate: "",
													subscribeBootCamp: "",
													bootCampStartDate: "",
													lastPaymentSprint: null,
												});
											}
										}}
										block
										color="primary"
									>
										Search
									</Button>
								</div>
							</div>
						</TabPane>
					</TabContent>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="2">
							<form onSubmit={e => e.preventDefault()}>
								<div className="row mt-3">
									<div className="col-lg-6 col-md-12">
										<FormGroup row>
											<Label sm={3}>Email</Label>
											<Col sm={9}>
												<Input
													value={searchObj.email}
													onChange={handleSearchChange}
													name="email"
												/>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>First name</Label>
											<Col sm={9}>
												<Input
													value={searchObj.firstname}
													type="text"
													onChange={handleSearchChange}
													name="firstname"
												/>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Last Name</Label>
											<Col sm={9}>
												<Input
													value={searchObj.lastname}
													type="text"
													onChange={handleSearchChange}
													name="lastname"
												/>
											</Col>
										</FormGroup>

									</div>
									<div className="col-lg-6 col-md-12 align-self-end">
										<Button
											type="button"
											block
											color="primary"
											onClick={e => {
												if (!isSearch) setSearch(true);
												else {
													setSearch(false);
													setSearchObj({
														email: "",
														firstname: "",
														lastname: "",
														company: "",
														mastermind: "",
													});
												}
											}}
										>
											{isSearch ? "Reset" : "Search"}
										</Button>
									</div>
								</div>
							</form>
							{isSearch ? (
								getSearchResults().length > 0 ? (
									getSearchResults().map(result => (
										<div className="row border mt-3 py-4">
											<div className="col-md-12 text-center">
												{profile.role != null && (profile.role.indexOf("admin") > -1
													|| profile.role.indexOf("studentAdmin") > -1) ? (
													<Nav className="justify-content-center" >
														<NavItem>
															<NavLink
																onClick={() => {
																	setSearch(true)
																	setUpdate(true)
																	setUserObj(result);
																	toggle("1");
																}}
															>
																<h3 className="font-weight-bold">{`${result.firstname} ${result.lastname != null ? result.lastname : ""
																	}`}</h3>
															</NavLink>
														</NavItem>
													</Nav>
												) : (

													<h3 className="font-weight-bold">{`${result.firstname} ${result.lastname != null ? result.lastname : ""
														}`}</h3>)}
												<p className="m-0">
													{result.email}
												</p>
												<p className="m-0">
													{result.numberHoursRemaining}h / {result.numberHourBuy}h
												</p>
												<p className="m-0">
													<b>
														{result.company != null
															? getCompanyName(result.company)
															: "N/A"}
													</b>{" "}
													/{" "}
													<b>
														{result.mastermind != null
															? getMastermindName(result.mastermind)
															: "N/A"}
													</b>
												</p>
												<p className="m-0">
													{result.contracts != null
														? getContractDateString(result.contracts)
														: ""}
												</p>
												<p className="m-0">
													{profile.role != null && profile.role.indexOf("admin") > -1 ? result.id : ""}
												</p>
											</div>
										</div>
									))
								) : (
									<h3 className="mt-4 text-center">No Results Found</h3>
								)
							) : (
								""
							)}

						</TabPane>
					</TabContent>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="3">
							<form onSubmit={e => e.preventDefault()}>
								<div className="row mt-3">
									<div className="col-lg-6 col-md-12">
										<FormGroup row>
											<Label sm={3}>Email</Label>
											<Col sm={9}>
												<Input
													value={searchObj.email}
													onChange={handleSearchChange}
													name="email"
												/>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>First name</Label>
											<Col sm={9}>
												<Input
													value={searchObj.firstname}
													type="text"
													onChange={handleSearchChange}
													name="Professor first name"
												/>
											</Col>
										</FormGroup>
										<FormGroup row>
											<Label sm={3}>Last Name</Label>
											<Col sm={9}>
												<Input
													value={searchObj.lastname}
													type="text"
													onChange={handleSearchChange}
													name="lastname"
												/>
											</Col>
										</FormGroup>

									</div>
									<div className="col-lg-6 col-md-12 align-self-end">
										<Button
											type="button"
											block
											color="primary"
											onClick={e => {
												if (!isSearch) setSearch(true);
												else {
													setSearch(false);
													setSearchObj({
														email: "",
														firstname: "",
														lastname: "",
														company: "",
														mastermind: "",
													});
												}
											}}
										>
											{isSearch ? "Reset" : "Search"}
										</Button>
									</div>
								</div>
							</form>
							{isSearch ? (
								getSearchResults().length > 0 ? (
									getSearchResults().map(result => (
										<div className="row border mt-3 py-4">
											<div className="col-md-6 text-center border-right">
												{profile.role != null && profile.role.indexOf("admin") > -1 ? (
													<Nav className="justify-content-center" >
														<NavItem>
															<NavLink
																onClick={() => {
																	setSearch(true)
																	setUpdate(true)
																	setUserObj(result);
																	toggle("1");
																}}
															>
																<h3 className="font-weight-bold">{`${result.firstname} ${result.lastname != null ? result.lastname : ""
																	}`}</h3>
															</NavLink>
														</NavItem>
													</Nav>
												) : (

													<h3 className="font-weight-bold">{`${result.firstname} ${result.lastname != null ? result.lastname : ""
														}`}</h3>)}
												<p className="m-0">
													{result.email}
												</p>
												<p className="m-0">
													{result.numberHoursRemaining}h / {result.numberHourBuy}h
												</p>
												<p className="m-0">
													<b>
														{result.company != null
															? getCompanyName(result.company)
															: "N/A"}
													</b>{" "}
													/{" "}
													<b>
														{result.mastermind != null
															? getMastermindName(result.mastermind)
															: "N/A"}
													</b>
												</p>
												<p className="m-0">
													{result.contracts != null
														? getContractDateString(result.contracts)
														: ""}
												</p>
												<p className="m-0">
													{profile.role != null && profile.role.indexOf("admin") > -1 ? result.id : ""}
												</p>
											</div>
											<div className="col-md-6 text-center d-flex align-items-center justify-content-center">
												{result.linkLearningLog ? (
													<a
														href={result.linkLearningLog}
														target="_blank"
														rel="noopener noreferrer"
														className="text-decoration-none"
													>
														<h3 className="font-weight-bold">Learning Log</h3>
													</a>
												) : (
													<h3 className="font-weight-bold">No Learning Log</h3>
												)}
											</div>
										</div>
									))
								) : (
									<h3 className="mt-4 text-center">No Results Found</h3>
								)
							) : (
								""
							)}
						</TabPane>
					</TabContent>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="4">
							<HourByProfessor
								professors={props.professors}
							/>
						</TabPane>
					</TabContent>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="5">
							<Sprints
								profile={profile}
								sprintsDB={props.sprints}
							/>
						</TabPane>
					</TabContent>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="6">
							<Companies
								profile={profile}
								companiesDB={props.companies}
							/>
						</TabPane>
					</TabContent>
				</div>
			</>
		);
	};
};

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		professors: state.professors.professors,
		company: state.company.company,
		users: state.users.users,
		contracts: state.contracts.contracts,
		mastermind: state.mastermind.mastermind,
		bootCamp: state.firestore.ordered.bootCamp,
		sprints: state.firestore.ordered.sprints,
		roles: state.firestore.ordered.roles,
		actionPlansDB: state.firestore.ordered.confidencePlanActions,
	};
};

Administration.propTypes = {
	fetchCompany: PropTypes.func.isRequired,
};
export default compose(
	connect(mapStateToProps, {
		fetchCompany,
		fetchProfessor,
		fetchUsers,
		fetchContracts,
		searchUsers,
		createUser,
		updateUser,
		fetchMastermind,
		fetchBootCamp,
		fetchRoles,
	}),
	firestoreConnect([{ collection: "confidencePlanActions" }])
)(Administration);
