
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import {
    Col,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
    fetchCompany,
    fetchProfessor,
    fetchUsers,
    fetchMastermind,
    fetchContracts,
    searchUsers,
    fetchBootCamp,
} from "../../store/actions/miscActions";
import DataTable from 'react-data-table-component';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

let llFiltred = "";
let nbHour = 0;

class HoursCoach extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            error: false,
            selectStudent: ''
        };
        this.onInputchange = this.onInputchange.bind(this);
        let nbHour = 0;

    }




    onInputchange(event) {
        if (event.target.name === "ChoiceSelected") {
            this.setState({ coachStartDate: null })
            this.setState({ coachEndDate: null })
        }
        this.setState({
            [event.target.name]: event.target.value
        })

        nbHour = 0

        this.forceUpdate();

    }





    render() {
        const conditionalRowStyles = [
            {
              when: row => getIdMeeting(row.professorId, row.userId, row.date) === "N/A",
              style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            },
          ];

        const { auth, profile, users } = this.props;
        const columns = [
            {
                name: 'Date',
                selector: row => row.date,
                sortable: true,
                width: "200px",
            },
            {
                name: 'Student',
                selector: row => getStudentName(row.userId),
                width: "300px",
            },
            {
                name: 'Duration',
                selector: row => row.duration,
                width: "100px",
            },
            {
                name: 'Coach',
                selector: row => getProfessorName(row.professorId),
                width: "100px",
            },
            {
                name: 'Calendly Meeting',
                id:"calendly",
                selector: row => getIdMeeting(row.professorId, row.userId, row.date),
                width: "350px",
            },
            

        ];

        const getProfessorName = (id) => {

            let professor = this.props.professors.find(prof => prof.id === id)
            if (professor != null) {

                return professor.name
            }
            return "N/A"

        };

        const getIdMeeting = (idProfessor, idUser, dateMeeting) => {



            let meeting1 = this.props.meetings.find(meeting => meeting.professorId === idProfessor)

            let meeting = this.props.meetings.find(meeting => meeting.professorId === idProfessor
                && meeting.idUser === idUser
                && meeting.start_time.split("T")[0] === dateMeeting)
            if (meeting != null) {

                var hours = moment
                    .duration(moment(meeting.end_time, 'YYYY/MM/DD HH:mm')
                        .diff(moment(meeting.start_time, 'YYYY/MM/DD HH:mm'))
                    ).asHours();

                return meeting.id + " -- (" + hours + " hours)"
            }
            return "N/A"

        };

        const getStudentName = (id) => {

            let student = this.props.users.find(student => student.id === id)
            if (student != null) {

                return student.firstname + " " + student.lastname
            }
            return "N/A"

        };

        getHours(users, this.props, this.state);


        if (!auth.uid) {
            return <Redirect to="/signin" />;
        }

        if (profile.isEmpty || users.isEmpty) {
            return <Redirect to="/" />;
        }
        return (
            <div className="dashboard container mx-auto">

                <h4 className="my-4">Get hours by coach</h4>

                <form >
                    <FormGroup row>
                        <Label sm={3}>Make your choice</Label>
                        <Col sm={9}>
                            <Input
                                required
                                type="select"
                                name="ChoiceSelected"
                                id="ChoiceSelected"
                                onChange={this.onInputchange}
                                value={this.state.choice}
                                defaultValue="">
                                <option value="" disabled selected>
                                    Select a option
                                                    </option>
                                <option value="1" selected>Coach</option>
                                <option value="2" selected>Company</option>;

                            </Input>
                        </Col>
                    </FormGroup>
                    {(this.state.ChoiceSelected != null && this.state.ChoiceSelected === "2") && (
                        <FormGroup row>
                            <Label sm={3}>Company</Label>
                            <Col sm={9}>
                                <Input
                                    required
                                    type="select"
                                    name="CompanySelected"
                                    id="CompanySelected"
                                    onChange={this.onInputchange}
                                    value={this.state.Professor}
                                    defaultValue="">
                                    <option value="" disabled selected>
                                        Select a company
                                                    </option>
                                    {this.props.company.map(item => {

                                        return <option value={item.id} selected>{item.name}</option>;

                                    })}

                                </Input>
                            </Col>
                        </FormGroup>
                    )}
                    {(this.state.ChoiceSelected != null && this.state.ChoiceSelected === "1") && (
                        <FormGroup row>
                            <Label sm={3}>Coach</Label>
                            <Col sm={9}>
                                <Input
                                    required
                                    type="select"
                                    name="CoachSelected"
                                    id="CoachSelected"
                                    onChange={this.onInputchange}
                                    value={this.state.Professor}
                                    defaultValue="">
                                    <option value="" disabled selected>
                                        Select a coach
                                                    </option>
                                    {this.props.professors.map(item => {

                                        return <option value={item.id} selected>{item.name}</option>;

                                    })}

                                </Input>
                            </Col>
                        </FormGroup>
                    )}
                    {(this.state.ChoiceSelected != null) && (
                        <FormGroup row>
                            <Label sm={3}>Start date</Label>
                            <Col sm={9}>
                                <Input
                                    required
                                    type="date"
                                    name="coachStartDate"
                                    id="coachStartDate"
                                    onChange={this.onInputchange}
                                    value={this.state.AttendanceStartDate}
                                    defaultValue=""

                                />
                            </Col>
                        </FormGroup>
                    )}
                    {(this.state.ChoiceSelected != null) && (
                        <FormGroup row>
                            <Label sm={3}>End date</Label>
                            <Col sm={9}>
                                <Input
                                    required
                                    type="date"
                                    min={new Date()}
                                    name="coachEndDate"
                                    id="coachEndDate"
                                    onChange={this.onInputchange}
                                    value={this.state.AttendanceEndDate}
                                    defaultValue=""

                                />
                            </Col>
                        </FormGroup>
                    )}
                </form >
                {(this.state.coachStartDate != null && this.state.coachEndDate != null) && (
                    <div>
                        <h4 className="my-4">The total of hours : {nbHour}</h4>

                        <DataTable
                            columns={columns}
                            data={llFiltred}
                            defaultSortFieldId={1}
                            pagination
                            conditionalRowStyles={conditionalRowStyles}
                        />

                    </div>
                )}
            </div >

        );
    }
}

const mapState = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        users: state.firestore.ordered.users,
        learningLogs: state.firestore.ordered.learningLogs,
        company: state.firestore.ordered.company,
        meetings: state.firestore.ordered.calendlyMeeting,
    };
};



function getHours(users, props, state) {

    if (state.ChoiceSelected === "1") {
        nbHour = 0
        if (users != null && state.CoachSelected != null && state.coachStartDate != null && state.coachEndDate != null) {
            let startDate = new Date(moment(state.coachStartDate))
            let endDate = new Date(moment(state.coachEndDate))
            nbHour = 0;

            llFiltred = props.learningLogs.filter(ll => ll.professorId === state.CoachSelected && new Date(moment(ll.date)) >= startDate && new Date(moment(ll.date)) <= endDate);

            llFiltred.forEach(elt => nbHour += elt.duration == '' ? 0 : elt.duration)

        }
    } else if (state.ChoiceSelected === "2") {
        if (users != null && state.CompanySelected != null && state.coachStartDate != null && state.coachEndDate != null) {

            let startDate = new Date(moment(state.coachStartDate))
            let endDate = new Date(moment(state.coachEndDate))
            nbHour = 0;

            let userFiltred = users.filter(user => user.company === state.CompanySelected)


            llFiltred = props.learningLogs.filter(ll => userFiltred.find(it => it.id === ll.userId) && new Date(moment(ll.date)) >= startDate && new Date(moment(ll.date)) <= endDate)


            llFiltred.forEach(elt => nbHour += elt.duration == '' ? 0 : elt.duration)

        }
    }
}



export default compose(
    firestoreConnect([{ collection: "users" }, { collection: "learningLogs" }, { collection: "company" }, { collection: "calendlyMeeting" }]),
    connect(mapState)
)(HoursCoach);
