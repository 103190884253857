
import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import moment from "moment";
import {
    FormGroup,
    Label,
    Button,
} from "reactstrap";
import input from "./input";
import firebase from "firebase/app";
import DataTable from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TimePicker from 'react-time-picker';
import DateTimePicker from 'react-datetime-picker';


import DeleteConfirmation from "./deleteConfirmation";





function SprintsForm({
    sprintsDB,
}) {

    const MyInput = ({ field, form, ...props }) => {
        return <input {...field} {...props} />;
    };

    const [idRowClicked, setIdRowClicked] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [isModify, setIsModify] = useState(false);
    const [startTime, onChangeStartTime] = useState('07:30');
    const [endTime, onChangeEndTime] = useState('08:00');
    const [idTodelete, setIdTodelete] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [type, setType] = useState(null);
    const [cancelDate, onChangeCancelDate] = useState(null);

    const [sprintsObj, setSprintsObj] = useState({
        name: "",
        title: "",
        startTime: startTime,
        endTime: endTime,
        disabled: "",
        createdTime: "",
        updatedTime: "",
        level: "",
        joinUrl: "",
        startUrl: "",
        cancelUntilDate: "",
    });



    const columnsAmin = [
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-trash`} onClick={() => onRowClickedDelete(row)}>
                    </i>
                </div>
            ),
            width: "50px",

        },

        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-edit`} onClick={() => onRowClicked(row)}>
                    </i>
                </div>
            ),
            width: "50px",
        },
        {
            name: 'Name of the sprint',
            selector: row => row.name,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Title of the sprint',
            selector: row => row.title,
            sortable: true,
            width: "250px",
        },
        {
            name: 'Start time',
            selector: row => moment.utc(row.startTime, ["h:mm"]).local().format("HH:mm A"),
            width: "120px",
        },
        {
            name: 'End time',
            selector: row => moment.utc(row.endTime, ["h:mm"]).local().format("HH:mm A"),
            width: "120px",
        },
        {
            name: 'Level',
            selector: row => row.level,
            width: "250px",
        },
        {
            name: 'Disabled',
            selector: row => row.disabled,
            width: "500px",
        },

    ];

    const onRowClicked = (row, event) => {

        sprintsObj.name = row.name
        onChangeStartTime(moment.utc(row.startTime, ["h:mm"]).local().format("HH:mm A"))
        onChangeEndTime(moment.utc(row.endTime, ["h:mm"]).local().format("HH:mm A"))
        sprintsObj.startTime = moment.utc(row.startTime, ["h:mm"]).local().format("HH:mm A")
        sprintsObj.endTime = moment.utc(row.endTime, ["h:mm"]).local().format("HH:mm A")
        sprintsObj.level = row.level
        sprintsObj.title = row.title
        sprintsObj.disabled = row.disabled
        sprintsObj.calendarLink = row.calendarLink
        sprintsObj.ressourcesLink = row.ressourcesLink
        sprintsObj.cancelUntilDate = row.cancelDate
        sprintsObj.joinUrl = row.joinUrl
        sprintsObj.startUrl = row.startUrl

        setIdRowClicked(row.id)
        setIsAdd(false)
        setIsModify(true)
    };

    const onRowClickedDelete = (row, event) => {
        showDeleteModal(row.name, row.id)
    };

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (name, id) => {

        setDeleteMessage(`Are you sure you want to delete the sprint '${name}'?`);
        setIdTodelete(id)

        setDisplayConfirmationModal(true);

    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitDelete = () => {

        deleteSprint(idTodelete)
        setDisplayConfirmationModal(false);
    };

    const addRow = () => {
        setIsAdd(true)
        sprintsObj.createdTime = new Date()
        sprintsObj.updatedTime = new Date()
        sprintsObj.disabled = ""
        sprintsObj.name = ""
        sprintsObj.startTime = ""
        sprintsObj.endTime = ""
        sprintsObj.level = ""
        sprintsObj.title = ""
        sprintsObj.calendarLink = ""
        sprintsObj.ressourcesLink = ""
        sprintsObj.cancelUntilDate = ""
        sprintsObj.joinUrl = ""
        sprintsObj.startUrl = ""

    };

    const handleSubmitSprint = async evt => {

        sprintsObj.createdTime = new Date()
        sprintsObj.updatedTime = new Date()
        sprintsObj.disabled = evt.disabled
        sprintsObj.name = evt.name
        sprintsObj.startTime = moment.utc(moment(startTime, ["h:mm"])).format("HH:mm A")
        sprintsObj.endTime = moment.utc(moment(endTime, ["h:mm"])).format("HH:mm A")
        sprintsObj.level = evt.level
        sprintsObj.title = evt.title
        sprintsObj.calendarLink = evt.calendarLink
        sprintsObj.ressourcesLink = evt.ressourcesLink
        sprintsObj.cancelUntilDate = cancelDate === null ? null : moment.utc(moment(cancelDate, ["YYYY-MM-DD h:mm"])).format("YYYY-MM-DD HH:mm A")
        sprintsObj.joinUrl = evt.joinUrl
        sprintsObj.startUrl = evt.startUrl

        if (isAdd) {
            await createSprint(sprintsObj)
        }

        if (isModify) {
            await updateSprint(sprintsObj)
        }

        setIsAdd(false)
        setIsModify(false)
        this.forceUpdate();
    };


    const createSprint = (sprintsObj) => {

        const firestore = firebase.firestore();

        firestore
            .collection("sprints")
            .add(sprintsObj)
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    const deleteSprint = (id) => {
        const firestore = firebase.firestore();


        firestore
            .collection("sprints")
            .doc(id)
            .delete()
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    //  Update Sprint
    const updateSprint = (sprintsObj) => {
        const firestore = firebase.firestore();


        firestore
            .collection("sprints")
            .doc(idRowClicked)
            .update(sprintsObj)
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    const schemaSprints = yup.object({
        name: yup.string().required("Name is required"),
        level: yup.string().required("Level is required"),
        disabled: yup.string().required("Disabled is required"),
    });

    const handleChange = e => {


        setSprintsObj({ ...sprintsObj, [e.target.name]: e.target.value });
    };


    return (
        <div className="form">
            {!isAdd && !isModify && (
                <div align="right">
                    <div>
                        <i className={`icon-container fas fa-plus-circle white-space:nowrap`} onClick={() => addRow()}>&nbsp;Add
                        </i>

                    </div>
                </div>)}

            {(!isAdd && !isModify) && (
                <center><h4 className="my-4">Sprints</h4></center>
            )
            }

            {isAdd && (<div align="center">
                <div>
                    <br></br>
                    <h4>Create a new entry</h4>
                </div>
            </div>)}

            {isModify && (<div align="center">
                <div>
                    <h4>Update the entry</h4>
                </div>
            </div>)}

            {(isAdd || isModify) && (
                <Formik
                    validationSchema={schemaSprints}
                    onSubmit={handleSubmitSprint}
                    initialValues={sprintsObj || {}}
                >
                    {({
                        handleSubmitSprint,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isInvalid,
                        errors,
                    }) => (
                        <Form >
                            <FormGroup>
                                <Label>Name of the sprint</Label>
                                <Field name="name" type="text" component={input} placeholder="Name" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Title of the sprint</Label>
                                <Field name="title" type="text" component={input} placeholder="Title" />
                            </FormGroup>
                            <FormGroup>
                                <FormGroup>
                                    <Label>Start time</Label><br></br>
                                    <TimePicker onChange={onChangeStartTime} value={startTime} />
                                </FormGroup>
                            </FormGroup>
                            <FormGroup>
                                <FormGroup>
                                    <Label>End time</Label><br></br>
                                    <TimePicker onChange={onChangeEndTime} value={endTime} />
                                </FormGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label>Days of the week</Label>
                                <div>
                                    {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => (
                                        <div key={day}>
                                            <input
                                                type="checkbox"
                                                id={day}
                                                name="daysOfWeek"
                                                value={day}
                                                checked={selectedDays.includes(day)}
                                                onChange={() => handleDayCheckboxChange(day)}
                                            />
                                            <label htmlFor={day}>{day}</label>
                                        </div>
                                    ))}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Label of the Level of English</Label>
                                <Field name="level" type="text" component={input} placeholder="Level" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Calendar link</Label>
                                <Field name="calendarLink" type="text" component={input} placeholder="Calendar link" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Ressources link</Label>
                                <Field name="ressourcesLink" type="text" component={input} placeholder="Ressources link" />
                            </FormGroup>
                            <FormGroup>
                                <Label> <a href={sprintsObj.joinUrl} target="_blank">Join Url (Student) for Zoom</a></Label>
                            </FormGroup>
                            <FormGroup>
                                <Label> <a href={sprintsObj.startUrl} target="_blank">Professor Url for Zoom</a></Label>
                            </FormGroup>
                            <FormGroup>
                                <Label>Cancel sprint until</Label><br></br>
                                <DateTimePicker onChange={onChangeCancelDate} value={cancelDate} /> &nbsp;
                                <Button type="button" onClick={() => {
                                    onChangeCancelDate(null)
                                }}>
                                    Clear
                                </Button>
                            </FormGroup>
                            <FormGroup>
                                <Label>Disabled</Label>
                                <Field name="disabled" type="select" component={input} onChange={handleChange} >
                                    <option value="" disabled selected>	Select a value	</option>
                                    <option value="false">False</option>
                                    <option value="true">True</option>
                                </Field>
                            </FormGroup>

                            <Button type="submit" style={{ marginRight: "10px" }}>
                                Save
                            </Button>
                            <Button type="button" onClick={() => {
                                setIsAdd(false)
                                setIsModify(false)
                            }}>
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}

            {(!isAdd && !isModify) && (
                <DataTable
                    columns={columnsAmin}
                    //data={learningLogsDB.filter(ll => ll.userId == student.id)}
                    data={sprintsDB}
                    defaultSortFieldId={1}
                    pagination
                    onRowClicked={onRowClicked}
                />)
            }

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} type={type} id={idTodelete} message={deleteMessage} />

        </div>
    );
}

const mapToProps = state => {
    return {
        sprintsDB: state.firestore.ordered.sprints,
        professorDB: state.firestore.ordered.professor,
    };
};
export default connect(
    mapToProps
)(SprintsForm);

