import React, { Component } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
	signUp,
	signUpWithGoogle,
	signUpWithFacebook,
	signUpWithApple,
	signOut,
	clearErrorMessage ,
} from "../../store/actions/authActions";
import {
	Row,
	Col,
	Button,
	Input,
	InputGroup,
	InputGroupText,
	InputGroupAddon,
} from "reactstrap";
import logo from "../../logo.png";
import { Spinner } from "reactstrap";
import { isLoaded } from "react-redux-firebase";

export class SignUp extends Component {
	state = {
		email: "",
		password: "",
		authentificationError: "",
		firstname: "",
	};
	handleChange = e => {
		this.setState({
			[e.target.id]: e.target.value,
		});
	};
	handleSubmit = e => {
		e.preventDefault();
		this.props.signUp(this.state);
	};
	handleClick = () => {
		// Dispatchez votre action ici
		this.props.clearErrorMessage();
		//this.props.dispatch({ type: 'LOGIN_CHANGE_SUCCESS', err: '' });
	};

	render() {
		const { auth, authError, profile } = this.props;
		let authentificationError = authError;

		console.log(this.props)
		//If user is authenticated then redirect to Professor page.
		if (auth.uid) {
			if (isLoaded(profile)) {
				return <Redirect to="/" />;
			}
		} else {
			if (authError === "")
				authentificationError = "";
		}
		if (!isLoaded(auth) || !isLoaded(profile)) {
			return (
				<div className="text-center mt-3">
					<Spinner />
				</div>
			)
		} else {
			return (
				<div className="auth-page">
					<div className="container pt-5">
						<form onSubmit={this.handleSubmit}>
							<Row className="justify-content-md-center">
								<Col sm="10" md="5">
									<div className="text-center">
										<img src={logo} width="60%" className="logo" alt="Ellipse" />
									</div>
									<div className="card-head">
										<div className=" pt-4 pb-3 text-center ">
											Welcome to Student Portal
										</div>
									</div>
									<div className="card-body">
										<h5 className="card-title text-center">Create your account</h5>
										<div className="input-field mt-3">
											<label htmlFor="firstName">FirstName</label>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fas fa-user"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="text"
													id="firstName"
												/>
											</InputGroup>
										</div>
										<div className="input-field mt-3">
											<label htmlFor="name">LastName</label>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fas fa-user"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="text"
													id="name"
												/>
											</InputGroup>
										</div>
										<div className="input-field mt-3">
											<label htmlFor="email">Email</label>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fa fa-envelope"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="email"
													id="email"
													onChange={this.handleChange}
												/>
											</InputGroup>
										</div>
										<div className="input-field mt-3">
											<label htmlFor="password">Password</label>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fa fa-lock"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="password"
													id="password"
													onChange={this.handleChange}
												/>
											</InputGroup>
										</div>
										<div className="input-field mt-3">
											<Button
												type="submit"
												block
												color="primary"
											>
												Create your account
											</Button>
											{/* <button type="submit" className="btn pink lighten-1 z-depth-0">
                                                    Login
                                                </button> */}

											<div className="center red-text-big">{this.props.authError != "" ? this.props.authError : null}</div>
											
										</div>

										<div className="my-3 text-center ">
											<strong>OR</strong>
										</div>

										<Button
											onClick={() => signUpWithGoogle()}
											className="text-capitalize google-login mt-3"
											block
										>
											Create your account with <i className="fa fa-google"></i> Google
										</Button>
										<Button
											onClick={() => signUpWithFacebook()}
											className="text-capitalize facebook-login"
											block
										>
											Create your account with <i className="fa fa-facebook"></i> Facebook
										</Button>

										<p className="text-center mt-2">
											Already have an account ?{" "}
											<NavLink to="/signin" onClick={this.handleClick}>
												<strong className="info-text">Login</strong>
											</NavLink>
										</p>
									</div>
								</Col>
							</Row>
							{/* <h5 className="grey-text text-darken-3">Sign Up</h5>
                        <div className="input-field">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <button className="btn pink lighten-1 z-depth-0">
                                Signup
                            </button>
                            <div className="red-text center">
                                { authError ? authError : null}
                            </div>
                        </div> */}
						</form>
						{/* <button onClick={() => signUpWithGoogle()} className="btn grey lighten-4 z-depth-0 black-text">
                        Google Sign Up
                    </button>
                    <button style={{marginLeft: '10px'}} onClick={() => signUpWithFacebook()} className="btn blue lighten-4 z-depth-0 black-text">
                        Facebook Sign Up
                    </button> */}
						{/* <button onClick={() => signUpWithApple()} className="btn grey lighten-4 z-depth-0 black-text">
                        Apple Sign Up
                    </button> */}
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError,
		profile: state.firebase.profile,
		authentificationError: state.authentificationError,
	};
};

const mapDispatchToProps = (dispatch)  => {
	return {
		signUp: user => dispatch(signUp(user)),
		signUpWithGoogle: () => dispatch(signUpWithGoogle()),
		signUpWithFacebook: () => dispatch(signUpWithFacebook()),
		signUpWithApple: () => dispatch(signUpWithApple()),
		signOut: () => dispatch(signOut()),
		signOut: () => dispatch(signOut()),
		clearErrorMessage: () => dispatch(clearErrorMessage()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
