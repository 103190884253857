
import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {
    FormGroup,
    Label,
    Button,
    Modal, ModalHeader, ModalBody,
} from "reactstrap";
import input from "./input";
import firebase from "firebase/app";
import DataTable from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import styled from 'styled-components';
import { firestoreConnect, isLoaded } from "react-redux-firebase";


import DeleteCompanyConfirmation from "./deleteConfirmation";
import DeleteStudentConfirmation from "./deleteConfirmation";
import DeleteAdminConfirmation from "./deleteConfirmation";

import AddStudent from "./addStudentModal2";
import ShowMessage from "./showMessage";
import emailjs from '@emailjs/browser';

import { Spinner } from "reactstrap";


function SprintsForm({
    profile,
    companiesDB,
    usersDB,
}) {
    //*********************************** Student ************************** */
    const [displayAddStudent, setDisplayAddStudent] = useState(false);
    const [nameStudentToDelete, setNameStudentToDelete] = useState("");
    const [deleteStudentMessage, setDeleteStudentMessage] = useState(`Are you sure you want to delete this student '${nameStudentToDelete}'?`);
    const [idStudentTodelete, setIdStudentTodelete] = useState(null);
    const [displayStudentConfirmationModal, setDisplayStudentConfirmationModal] = useState(false);
    const [idRowClickedStudent, setIdRowClickedStudent] = useState(null);
    const [isAddStudent, setIsAddStudent] = useState(false);
    const [isModifyStudent, setIsModifyStudent] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [usersFiltered, setUsersFiltered] = useState([]);
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Months are 0-indexed (0 for January, 11 for December)
    const currentYear = currentDate.getFullYear();

    const hideStudent = () => {
        setDisplayAddStudent(false);
    };
    // Hide the modal
    const hideStudentConfirmationModal = () => {
        setDisplayStudentConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitStudentDelete = () => {
        let array = JSON.parse(JSON.stringify(companyObj.students));

        let obj = array.filter(
            item => item.email != idStudentTodelete
        );
        companyObj.students = obj

        setDisplayStudentConfirmationModal(false);
    };

    const [studentObj, setStudentObj] = useState({

        firstName: "",
        lastName: "",
        email: "",
    });

    const AddStudentModal = props => {
        const { isOpen } = props;
        // If we only put the modal in the render tree when it's open, multiple modals
        // will open in the expected order
        return isOpen ? <Modal {...props} /> : null;
    };

    const onRowClickedStudent = (row, event) => {

        studentObj.lastName = row.lastName
        studentObj.firstName = row.firstName
        studentObj.email = row.email

        setIdRowClickedStudent(row.id)
        setIsAddStudent(false)
        setIsModifyStudent(true)
        setDisplayAddStudent(true)
    };

    const onRowClickedDeleteStudent = (row, event) => {
        setIdStudentTodelete(row.email)
        setNameStudentToDelete(row.firstName + ' ' + row.lastName)

        setDeleteStudentMessage("Are you sure you want to delete this student " + row.firstName + ' ' + row.lastName + " ?")

        setDisplayStudentConfirmationModal(true);

    };

    function calculateUserMonthlySprintAttendance(user) {
        

        // Check if sprintAttendance exists and is an array; otherwise, set it as an empty array
        const attendanceRecords = user.sprintAttendance || [];
        console.log("user", user);

        if (user.email == "lanm80@gmail.com")
            console.log("attendanceRecords", attendanceRecords[0].toDate());

        // Filter user's sprint attendances to only include those within the current month and year
        const monthlyAttendance = attendanceRecords.filter(date => {
            const attendanceDate = date.toDate();
            return attendanceDate.getMonth() === currentMonth && attendanceDate.getFullYear() === currentYear;
        }).length;

        return monthlyAttendance;
    }



    const columnsStudents = [
        /*{
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-trash`} onClick={() => onRowClickedDeleteStudent(row)}>
                    </i>
                </div>
            ),
            width: "50px",

        },
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-edit`} onClick={() => onRowClickedStudent(row)}>
                    </i>
                </div>
            ),
            width: "50px",
        },*/
        {
            name: 'First Name',
            selector: row => row.firstname,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Last Name',
            selector: row => row.lastname,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "300px",
        },
        ,
        {
            name: 'Available hours',
            center: true,
            selector: row => row.numberHoursRemaining != null ? row.numberHoursRemaining + "/" + row.numberHourBuy : "N/A",
            sortable: true,
            width: "130px", // Adjusted to fit the potentially complex information
        },
        {
            name: 'Sprint Current Month (' + monthNames[currentMonth] + ')',
            center: true,
            selector: row => calculateUserMonthlySprintAttendance(row) + " Sessions",
            sortable: true,
            width: "200px", // Provides space for the text and numbers
        },
        {
            name: 'Sprint Attendance',
            center: true,
            selector: row => row.sprintAttendance != null ? row.sprintAttendance.length + " Sessions" : "0 Sessions",
            sortable: true,
            width: "200px", // Matched width with 'Sprint Current Month' for consistency
        },
    ];



    const addRowStudent = () => {

        if (companyObj.students.length <= numberOfSeat) {
            setIsModifyStudent(false)
            setDisplayAddStudent(true)
        } else {
            setMessageError("You can not add more student, the number of seat you have is " + companyObj.numberSeatsSprint + ". Please call Ellipse to add more seat")
            setDisplayShowMessageModal(true)
        }

    };

    //*********************************** Admin manager ************************** */
    const [displayAddAdmin, setDisplayAddAdmin] = useState(false);
    const [nameAdminToDelete, setNameAdminToDelete] = useState("");
    const [deleteAdminMessage, setDeleteAdminMessage] = useState(`Are you sure you want to delete this administrator manager '${nameAdminToDelete}'?`);
    const [idAdminTodelete, setIdAdminTodelete] = useState(null);
    const [displayAdminConfirmationModal, setDisplayAdminConfirmationModal] = useState(false);
    const [idRowClickedAdmin, setIdRowClickedAdmin] = useState(null);
    const [isAddAdmin, setIsAddAdmin] = useState(false);
    const [isModifyAdmin, setIsModifyAdmin] = useState(false);
    const hideAdmin = () => {
        setDisplayAddAdmin(false);
    };

    // Hide the modal
    const hideAdminConfirmationModal = () => {
        setDisplayAdminConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitAdminDelete = () => {

        deleteCompany(idCompanyTodelete)
        setDisplayAdminConfirmationModal(false);
    };

    const [adminObj, setAdminObj] = useState({

        firstName: "",
        lastName: "",
        email: "",
    });

    const addAdmintModal = props => {
        const { isOpen } = props;
        // If we only put the modal in the render tree when it's open, multiple modals
        // will open in the expected order
        return isOpen ? <Modal {...props} /> : null;
    };

    const onRowClickedAdmin = (row, event) => {

        adminObj.lastName = row.lastName
        adminObj.firstName = row.firstName
        adminObj.email = row.email

        idRowClickedAdmin(row.id)
        setIsAddAdmin(false)
        setIsModifyAdmin(true)
    };

    const onRowClickedDeleteAdmin = (row, event) => {
        showDeleteModal(row.name, row.id)
    };

    const columnsAminManager = [

        {
            name: 'FirstName',
            selector: row => row.firstname,
            sortable: true,
            width: "200px",
        },
        {
            name: 'lastName',
            selector: row => row.lastname,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "300px",
        },
    ];

    //*********************************** Show message modal ************************** */
    const [displayShowMessageModal, setDisplayShowMessageModal] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const hideShowMessageModal = () => {
        setDisplayShowMessageModal(false);
    };


    //*********************************** Company ************************** */
    const [idRowClicked, setIdRowClicked] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [isModify, setIsModify] = useState(false);
    const [idCompanyTodelete, setIdCompanyTodelete] = useState(null);
    const [displayCompanyConfirmationModal, setDisplayCompanyConfirmationModal] = useState(false);
    const [deleteCompanyMessage, setDeleteCompanyMessage] = useState(null);
    const [numberOfSeat, setNumberOfSeat] = useState(0);
    const [idCompany, setIdCompany] = useState(0);

    const [companyObj, setcompanyObj] = useState({

        name: "",
        createdTime: new Date(),
        updatedTime: new Date(),
        numberSeatsSprint: 0,
        students: [],
        totalPrivateHours: 0,
        owner: "",
        debitDay: "",
    });

    const [userObj, setUserObj] = useState({

        firstname: "",
        lastname: "",
        role: ["student"],
        numberHourBuy: 0,
        numberHoursRemaining: 0,
        email: "",
        uid: "",
        Professors: [],
        company: "",
        mastermind: "",
        linkLearningLog: "",
        AdressCP: "",
        AdressCity: "",
        AdressCountry: "",
        AdressLine1: "",
        AddressLine2: "",
        AddressState: "",
        Level: "",
        bootCampEndDate: "",
        bootCampRegistered: "",
        bootCampStartDate: "",
        lastProductSubcribe: "",
        updateTime: "",
        subscribeBootCamp: "",

    });

    const handleChangeCompany = e => {

        if (e.target.name == 'numberSeatsSprint')
            setNumberOfSeat(e.target.value)

    };



    const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;
    const ClearButton = styled(Button)`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
height: 34px;
width: 32px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
`;

    const FilterComponent = (props) => (
        <>
            <TextField
                id="search"
                type="text"
                placeholder="Filter By Name"
                aria-label="Search Input"
                value={props.filterText}
                onChange={props.onFilter}
            />
            <ClearButton type="button" onClick={props.onClear}>
                X
            </ClearButton>
        </>
    );

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = companiesDB ? companiesDB.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    ) : "";

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent autoFocus onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);


    const columnsAmin = [
        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-trash`} onClick={() => onRowClickedDelete(row)}>
                    </i>
                </div>
            ),
            width: "50px",

        },

        {
            cell: (row) => (
                <div>
                    <i className={`icon-container fas fa-edit`} onClick={() => onRowClicked(row)}>
                    </i>
                </div>
            ),
            width: "50px",
        },
        {
            name: 'Name of the company',
            selector: row => row.name,
            sortable: true,
            width: "300px",
        },
        {
            name: 'Adress',
            selector: row => row.adress,
            width: "250px",
        },
        {
            name: 'Number of seat',
            selector: row => row.numberSeatsSprint,
            width: "120px",
        },
        {
            name: 'Total private hours',
            selector: row => row.totalPrivateHours,
            width: "120px",
        },
        {
            name: 'Owner',
            selector: row => row.owner,
            width: "120px",
        },
        {
            name: 'Invoice Day/Debit Day',
            selector: row => row.debitDay,
            width: "250px",
        },

    ];

    const onRowClicked = (row, event) => {
        companyObj.name = row.name
        companyObj.createdTime = new Date()
        companyObj.updatedTime = new Date()
        companyObj.name = row.name
        companyObj.numberSeatsSprint = row.numberSeatsSprint == null ? 0 : row.numberSeatsSprint
        companyObj.students = row.students == null ? [] : row.students
        companyObj.totalPrivateHours = row.totalPrivateHours == null ? 0 : row.totalPrivateHours
        companyObj.owner = row.owner == null ? "" : row.owner
        companyObj.debitDay = row.debitDay == null ? "" : row.debitDay

        setNumberOfSeat(companyObj.numberSeatsSprint)
        setIdCompany(row.id)

        setIdRowClicked(row.id)
        setIsAdd(false)
        setIsModify(true)
    };

    const onRowClickedDelete = (row, event) => {
        showDeleteModal(row.name, row.id)


    };

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (name, id) => {

        setDeleteCompanyMessage(`Are you sure you want to delete the company '${name}'?`);
        setIdCompanyTodelete(id)

        setDisplayCompanyConfirmationModal(true);

    };

    // Hide the modal
    const hideCompanyConfirmationModal = () => {
        setDisplayCompanyConfirmationModal(false);
    };

    // Handle the actual deletion of the item
    const submitCompanyDelete = () => {

        deleteCompany(idCompanyTodelete)
        setDisplayCompanyConfirmationModal(false);
    };

    const addRow = () => {

        setIsAdd(true)
        companyObj.createdTime = new Date()
        companyObj.updatedTime = new Date()
        companyObj.name = ""
        companyObj.numberSeatsSprint = ""
        companyObj.students = []
        companyObj.totalPrivateHours = 0
        companyObj.owner = ""
        companyObj.debitDay = ""

    };



    const addRowAdmin = () => {

        setDisplayAddAdmin(true)


    };

    const handleSubmitCompany = async evt => {

        companyObj.createdTime = new Date()
        companyObj.updatedTime = new Date()
        companyObj.name = evt.name
        companyObj.numberSeatsSprint = evt.numberSeatsSprint
        companyObj.students = evt.students
        companyObj.totalPrivateHours = evt.totalPrivateHours
        companyObj.owner = evt.owner
        companyObj.debitDay = evt.debitDay


        if (isAdd) {
            await createCompany(companyObj)
        }

        if (isModify) {
            await updateCompany(companyObj)
        }

        //Check if the student exist
        companyObj.students.map(student => {
            let obj = usersDB.find(
                item => item.email === student.email
            );
            if (obj == null) {
                //Create the user if not exist
                createUser(companyObj, student)
            }
        })

        setIsAdd(false)
        setIsModify(false)
        this.forceUpdate();
    };

    const createUser = (companyObj, student) => {
        const firestore = firebase.firestore();

        userObj.firstname = student.firstName
        userObj.lastname = student.lastName
        userObj.email = student.email
        userObj.company = idCompany
        userObj.subscribeBootCamp = "Company"


        firestore
            .collection("users")
            .add(userObj)
            .then(() => {

            })
            .catch(error => console.log(error.message))


        var templateParams = {
            name: userObj.firstname,
            to_email: 'lanm80@gmail.com',
            to_name: userObj.firstname
        };

        emailjs.send('service_clw405h', 'template_welcome_sprint', templateParams, 'QpS-ULBjL-j3MYSLa')
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });
    };

    const createCompany = (companyObj) => {
        const firestore = firebase.firestore();

        firestore
            .collection("company")
            .add(companyObj)
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    const deleteCompany = (id) => {
        const firestore = firebase.firestore();


        firestore
            .collection("company")
            .doc(id)
            .delete()
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    //  Update Sprint
    const updateCompany = (companyObj) => {
        const firestore = firebase.firestore();

        firestore
            .collection("company")
            .doc(idRowClicked)
            .update(companyObj)
            .then(() => {

            })
            .catch(error => console.log(error.message))
    };

    const schemaCompany = yup.object({
        name: yup.string().required("Name is required"),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!profile) {
                    setLoading(false);
                    return; // If no company ID provided, stop fetching data
                }

                const querySnapshot = await firebase
                    .firestore()
                    .collection('users')
                    .where('company', '==', idCompany)
                    .get();

                const userData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setUsersFiltered(userData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [idCompany]); // This effect depends on `companyId`



    /************************************************************************************************************************************************* */




    console.log("filteredItems", filteredItems)
    if (!isLoaded(profile) || filteredItems === null || !filteredItems || usersDB === null || !usersDB) {
        return (
            <div className="text-center mt-3">
                <Spinner />
            </div>
        )
    } else {

        return (
            <div className="form">
                {!isAdd && !isModify && (
                    <div align="right">
                        <div>
                            <i className={`icon-container fas fa-plus-circle white-space:nowrap`} onClick={() => addRow()}>&nbsp;Add
                            </i>

                        </div>
                    </div>)}

                {(!isAdd && !isModify) && (
                    <center><h4 className="my-4">Companies</h4></center>
                )
                }

                {isAdd && (<div align="center">
                    <div>
                        <br></br>
                        <h4>Create a new entry</h4>
                    </div>
                </div>)}

                {isModify && (<div align="center">
                    <div>
                        <h4>Update the entry</h4>
                    </div>
                </div>)}

                {(isAdd || isModify) && (
                    <Formik
                        validationSchema={schemaCompany}
                        onSubmit={handleSubmitCompany}
                        initialValues={companyObj || {}}
                    >
                        {({
                            handleSubmitSprint,
                            handleBlur,
                            values,
                            touched,
                            isInvalid,
                            errors,
                        }) => (
                            <Form onChange={handleChangeCompany}>
                                <FormGroup>
                                    <Label><b>Name of the company</b></Label>
                                    <Field name="name" type="text" component={input} placeholder="Name" />
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Adress</b></Label>
                                    <Field name="adress" type="text" component={input} placeholder="Adress" />
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Number of seat (Sprint)</b></Label>
                                    <Field name="numberSeatsSprint" type="text" component={input} placeholder="Number Seat" />
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Invoice Day/Debit Day</b></Label>
                                    <Field name="debitDay" type="select" component={input} placeholder="Debit Day">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">12</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                    </Field>
                                </FormGroup>
                                {/*
                                <FormGroup>
                                    <Label><b>Student for the Sprint</b></Label><i className={`icon-container fas fa-plus-circle white-space:nowrap`} onClick={() => addRowStudent()} />
                                    <DataTable
                                        columns={columnsStudents}
                                        data={companyObj.students}
                                        onRowClicked={onRowClickedStudent}
                                    />
                                </FormGroup>
                                */}
                                <FormGroup>
                                    <Label><b>All Student in the company</b></Label>
                                    <DataTable
                                        columns={columnsStudents}
                                        data={usersFiltered}
                                    //onRowClicked={onRowClickedStudent}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Account Manager</b></Label>
                                    <DataTable
                                        columns={columnsAminManager}
                                        data={usersDB ? usersDB.filter(user => user.company === idCompany && user.role != null && user.role != "" ? user.role.some(role => role === "account manager") : false) : []}
                                        onRowClicked={onRowClickedAdmin}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Total private hours</b></Label>
                                    <Field name="totalPrivateHours" type="text" component={input} placeholder="Private Hours" />
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Owner</b></Label>
                                    <Field name="owner" type="text" component={input} placeholder="Owner" />
                                </FormGroup>

                                <Button type="submit" style={{ marginRight: "10px" }}>
                                    Save
                                </Button>
                                <Button type="button" onClick={() => {
                                    setIsAdd(false)
                                    setIsModify(false)
                                }}>
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}

                {(!isAdd && !isModify) && (
                    <DataTable
                        columns={columnsAmin}
                        //data={learningLogsDB.filter(ll => ll.userId == student.id)}
                        data={filteredItems}
                        defaultSortFieldId={3}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                        onRowClicked={onRowClicked}
                    />)
                }

                <DeleteCompanyConfirmation showModal={displayCompanyConfirmationModal} confirmModal={submitCompanyDelete} hideModal={hideCompanyConfirmationModal} type="" id={idCompanyTodelete} message={deleteCompanyMessage} />
                <DeleteStudentConfirmation showModal={displayStudentConfirmationModal} confirmModal={submitStudentDelete} hideModal={hideStudentConfirmationModal} type="" id={idStudentTodelete} message={deleteStudentMessage} />
                <DeleteAdminConfirmation showModal={displayAdminConfirmationModal} confirmModal={submitAdminDelete} hideModal={hideAdminConfirmationModal} type="" id={idAdminTodelete} message={deleteAdminMessage} />

                <ShowMessage showModal={displayShowMessageModal} hideModal={hideShowMessageModal} message={messageError} title="Erreur" />

                <AddStudentModal isOpen={displayAddStudent} size="lg" toggle={() => setDisplayAddStudent(false)}>

                    <ModalHeader>
                        <h4>Add a student to the Sprint</h4>
                    </ModalHeader>
                    <ModalBody>
                        <AddStudent
                            company={companyObj}
                            onCancel={hideStudent.bind(this)}
                            student={studentObj}
                            idCompany={idRowClicked}
                            displayAddStudent={displayAddStudent}
                            isModify={isModifyStudent}
                        />
                    </ModalBody>
                </AddStudentModal>

            </div>
        );
    }
}

const mapToProps = state => {
    return {
        companiesDB: state.firestore.ordered.company,
        professorDB: state.firestore.ordered.professor,
        usersDB: state.firestore.ordered.users,
    };
};
export default connect(
    mapToProps
)(SprintsForm);

