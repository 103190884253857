import React, { Component } from "react";
import CompaniesAdministration from "./companyAdministrationFunction";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class CompaniesAdministrationComponent extends Component {
	render() {
		const { auth, profile, meetings } = this.props;

		if (!auth.uid) {
			return <Redirect to="/signin" />;
		}
		return (
			<div className="dashboard container">
				<div className="row">
					<div className="col s12">
						<CompaniesAdministration
							profile={profile}
							companiesDB={this.props.companies}
						/>

					</div>
				</div>
			</div>
		);
	}
}

const mapState = state => {
	return {
		companies: state.firestore.ordered.company,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
	};
};

export default compose(
	connect(mapState),
	firestoreConnect([{ collection: "company" }])
)(CompaniesAdministrationComponent);