import React from 'react'
import {openPopupWidget} from 'react-calendly'
import { connect } from 'react-redux'
import {firestoreConnect} from 'react-redux-firebase'
import {compose} from 'redux'
import {Button} from 'reactstrap'
import moment from 'moment'
import { Redirect} from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const MeetingDetails = (props) => {
    const { professor, meeting, profile } = props
    const { t, i18n } = useTranslation();
    if(professor && meeting) {
        let cancel;
        let renderButton;
        if(meeting.isCancel) {
            renderButton = <div className="red-text">{t('meeting_list_cancel_meeting')}</div>
        }
        else {
            cancel = <Button style={{width: '150px'}} color="danger" block={false} onClick={() => openPopupWidget({url: 'https://calendly.com/cancellations/'+meeting.idCalendly, prefill: {name: profile.firstname, email: profile.email}})}>{t('meeting_detail_cancel_meeting')}</Button>
            renderButton = <Button style={{width: '150px'}} color="primary" block={false} onClick={() => openPopupWidget({url: 'https://calendly.com/reschedulings/'+meeting.idCalendly, prefill: {name: profile.firstname, email: profile.email}})}>{t('meeting_detail_reschedule_meeting')}</Button> 
        }

        const localStartTime = moment(meeting.start_time).local().format("MMMM DD HH:mm A")
        const localEndTime = moment(meeting.end_time).local().format("MMMM DD HH:mm A")
        const localDate = moment(new Date()).local().format("MMMM DD  HH:mm A")

        console.log("localEndTime",localEndTime)
        console.log(meeting.end_time)
        console.log(moment(meeting.end_time) < moment(new Date()).local())




        if(moment(meeting.end_time) < moment(new Date()).local()) {
            cancel = null
            renderButton = <Button style={{width: '150px'}} color="danger">{t('meeting_detail_expired')}</Button>
        }
        if(!props.auth.uid) {
            return <Redirect to="/signin" />
        }
        return (
            <div className="container section project-details">
                <div>
                    <Link to={'/meetings'}><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp; {t('meeting_detail_back_meeting')}</Link>
                </div>
                <div className="card text-center mt-4">
                    <div className="card-body">
                        <p className="card-title">
                            <strong> {t('meeting_detail_event_details')} </strong>
                        </p>

                        <h2>{meeting.event_name}</h2>
                        
                        <p><strong>{getMeetingDateTime(meeting)}</strong></p>

                        <h2>{t('meeting_detail_your_coach')}</h2>
                        <img className="professor-image rounded-circle responsive-img" src={professor.pictureUrl ? professor.pictureUrl : null} alt={professor.name} />
                        <h3>{professor.name}</h3>
                        <h5>{t('professor_list_interests')}</h5>
                        <p>{professor.interest}</p>

                        {renderButton} {' '}
                    {cancel}
                    </div>
                    
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="container center">
                <p>{t('meeting_detail_loading_details')}</p>
            </div>
        )
    }
}

function getMeetingDateTime(meeting) {

    const date = meeting.start_time.split('T')[0]
    const startTime = meeting.start_time.split('T')[1].split('-')[0]
    const endTime = meeting.end_time.split('T')[1].split('-')[0]

    const newDate = moment(date).format('DD-MM')

    return moment(newDate.split('-')[1], 'M').format('MMMM') + " " + newDate.split('-')[0] + " " + moment(startTime, ["h:mm"]).format('HH:mm A') + " - " + moment(endTime, ["h:mm"]).format('HH:mm A') 
}

const mapToProps = (state, ownProps) => {
    const id=ownProps.match.params.id;
    const meetings =state.firestore.data.calendlyMeeting;
    const professors =state.firestore.data.professor;
    const meeting = meetings ? meetings[id] : null;
    const professor = professors ? professors[meeting.professorId] : null;

  return {
    meeting :meeting,
    professor: professor,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }
}

export default compose(
    connect(mapToProps),
    firestoreConnect([
        { collection: 'calendlyMeeting' },
        { collection: 'professor' }
    ])
)(MeetingDetails)
