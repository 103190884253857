const initialState = {
	contracts: [],
};

const contractReducer = (state = initialState, payload) => {
	switch (payload.type) {
		case "FETCH_CONTRACTS":
			return {
				...state,
				contracts: payload.payload,
			};
		default:
			return {
				...state,
			};
	}
};

export default contractReducer;
