import React, { Component } from "react";

import FreeRessouces from "./RessourcesCategories";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class UserManagementComponent extends Component {
	render() { 
		const { auth, profile,meetings} = this.props;

		if (!auth.uid) {
			return <Redirect to="/signin" />;
		}
		return (
			<div className="dashboard container">
				<div className="row">
					<div className="col s12">
						<FreeRessouces/>

					</div>
				</div>
			</div>
		);
	}
}

const mapState = state => {
	return {
		meetings: state.firestore.ordered.calendlyMeeting,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
	};
};

export default compose(
	connect(mapState),
	firestoreConnect([{ collection: "calendlyMeeting" }])
)(UserManagementComponent);
