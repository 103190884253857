import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { getStorage } from "firebase/storage";

// var config  = {
//   apiKey: "AIzaSyCzFpXZtJhKfVs8am7PlhN3EKaF7r7dIYI",
//   authDomain: "bitpost-test.firebaseapp.com",
//   databaseURL: "https://bitpost-test.firebaseio.com",
//   projectId: "bitpost-test",
//   storageBucket: "bitpost-test.appspot.com",
//   messagingSenderId: "593528959406",
//   appId: "1:593528959406:web:824bff9377624a06d0183f",
//   measurementId: "G-H6B63Z7L5P"
// };

var config = {
  apiKey: "AIzaSyAgMwS8n7c0tZfI7ZhgGtN3iWf_u_e7-YE",
  authDomain: "ios-authentification.firebaseapp.com",
  databaseURL: "https://ios-authentification.firebaseio.com",
  projectId: "ios-authentification",
  storageBucket: "ios-authentification.appspot.com",
  messagingSenderId: "61769481998",
  appId: "1:61769481998:web:a6a994677d0d2a453fe15f",
  measurementId: "G-ST0QM7P1DV"
};

  // Initialize Firebase
firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });



export default firebase