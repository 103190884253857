
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import {
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CSVLink, CSVDownload } from "react-csv";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

let morningArray = [];
let lunchArray = [];
let userArray = [];
let labels = "";
let attendanceArray = [];
let attendanceArrayCSV = [];
let toolTipArray = [];


class SprintAttendance extends Component {


  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      error: false,
      selectStudent: ''
    };
    this.onInputchange = this.onInputchange.bind(this);
  }


  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })

    morningArray = [];
    lunchArray = [];
    userArray = [];
    attendanceArray = [];
    attendanceArrayCSV = [];
    toolTipArray = [];

  }





  render() {

    const { auth, profile, users } = this.props;
    const options = {
      type: 'line',
      maintainAspectRatio: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return toolTipArray[tooltipItem.dataIndex];
            }
          }
        },
        legend: {
          position: 'top',
          display: true,
        },
        title: {
          display: true,
          text: this.state.AttendanceStartDate + ' to ' + this.state.AttendanceEndDate,
        },
      },
    };

    labels = getLabels(users, this.state);

    let data = {
      labels,
      datasets: [
        {
          label: 'Sprint time',
          data: morningArray,
          //data: [1,2,3],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        /*{
          label: 'lunch time',
          data: lunchArray,
          //data: [1,2,3],
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },*/
      ],
    };

    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }

    if ((!isLoaded(profile)) || (!isLoaded(users))) {
      return <Redirect to="/" />;
    }
    return (
      <div className="dashboard container mx-auto">

        <h4 className="my-4">Sprint attendance record</h4>

        <form >
          <FormGroup row>
            <Label sm={3}>Start date</Label>
            <Col sm={9}>
              <Input
                required
                type="date"
                max={new Date().toISOString().split('T')[0]}
                name="AttendanceStartDate"
                id="AttendanceStartDate"
                onChange={this.onInputchange}
                value={this.state.AttendanceStartDate}
                defaultValue=""

              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>End date</Label>
            <Col sm={9}>
              <Input
                required
                type="date"
                max={new Date().toISOString().split('T')[0]}
                min = {this.state.AttendanceStartDate}
                name="AttendanceEndDate"
                id="AttendanceEndDate"
                onChange={this.onInputchange}
                value={this.state.AttendanceEndDate}
                defaultValue=""

              />
            </Col>
            <CSVLink data={attendanceArrayCSV.sort()}>Download Detail</CSVLink>
          </FormGroup>
        </form>
        <div>
          {(this.state.AttendanceStartDate != null && this.state.AttendanceEndDate != null) && (
            <Bar options={options} data={data} />
          )}
        </div>

      </div>

    );
  }
}

const mapState = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    users: state.firestore.ordered.users,
  };
};



function getLabels(users, state) {
  morningArray = [];
  lunchArray = [];
  userArray = [];

  if (users != null) {
    let startDate = new Date(moment(state.AttendanceStartDate + "T00:00:00-05:00"))
    let endDate = new Date(moment(state.AttendanceEndDate + "T24:00:00-05:00"))

    var duration = moment.duration(moment(endDate).diff(moment(startDate)));
    var days = duration.asDays();

    let userSprintAttendance = users.filter(user => {

      if (user.sprintAttendance != null && state.AttendanceStartDate != null && state.AttendanceEndDate != null) {
        let nbMorning = 0;
        let nbLunch = 0;
        let addUser = false;

        user.sprintAttendance.filter(attendance => {


          let date
          try {
            date = new Date(attendance.toDate())

          } catch (error) {
            console.log("Erreur de conversion de date")

          }


          if (date >= startDate && date <= endDate) {

            let userObj = []
            userObj.push(moment(date).format("YYYY-MM-DD hh:mm"), user.firstname + " " + user.lastname)
            attendanceArrayCSV.push(userObj)

            attendanceArray.push([moment(date).format("hh:mm"), userObj])

          }
        })
      }
    })

    console.log(attendanceArray)

    let lastHour = ""
    let count = 0
    let previousHour = ""
    let namePresent = []

    attendanceArray.sort().map(hour => {



      if (previousHour != "" && previousHour[0] != hour[0]) {
        userArray.push(previousHour[0] + " ( mean : " + ((count - (1 * days)) / days).toFixed(2) + " students)")
        morningArray.push(count)
        toolTipArray.push(namePresent)
        count = 1
        namePresent = []
        previousHour = hour
        namePresent.push(previousHour[1][1])
      } else {
        previousHour = hour
        count += 1
        namePresent.push(previousHour[1][1])
      }
    })
    userArray.push(previousHour[0] + " ( mean : " + ((count - (1 * days)) / days).toFixed(2) + " students)")
    morningArray.push(count)
    toolTipArray.push(namePresent)
    console.log(morningArray)


    return (
      userArray
    );
  }
}



export default compose(
  firestoreConnect([{ collection: "users" }]),
  connect(mapState)
)(SprintAttendance);

