const initState = {
  professors: [],
};

const professorReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_PROFESSOR":
      return { ...state, professors: action.payload };
      break;

    default:
      return { ...state };

      break;
  }
};

export default professorReducer;
