import React, { Component } from "react";
import { connect } from "react-redux";
import {
	signIn,
	loginWithGoogle,
	loginWithFacebook,
	loginWithApple,
	forgotPassword,
	clearErrorMessage ,
} from "../../store/actions/authActions";
import { Redirect, NavLink } from "react-router-dom";
import {
	Row,
	Col,
	Button,
	Input,
	InputGroup,
	InputGroupText,
	InputGroupAddon,
} from "reactstrap";
import logo from "../../logo.png";
import { Spinner } from "reactstrap";
import { isEmpty, isLoaded } from "react-redux-firebase";
import emailjs from '@emailjs/browser';
import firebase from "firebase/app";

export class SignIn extends Component {
	state = {
		email: "",
		password: "",
		errorMessage: "",
		authentificationError: "",
		resetMode: false,
	};

	handleChange = e => {
		this.setState({
			[e.target.id]: e.target.value,
		});
	};
	handleSubmit = e => {
		e.preventDefault();
	};

	handleClick = () => {
		// Dispatchez votre action ici
		this.props.clearErrorMessage();
		//this.props.dispatch({ type: 'LOGIN_CHANGE_SUCCESS', err: '' });
	};


	render() {
		const { authError, auth, profile } = this.props;

		/*if (!this.state.resetMode)
			this.state.authentificationError = authError;
		//If user is authenticated then redirect to Professor page.
		if (auth.uid ) {
			if (isLoaded(profile)) {
				return <Redirect to="/" />;
			}
			else
				if (authError === null || authError === "") {
					var templateParams = {
						email: profile.email,
						error: "Erreur, le profile semble être vide auth id: " + auth.uid
					};
	
					emailjs.send('service_x9s33ur', 'template_zgnb1l5', templateParams, '5Sygx-dSIpMv3Ykh2')
						.then(function (response) {
							console.log('SUCCESS!', response.status, response.text);
						}, function (error) {
							console.log('FAILED...', error);
						});

					this.state.authentificationError = "Aie aie aie une erreur inattendue est survenue. Communiquer avec Ellipse pour du support.";
				}
		} else {
			if (authError === "")
				this.state.authentificationError = "";
		}*/

		//If user is authenticated then redirect to Professor page.
		if (isLoaded(auth) && !isEmpty(auth) && isLoaded(profile)) {
			console.log("auth is loaded and not empty  then redirect to Professor page");
			return <Redirect to="/" />;
		}
		if (isEmpty(profile)) {
			console.log("profile is empty");
		}
		if (!isLoaded(auth) || !isLoaded(profile)) {
			console.log("auth or profile is not loaded");
			return (
				<div className="text-center mt-3">
					Loading your Student Portal...<br></br>
					Paradise will be here in few seconds :D <br></br>
					<Spinner />
				</div>
			)
		} else {

			return (
				<div className="auth-page">
					<div className="container pt-5">
						<form onSubmit={this.handleSubmit}>
							<Row className="justify-content-md-center">
								<Col sm="10" md="5">
									<div className="text-center">
										<img src={logo} width="60%" className="logo" alt="Ellipse" />
									</div>

									<div className="card-head">
										<div className=" pt-4 pb-3 text-center ">
											Welcome to Student Portal
										</div>
									</div>
									<div className="card-body">
										<h5 className="card-title text-center">Log in to your account</h5>
										<div className="input-field">
											<label htmlFor="email">Email</label>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fa fa-envelope"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="email"
													id="email"
													onChange={this.handleChange}
												/>
											</InputGroup>
										</div>
										<div className="input-field mt-2">
											<label htmlFor="password">Password</label>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fa fa-lock"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="password"
													id="password"
													onChange={this.handleChange}
												/>
											</InputGroup>
										</div>
										<div className="input-field mt-3">
											<Button
												type="submit"
												block
												className="text-capitalize"
												color="primary"
												name="Login"
												onClick={() => {
													this.state.errorMessage = ""; 
													this.state.resetMode = false;
													this.props.signIn(this.state);
													this.forceUpdate();
												}}
											>
												Login with your Email
											</Button>
											{/* <button type="submit" className="btn pink lighten-1 z-depth-0">
                                                Login
                                            </button> */}
											<div className="center red-text-big">{this.state.authentificationError ? this.state.authentificationError : null}</div>

											<div className="center red-text-big">{this.state.errorMessage != "" ? this.state.errorMessage : null}</div>
											<div className="center red-text-big">{this.props.authError != "" ? this.props.authError : null}</div>
										</div>

										<div className="my-3 text-center">
											<strong>OR</strong>
										</div>

										<Button
											onClick={() => this.props.loginWithGoogle(this.state)}
											className="text-capitalize google-login mt-3"
											block
										>
											Login with <i className="fa fa-google"></i> Google
										</Button>
										<Button
											onClick={() => loginWithFacebook()}
											className="text-capitalize facebook-login"
											block
										>
											Login with <i className="fa fa-facebook"></i> Facebook
										</Button>

										<p className="text-center mt-2">
											Don't have an account ?{" "}
											<NavLink to="/signup" onClick={this.handleClick} >
												<strong className="info-text">Create an account</strong>
											</NavLink>
											<p>
												<Button type="submit"

													onClick={() => {
														if (this.state.email === "") {
															this.state.errorMessage = "Provide an email to reset your password";
															this.forceUpdate();
														} else {
															this.state.resetMode = true;
															this.state.authentificationError = "";

															this.state.errorMessage = "";
															this.props.forgotPassword(this.state)

															if (this.state.errorMessage === "") {
																this.state.errorMessage = "You will receive an email with a link to reset your password."
															}
															this.forceUpdate();

														}
													}

													}
													name="Reset"
													className="text-center text-capitalize google-login mt-3 btn-primary-outline"


												> forgot your password?
												</Button>
											</p>
										</p>

									</div>
								</Col>
							</Row>
						</form>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		authError: state.auth.authError,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		authentificationError: state.authentificationError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		signIn: creds => dispatch(signIn(creds)),
		loginWithGoogle: () => dispatch(loginWithGoogle()),
		loginWithFacebook: () => dispatch(loginWithFacebook()),
		loginWithApple: () => dispatch(loginWithApple()),
		forgotPassword: creds => dispatch(forgotPassword(creds)),
		clearErrorMessage: () => dispatch(clearErrorMessage()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
