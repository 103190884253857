import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Navigation from "./components/layout/Navigation";
import Dashboard from "./components/dashboard/Dashboard";
import Meetings from "./components/meetings/Meetings";
import Sprint from "./components/sprint/EllipseSprintV3";
import SprintAttendance from "./components/sprint/SprintAttendance";
import LearningLogs from "./components/users/SearchUsers";
import UserManagement from "./components/UserManagement/UserManagementComponent";
import Masterminds from "./components/masterminds/mastermindsList";
import Attendance from "./components/attendanceRecord/AttendanceRecord";
import Confidence from "./components/confidenceApproche/confidenceApproche";
import SprintAdmin from "./components/sprintAdministration/SprintAdministrationComponent";
import SprintPlanning from "./components/SprintPlanning/sprintPlanning";
import CompaniesAdmin from "./components/companiesAdministration/CompaniesAdministrationComponent";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import MeetingDetails from "./components/meetings/MeetingDetails";
import "./App.css";
import Administration from "./components/administration/administration";
import SurveyIntact from "./components/Survey/Intact";
import i18n from './i18n';
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { isLoaded } from "react-redux-firebase";
import Ressources from "./components/Ressources/Ressources";


class App extends Component {
	render() {
		if (!isLoaded(this.props.profile)) {
			return (
				<div className="text-center mt-3">
					Loading your Student Portal...<br></br>
					Paradise will be here in few seconds :D <br></br>
					<Spinner />
				</div>
			)
		} else {
			i18n.changeLanguage(this.props.profile.language);
			return (
				<BrowserRouter>
					<div>
						<Navigation>
							<Switch>
								<Route exact path="/" exact1 component={Dashboard} />
								<Route exact path="/coaches" exact2 component={Dashboard} />
								<Route exact path="/meetings" exact3 component={Meetings} />
								<Route exact path="/sprint" exact4 component={Sprint} />
								<Route exact path="/sprintattendance" exact5 component={SprintAttendance} />
								<Route exact path="/attendance" exact6 component={Attendance} />
								<Route exact path="/confidence" exact9 component={Confidence} />
								<Route exact path="/intact" exact7 component={SurveyIntact} />
								<Route exact path="/userManagement" exact8 component={UserManagement} />
								<Route exact path="/resources" exact11 component={Ressources} />
								<Route exact path="/masterminds" exact10 component={Masterminds} />
								<Route exact path="/learningLogs" exact12 component={LearningLogs} />
								<Route exact path="/sprintAdministration" exact13 component={SprintAdmin} />
								<Route exact path="/companiesAdministration" exact14 component={CompaniesAdmin} />
								<Route exact path="/sprintPlanning" exact15 component={SprintPlanning} />
								<Route
									exact
									path="/administration"
									exact9
									component={Administration}
								/>
								<Route exact path="/meeting/:id" component={MeetingDetails} />
							</Switch>
						</Navigation>
						<Switch>
							<Route path="/signin" component={SignIn} />
							<Route path="/signup" component={SignUp} />
						</Switch>
					</div>
				</BrowserRouter>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile
	};
};

export default connect(mapStateToProps)(App);
