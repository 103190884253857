const initialState = {
  company: [],
};

const CompanyReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case "FETCH_COMPANY":
      return {
        ...state,
        company: payload.payload,
      };

      break;

    default:
      return {
        ...state,
      };
      break;
  }
};

export default CompanyReducer;
