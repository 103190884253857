import React, { Component } from "react";
import MeetingList from "../meetings/MeetingList";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class Dashboard extends Component {
	render() { 
		//console.log(this.props);
		const { meetings, auth, profile } = this.props;
		let userMeetings = [];
		if (profile && meetings) {
			userMeetings = meetings.filter(meeting => meeting.idUser === profile.uid);
		}
		if (!auth.uid) {
			return <Redirect to="/signin" />;
		}
		return (
			<div className="dashboard container">
				<div className="row">
					<div className="col s12">
						<MeetingList meetings={userMeetings} loaded={isLoaded(meetings)} />
					</div>
				</div>
			</div>
		);
	}
}

const mapState = state => {
	return {
		meetings: state.firestore.ordered.calendlyMeeting,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
	};
};

export default compose(
	connect(mapState),
	firestoreConnect([{ collection: "calendlyMeeting" }])
)(Dashboard);
