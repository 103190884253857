const initState = {
  users: [],
};

const usersReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_USERS":
      return { ...state, users: action.payload };
      break;

    default:
      return { ...state };

      break;
  }
};

export default usersReducer;
