
import React, { useState, useEffect, memo } from "react";
import { Formik, Field } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";
import input from "../administration/input";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Spinner } from "reactstrap";
import styled from 'styled-components';
import DeleteConfirmation from "./deleteConfirmation";
import "./CollapsibleDiv.css"; // Assurez-vous d'avoir un fichier CSS pour le style du collapsible div.
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Avatar from '@material-ui/core/Avatar';
import firebase from "firebase";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'; // Assurez-vous d'installer react-icons
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const CustomButton = styled.button`
background-color: #007bff;
color: #fff;
padding: 10px 20px;
border: none;
border-radius: 5px;
cursor: pointer;
`;


const userAdministration = memo(function userAdministration({
  isOpen, onClose, onSave, userData, isAdd, isModify, profile, updateHoursRemaining
}) {

  // Set up some additional local state
  const [type, setType] = useState(null);
  const [idTodelete, setIdTodelete] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [showPurchaseList, setShowPurchaseList] = useState(false); // État pour contrôler la visibilité de la liste des achats

  ///Action Plan
  // Utilisation de l'état (state) pour suivre la nouvelle valeur en cours de saisie
  const [nouvelleValeurActionPlan, setNouvelleValeurActionPlan] = useState({
    description: "",
    id: "",
    retired: false,
  });

  // Fonction pour ajouter une nouvelle valeur à la liste
  const ajouterActionPlan = () => {
    // Create a copy of the user object
    const updatedUserObj = { ...user };

    // Initialize the actionPlans array if it's undefined
    if (!updatedUserObj.actionPlans) {
      updatedUserObj.actionPlans = [];
    }

    // Add the new action plan to the actionPlans array
    updatedUserObj.actionPlans = [...updatedUserObj.actionPlans, nouvelleValeurActionPlan];

    // Optionally, update the state with the modified user object
    setUser(updatedUserObj);

    // Optionally, reset the form or state for nouvelleValeurActionPlan
    setNouvelleValeurActionPlan({ description: "", id: "", retired: false });
  };

  const retireActionPlan = (idToRetire) => {
    // Create a copy of the user object
    const updatedUserObj = { ...user };

    // Check if the actionPlans array exists
    if (updatedUserObj.actionPlans) {
      // Set the retired property to true for the action plan with the specified id
      updatedUserObj.actionPlans = updatedUserObj.actionPlans.map((actionPlan) => {
        if (actionPlan.id === idToRetire) {
          return { ...actionPlan, retired: true };
        }
        return actionPlan;
      });

      // Optionally, update the state with the modified user object
      setUser(updatedUserObj);
    }
  };


  const handleSave = () => {
    const updatedUser = { ...user, hoursPurchased };
    onSave(updatedUser);
    onClose();
  };


  const schema = yup.object({
    date: yup.string().required("Date is required"),
    duration: yup.number().required("Duration is required"),
    duration: yup.number().min(0).max(3).required(),
    discussion: yup.string().required("Disccussion is required"),
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),

      [theme.breakpoints.down("sm")]: {
        minWidth: 389,
        maxWidth: 389,
      },
      [theme.breakpoints.up("md")]: {
        minWidth: 396,
        maxWidth: 396,
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: 389,
        maxWidth: 389,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: 331,
        maxWidth: 331,
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  const getDefaultUser = () => ({
    email: "",
    firstname: "",
    lastname: "",
    numberHourBuy: 0,
    numberHoursRemaining: 0,
    Professors: [],
    contracts: [],
    role: [],
    company: "",
    mastermind: "",
    bootCamp: "",
    bootCampEndDate: "",
    subscribeBootCamp: "",
    bootCampStartDate: "",
    pauseSprintFrom: "",
    pauseSprintUntil: "",
    lastPaymentSprint: null,
    confidenceObjective1: "",
    confidenceObjective2: "",
    confidenceObjective3: "",
    actionPlans: [],
    hoursPurchased: []
  });

  const [user, setUser] = useState(userData || getDefaultUser());

  // Nouveaux états pour gérer l'ajout des heures
  const [newHours, setNewHours] = useState({
    id: uuidv4(),
    datePurchase: "",
    numberOfHours: 0,
    endDate: "",
    retired: false,
    updateTime: new Date().toISOString(),
    updatedBy: profile?.email || "Unknown", // Utiliser l'email du profil si disponible
  });

  const [hoursPurchased, setHoursPurchased] = useState(user.hoursPurchased || []);
  const [showHoursForm, setShowHoursForm] = useState(false);

  const [editingId, setEditingId] = useState(null);

  const handleAddHours = async () => {
    const updatedTime = new Date().toISOString();
    const userEmail = profile?.email || "Unknown";

    let updatedHours;
    if (editingId) {
      updatedHours = hoursPurchased.map(hour =>
        hour.id === editingId
          ? { ...hour, ...newHours, updateTime: updatedTime, updatedBy: userEmail }
          : hour
      );
      setEditingId(null);
    } else {
      updatedHours = [
        ...hoursPurchased,
        { ...newHours, updateTime: updatedTime, updatedBy: userEmail }
      ];
    }

    // Update the state with the new hoursPurchased array
    setHoursPurchased(updatedHours);

    // Create an updated user object that includes the latest hoursPurchased
    let updatedUser = { ...user, hoursPurchased: updatedHours };

    // Update the number of hours purchased
    const updatedNumberHourBuy = updateNumberHourBuy(updatedUser.hoursPurchased);
    updatedUser = { ...updatedUser, numberHourBuy: updatedNumberHourBuy };

    setUser(updatedUser); // Set the updated user state here

    // Handle the promise from updateHoursRemaining
    try {
      const tot = await updateHoursRemaining(updatedUser);
      updatedUser = { ...updatedUser, numberHoursRemaining: tot }; // Update the local updatedUser
      setUser(updatedUser); // Set the updated user state here
    } catch (error) {
      console.error("Error updating remaining hours:", error);
    }

    // Reset the form
    setNewHours({ id: uuidv4(), datePurchase: "", numberOfHours: 0, endDate: "" });
    setShowHoursForm(false);

    // Recalculate hours taken and update the UI
    await fetchAndCalculateHoursTaken(updatedUser);
  };




  const calculateHoursTaken = (learningLogs, updatedHoursPurchased) => {
    let currentIndex = 0;

    learningLogs.forEach(log => {
      let logDuration = parseFloat(log.duration) || 0;
      const logDateTime = new Date(log.date);

      // Process logs only if they fall within the time range of any period
      while (logDuration > 0 && currentIndex < updatedHoursPurchased.length) {
        const currentPeriod = updatedHoursPurchased[currentIndex];
        const startDateTime = new Date(currentPeriod.datePurchase);
        const endDateTime = new Date(currentPeriod.endDate);

        if (logDateTime >= startDateTime && logDateTime <= endDateTime) {
          const availableHours = currentPeriod.remainingHours;

          // Allocate log duration within the available hours of the current period
          if (logDuration <= availableHours) {
            currentPeriod.hoursTaken += logDuration;
            currentPeriod.remainingHours -= logDuration;
            log.used = true;
            currentPeriod.usedLogDates.push(`${log.date} - ${log.duration} hour(s)`);
            logDuration = 0; // Entire log is used

          } else {
            // Use up all remaining hours of the current period
            currentPeriod.hoursTaken += availableHours;
            logDuration -= availableHours;
            currentPeriod.remainingHours = 0;
            currentPeriod.usedLogDates.push(`${log.date} - ${availableHours} hour(s)`);
            currentIndex++; // Move to the next period

          }
        } else {
          currentIndex++; // Move to the next period if log date doesn't fit
        }
      }

      // If log is not used and we've gone through all periods, allocate to last period
      if (logDuration > 0 && currentIndex >= updatedHoursPurchased.length && !log.used) {
        const lastPeriod = updatedHoursPurchased[updatedHoursPurchased.length - 1];
        lastPeriod.hoursTaken += logDuration;
        lastPeriod.remainingHours -= logDuration;
        log.used = true;
        lastPeriod.usedLogDates.push(`${log.date} - ${log.duration} hour(s)`);
      }
    });
  };

  const fetchAndCalculateHoursTaken = async (user) => {
    try {
      const resp = await firestore.collection('learningLogs')
        .where("userId", "==", user.id)
        .get();

      let learningLogs = resp.docs.map(doc => ({ ...doc.data(), used: false }));

      // Sort the purchased hours by date of purchase
      const sortedHoursPurchased = [...user.hoursPurchased].sort((a, b) => new Date(a.datePurchase) - new Date(b.datePurchase));

      const updatedHoursPurchased = sortedHoursPurchased.map(hourPurchased => ({
        ...hourPurchased,
        hoursTaken: 0,
        remainingHours: hourPurchased.numberOfHours,
        usedLogDates: [],
      }));


      // Calculate hours taken and borrow across periods if needed
      calculateHoursTaken(learningLogs, updatedHoursPurchased);

      // Set the updated state
      setHoursPurchased(updatedHoursPurchased);

    } catch (error) {
      console.error("Error fetching learning logs:", error);
    }
  };


















  const handleEdit = (id) => {
    const hourToEdit = hoursPurchased.find(hour => hour.id === id);
    setNewHours(hourToEdit);
    setEditingId(id);
    setShowHoursForm(true);
  };


  const handleDelete = async (id) => {
    const hourIndex = hoursPurchased.findIndex(hour => hour.id === id);

    if (hourIndex !== -1) {
      const updatedHour = { ...hoursPurchased[hourIndex], retired: true };
      const updatedHoursPurchased = [
        ...hoursPurchased.slice(0, hourIndex),
        updatedHour,
        ...hoursPurchased.slice(hourIndex + 1)
      ];

      setHoursPurchased(updatedHoursPurchased);

      let updatedUser = { ...user, hoursPurchased: updatedHoursPurchased };
      setUser(updatedUser);

      // Recalculate hours taken and update the UI
      await fetchAndCalculateHoursTaken(updatedUser);
    }
  };



  const handleChangeNewHours = (e) => {
    const { name, value } = e.target;

    if (name === 'datePurchase') {
      // Automatically calculate the end date as one year after the start date
      const startDate = new Date(value);
      const endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + 1);

      setNewHours({
        ...newHours,
        datePurchase: value,
        endDate: endDate.toISOString().split('T')[0], // Format the end date as YYYY-MM-DD
      });
    } else {
      setNewHours({ ...newHours, [name]: value });
    }
  };




  const onRowClicked = (row, event) => {

  };


  const onRowClickedDelete = (row, event) => {

  };


  const addRow = () => {

  };

  function getStyles(name, personName, theme) {
    if (personName)
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
  }

  const theme = useTheme();



  // Handle the displaying of the modal based on type and id
  const showDeleteModal = (date, name, id, duration) => {

    setDeleteMessage(`Are you sure you want to delete the entry '${date}' in the learning log?`);
    setIdTodelete(id)
    setDuration(duration)

    setDisplayConfirmationModal(true);

  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {

    deleteLearningLog(idTodelete)
    setDisplayConfirmationModal(false);
  };

  const updateNumberHourBuy = (hours) => {
    if (!hours || hours.length === 0) {
      return null; // Return null if hours is null or undefined
    }

    const today = new Date().toISOString().split('T')[0]; // Format ISO (YYYY-MM-DD)

    const filteredHours = hours.filter(hour => {
      const startDate = new Date(hour.datePurchase).toISOString().split('T')[0];
      const endDate = new Date(hour.endDate).toISOString().split('T')[0];
      const isValidPeriod = startDate <= today && endDate >= today && !hour.retired;

      return isValidPeriod;
    });

    const totalHours = filteredHours.reduce((total, hour) => {
      const parsedHours = parseFloat(hour.numberOfHours);
      return total + parsedHours;
    }, 0);

    return totalHours;
  };



  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const [showContentActions, setShowContentActions] = useState(false);

  const toggleContentActions = () => {
    setShowContentActions(!showContentActions);
  };

  const getLabelProfessor = value => {
    let obj = professorDB.find(item => item.id === value);

    if (obj != null)
      return obj.name;
    else
      return "";

  };
  const getPictureUrlProfessor = value => {
    let obj = professorDB.find(item => item.id === value);

    if (obj != null) {
      return obj.pictureUrl;
    }
    else {
      return "";
    }
  };

  const [professorDB, setProfessorDB] = useState([]);
  const [rolesDB, setRolesDB] = useState([]);
  const [companyDB, setCompanyDB] = useState([]);
  const [mastermindDB, setMastermindDB] = useState([]);
  const firestore = firebase.firestore();

  useEffect(() => {
    if (user.id) {
      fetchAndCalculateHoursTaken(user);
    }


    // Fonction pour mettre à jour le nombre d'heures achetées actives
    const updateHoursOnLoad = () => {
      const updatedNumberHourBuy = updateNumberHourBuy(user.hoursPurchased);

      if (updatedNumberHourBuy != null) {

        const updatedUser = {
          ...user,
          numberHourBuy: updatedNumberHourBuy
        };

        setUser(updatedUser);

        // Appeler onSave avec l'utilisateur mis à jour
        onSave(updatedUser);
      }
    };


    const professorDB = async () => {
      const professorDBRef = firestore.collection("professor");
      const querySnapshot = await professorDBRef.get();
      const professors = [];
      querySnapshot.forEach((doc) => {
        professors.push({ id: doc.id, ...doc.data() });
      });
      setProfessorDB(professors)
    };

    const rolesDB = async () => {
      const rolesDBRef = firestore.collection("roles");
      const querySnapshot = await rolesDBRef.get();
      const roles = [];
      querySnapshot.forEach((doc) => {
        roles.push({ id: doc.id, ...doc.data() });
      });
      setRolesDB(roles)
    };

    const companyDB = async () => {
      const companyDBRef = firestore.collection("company");
      const querySnapshot = await companyDBRef.get();
      const company = [];
      querySnapshot.forEach((doc) => {
        company.push({ id: doc.id, ...doc.data() });
      });
      // Tri du tableau company par ID
      const sortedCompany = company.sort((a, b) => a.name.localeCompare(b.name));

      setCompanyDB(sortedCompany);
    };

    const mastermindDB = async () => {
      const mastermindRef = firestore.collection("mastermind");
      const mastermindsRef = firestore.collection("masterminds");

      // Récupérer les documents de la première collection `mastermind`
      const mastermindSnapshot = await mastermindRef.get();
      const mastermindData = mastermindSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Récupérer les documents de la deuxième collection `masterminds`
      const mastermindsSnapshot = await mastermindsRef.get();
      const mastermindsData = mastermindsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Combiner les deux listes
      const combinedData = [...mastermindData, ...mastermindsData];

      // Trier la liste combinée par le nom en ordre décroissant
      const sortedCombinedData = combinedData.sort((a, b) => b.name.localeCompare(a.name));

      setMastermindDB(sortedCombinedData); // Met à jour l'état ou la variable avec les données triées en ordre inverse


    };



    const fetchData = async () => {
      await Promise.all([professorDB(), rolesDB(), companyDB(), mastermindDB()]);
      setIsUserOpen(true);
    };

    fetchData();
    updateHoursOnLoad();


  }, []);

  const [displayEmailWarning, setDisplayEmailWarning] = useState(false);

  const handleChangeForm = e => {

    let value = "";

    if (e.target.name === "numberHourBuy" || e.target.name === "numberHoursRemaining") {
      value = Number(e.target.value);
      //updateRemainingHour(value);
    } else {
      value = e.target.value;
    }

    if (e.target.name === "email") {
      value = value.trimEnd().toLowerCase()

      // Check if the email has changed
      if (!isAdd && user.email !== value) {
        // Set a state variable to indicate that a warning should be displayed
        setDisplayEmailWarning(true);
      } else {
        // Reset the state variable if the email is changed back
        setDisplayEmailWarning(false);
      }
    }

    if (e.target.name === "accountManager") {
      value = user.role
      if (value == null)
        value = []
      value.push("account manager")
      setUser({ ...user, role: value });
    } else {
      setUser({ ...user, [e.target.name]: value });
    }
  };


  /****************************************************SECTION SPRINT************************************************ */
  // Ajouter un nouvel état pour gérer la visibilité du sprint
  const [isSprintOpen, setIsSprintOpen] = useState(false);

  // La fonction pour basculer la visibilité du sprint
  const toggleSprint = () => {
    setIsSprintOpen(!isSprintOpen);
  };
  /****************************************************END SECTION SPRINT************************************************ */


  /****************************************************SECTION USER************************************************ */
  // Ajouter un nouvel état pour gérer la visibilité du User
  const [isUserOpen, setIsUserOpen] = useState(false);

  // La fonction pour basculer la visibilité du User
  const toggleUser = () => {
    setIsUserOpen(!isUserOpen);
  };
  /****************************************************END SECTION USER************************************************ */

  /****************************************************SECTION CONFIDENCE objective************************************************ */
  // Ajouter un nouvel état pour gérer la visibilité du Confidence
  const [isConfidenceOpen, setIsConfidenceOpen] = useState(false);

  // La fonction pour basculer la visibilité du Confidence
  const toggleConfidence = () => {
    setIsConfidenceOpen(!isConfidenceOpen);
  };
  /****************************************************END SECTION CONFIDENCE objective************************************************ */

  /****************************************************SECTION CONFIDENCE action plan************************************************ */
  // Ajouter un nouvel état pour gérer la visibilité du Confidence
  const [isActionPlanOpen, setIsActionPlanOpen] = useState(false);

  // La fonction pour basculer la visibilité du Confidence
  const toggleActionPlan = () => {
    setIsActionPlanOpen(!isActionPlanOpen);
  };

  // Fonction pour supprimer une valeur de la liste
  const handleDeleteActionPlan = (index, e) => {
    e.preventDefault();

    const nouvellesValeurs = [...actionPlansDB[0].actions];
    nouvellesValeurs.splice(index, 1);

    const nouvellesActionPlansDB = JSON.parse(JSON.stringify(actionPlansDB));

    nouvellesActionPlansDB[0].actions = nouvellesValeurs;

    const firestore = firebase.firestore();


    firestore
      .collection("confidencePlanActions")
      .doc(actionPlansDB[0].id)
      .update(nouvellesActionPlansDB[0])
      .then(() => {

      })
      .catch(error => console.log(error.message))
  };
  /****************************************************END SECTION CONFIDENCE action plan************************************************ */


  /****************************************************SECTION Company************************************************ */
  // Ajouter un nouvel état pour gérer la visibilité du Confidence
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  // La fonction pour basculer la visibilité du Confidence
  const toggleCompany = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };
  /****************************************************END SECTION Company************************************************ */

  /****************************************************SECTION Mastermind************************************************ */
  // Ajouter un nouvel état pour gérer la visibilité du Confidence
  const [isMastermindOpen, setIsMastermindOpen] = useState(false);

  // La fonction pour basculer la visibilité du Confidence
  const toggleMastermind = () => {
    setIsMastermindOpen(!isMastermindOpen);
  };
  /****************************************************END SECTION Mastermind************************************************ */

  // Calcul de l'ID de mastermind le plus récent ou de celui de `user.mastermind`
  const getSelectedMastermindId = () => {
    if (user.masterminds && user.masterminds.length > 0) {
      // Prend le mastermind avec la date `dateAdded` la plus récente dans `user.masterminds`
      const recentMastermind = user.masterminds.reduce((latest, current) => {
        return !latest || current.dateAdded > latest.dateAdded ? current : latest;
      });
      return recentMastermind.id;
    } else {
      // Utilise `user.mastermind` s'il n'y a pas de `user.masterminds`
      return user.mastermind;
    }
  };

  if (!isLoaded(professorDB)) {
    return (
      <div className="text-center mt-3">
        <Spinner />
      </div>
    )
  } else {


    return (
      <div className="form">


        {isAdd && (<div align="center">
          <div>
            <h4>Create a new member</h4>
          </div>
        </div>)}

        {isModify && (<div align="center">
          <div>
            <h4>Update the member {user.firstname} {user.lastname}</h4>
          </div>
        </div>)}


        {(isAdd || isModify) && (
          <Form >
            <h4 style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '1.1rem', marginBottom: '25px' }} onClick={toggleUser}>
              Personal information
              {isUserOpen ? <FaAngleDown style={{ marginLeft: '5px', marginTop: '5px' }} /> : <FaAngleRight style={{ marginLeft: '5px', marginTop: '5px' }} />}
            </h4>
            {isUserOpen && (
              <div>
                {displayEmailWarning && (
                  <div style={{ marginTop: '10px', border: '1px solid #f0ad4e', backgroundColor: '#fcf8e3', borderRadius: '4px', padding: '10px' }}>
                    <strong style={{ color: '#8a6d3b' }}>Warning:</strong> Changing the email may have consequences. <br />
                    <span style={{ color: '#8a6d3b' }}>If you change the email, you will need to notify the user to create a new account with their new email address.</span>
                  </div>
                )}
                <FormGroup>
                  <Label for="email" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>
                    Email
                  </Label>
                  <Input
                    required
                    value={user.email}
                    type="text"
                    onChange={handleChangeForm}
                    name="email"
                    id="email"
                    placeholder="Enter the email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="fisrtname" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>First name</Label>
                  <Input
                    required
                    value={user.firstname}
                    type="text"
                    onChange={handleChangeForm}
                    name="firstname"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastname" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Last name</Label>
                  <Input
                    required
                    value={user.lastname}
                    type="text"
                    onChange={handleChangeForm}
                    name="lastname"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="language" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Default language</Label>
                  <Input
                    type="select"
                    name="language"
                    id="language"
                    onChange={handleChangeForm}
                    value={user.language != null ? user.language : "en"}
                    style={{ fontWeight: "bold", color: "#555", height: "50px" }} // Augmenter la hauteur du champ
                  >
                    <option value="en" selected style={{ color: "#999", fontWeight: "bold", height: "50px" }}>English</option>
                    <option value="fr" style={{ color: "#999", fontWeight: "bold", height: "50px" }}>French</option>
                  </Input>
                </FormGroup>
                <div style={{ marginBottom: "20px" }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                    <Button color="primary" onClick={() => setShowHoursForm(!showHoursForm)} style={{ marginRight: '10px' }}>
                      {showHoursForm ? "Cancel" : "Purchase Hours"}
                    </Button>
                    {!showHoursForm && (
                      <Button color="secondary" onClick={() => setShowPurchaseList(!showPurchaseList)}>
                        {showPurchaseList ? "Hide Purchase List" : "Show Purchase List"}
                      </Button>
                    )}
                  </div>

                  {showHoursForm && (
                    <div style={{
                      padding: '20px',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      backgroundColor: '#f7f7f7',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      maxWidth: '500px',
                      margin: '0 auto',
                    }}>
                      <FormGroup>
                        <Label for="datePurchase">Date purchased</Label>
                        <Input
                          type="date"
                          name="datePurchase"
                          value={newHours.datePurchase}
                          onChange={handleChangeNewHours}
                          style={{ marginBottom: '10px' }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="numberOfHours">Number of hours purchased</Label>
                        <Input
                          type="number"
                          name="numberOfHours"
                          value={newHours.numberOfHours}
                          onChange={handleChangeNewHours}
                          style={{ marginBottom: '10px' }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="endDate">End Date</Label>
                        <Input
                          type="date"
                          name="endDate"
                          value={newHours.endDate}
                          onChange={handleChangeNewHours}
                          style={{ marginBottom: '20px' }}
                        />
                      </FormGroup>
                      <Button color="primary" onClick={handleAddHours} style={{ display: 'block', width: '100%' }}>
                        {editingId ? "Update" : "Add"}
                      </Button>
                    </div>
                  )}
                </div>

                {showHoursForm && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                    <Button color="secondary" onClick={() => setShowPurchaseList(!showPurchaseList)}>
                      {showPurchaseList ? "Hide Purchase List" : "Show Purchase List"}
                    </Button>
                  </div>
                )}

                {showPurchaseList && (
                  <ul style={{ padding: 0, listStyleType: 'none', marginTop: '20px' }}>
                    {hoursPurchased
                      .filter(hour => !hour.retired) // Filter out retired hours
                      .map((hour) => (
                        <li key={hour.id} style={{
                          padding: '20px',
                          marginBottom: '15px',
                          border: '1px solid #e0e0e0',
                          borderRadius: '8px',
                          backgroundColor: '#fafafa',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          transition: 'transform 0.2s',
                          display: 'flex',
                          flexDirection: 'column', // Align elements vertically
                          gap: '15px', // Space between elements
                        }}
                          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
                          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            Purchase Date: {hour.datePurchase}
                          </div>
                          <div style={{ fontSize: '16px' }}>
                            {hour.numberOfHours} hours purchased
                          </div>
                          <div style={{ fontSize: '16px' }}>
                            Hours taken: {hour.hoursTaken || 0} {/* Display hours taken */}
                          </div>
                          <div style={{ fontSize: '16px', fontStyle: 'italic' }}>
                            End Date: {hour.endDate}
                          </div>
                          {hour.usedLogDates && hour.usedLogDates.length > 0 && (
                            <div style={{ fontSize: '14px', marginTop: '10px' }}>
                              <strong>Learning Log Dates:</strong>
                              <ul style={{ padding: '0', listStyleType: 'none', marginTop: '5px' }}>
                                {hour.usedLogDates.map((logDate, index) => (
                                  <li key={index} style={{ fontSize: '14px', color: '#555' }}>
                                    {logDate}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {hour.isAutomaticCreate && (
                            <div style={{ fontSize: '12px', color: '#666', marginTop: '5px' }}>
                              Automatically generated by the system
                            </div>
                          )}
                          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <Button size="sm" onClick={() => handleEdit(hour.id)}>
                              <FaEdit /> Edit
                            </Button>
                            <Button size="sm" disabled={hour.isAutomaticCreate} onClick={() => handleDelete(hour.id)}>
                              <FaTrashAlt /> Retire
                            </Button>
                          </div>
                        </li>
                      ))}
                  </ul>

                )}

                <FormGroup>
                  <Label for="hour bought" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Total active hours purchased</Label>
                  <Input
                    required
                    value={Number(user.numberHourBuy)}
                    step="0.5"
                    type="number"
                    min="0"
                    onChange={handleChangeForm}
                    name="numberHourBuy"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="hourremaing" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Hours remaining</Label>
                  <Label sm={3}>{user.numberHoursRemaining}</Label>
                </FormGroup>
                <FormGroup>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <Label for="professor" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Professor</Label>
                    <Select
                      multiple
                      name="Professors"
                      value={user.Professors}
                      onChange={handleChangeForm}
                      renderValue={selected => (
                        <div className={classes.chips}>
                          {selected.map(value => (
                            <Chip
                              avatar={<Avatar alt={getLabelProfessor(value)}
                                src={getPictureUrlProfessor(value)} />}
                              key={value}
                              label={getLabelProfessor(value)}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {professorDB.filter(ll => (ll.retired == false || ll.retired == null)).sort((a, b) => a.name < b.name ? -1 : 1).map((item, ind) => (
                        <MenuItem
                          key={ind}
                          value={item.id}
                          style={getStyles(
                            item.name,
                            user.Professors,
                            theme
                          )}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
                {(profile.role.indexOf("admin") > -1) ? (
                  <FormGroup>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Label for="role" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Role</Label>
                      <Select
                        multiple
                        name="role"
                        value={user.role != null && user.role.length > 0 ? user.role : []}
                        onChange={handleChangeForm}
                        MenuProps={MenuProps}
                      >
                        {rolesDB[0].role.map((item, ind) => (
                          <MenuItem key={ind}
                            value={item}	>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                  </FormGroup>) : ("")}
              </div>
            )}
            <h4 style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '1.1rem', marginBottom: '25px' }} onClick={toggleMastermind}>
              Mastermind
              {isMastermindOpen ? <FaAngleDown style={{ marginLeft: '5px', marginTop: '7px' }} /> : <FaAngleRight style={{ marginLeft: '5px', marginTop: '7px' }} />}
            </h4>

            {isMastermindOpen && (
  <div>
    <h4 style={{ fontSize: "1.2rem", marginBottom: "15px" }}>Participated Masterminds</h4>
    <div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingLeft: "20px" }}>
      {user.masterminds && user.masterminds.length > 0 ? (
        // Afficher la liste des masterminds auxquels l'utilisateur a participé
        user.masterminds.map(item => (
          <div
            key={item.id}
            style={{
              padding: "10px",
              borderRadius: "6px",
              backgroundColor: "#fafafa",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <strong style={{ fontSize: "1rem", color: "#333" }}>
              {item.name} <span style={{ color: "#666", fontSize: "0.9rem" }}>({new Date(item.dateAdded.seconds * 1000).toLocaleDateString()})</span>
            </strong>
          </div>
        ))
      ) : (
        // Si `masterminds` est vide, afficher le nom du `mastermind` correspondant à l'ID
        mastermindDB
          .filter(mastermind => mastermind.id === user.mastermind)
          .map(mastermind => (
            <div
              key={mastermind.id}
              style={{
                padding: "10px",
                borderRadius: "6px",
                backgroundColor: "#fafafa",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <strong style={{ fontSize: "1rem", color: "#333" }}>{mastermind.name}</strong>
            </div>
          ))
      )}
    </div>
  </div>
)}






            <h4 style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '1.1rem', marginBottom: '25px' }} onClick={toggleCompany}>
              Company
              {isCompanyOpen ? <FaAngleDown style={{ marginLeft: '5px', marginTop: '7px' }} /> : <FaAngleRight style={{ marginLeft: '5px', marginTop: '7px' }} />}
            </h4>

            {isCompanyOpen && (
              <div>
                <FormGroup>
                  <Input
                    value={user.company}
                    type="select"
                    onChange={handleChangeForm}
                    name="company"
                    style={{ fontWeight: "bold", color: "#555", height: "50px" }} // Augmenter la hauteur du champ
                  >
                    <option value="" selected style={{ color: "#999", fontWeight: "bold", height: "50px" }}>
                      Select Company
                    </option>
                    {companyDB.map(item => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </Input>
                  <br></br>
                  <Label for="account" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Account manager of the company</Label>
                  <Input
                    type="select"
                    name="accountManager"
                    id="accountManager"
                    onChange={handleChangeForm}
                    value={user.role != null && user.role != "" ? user.role.some(role => role === "account manager") : ""}
                    style={{ fontWeight: "bold", color: "#555", height: "50px" }} // Augmenter la hauteur du champ
                  >
                    <option value="false" selected style={{ color: "#999", fontWeight: "bold", height: "50px" }}>No</option>
                    <option value="true" style={{ color: "#999", fontWeight: "bold", height: "50px" }}>Yes</option>
                  </Input>

                </FormGroup>
              </div>
            )}

            {isCompanyOpen && (
              <div>
                <FormGroup>
                  <Input
                    value={user.company}
                    type="select"
                    onChange={handleChangeForm}
                    name="company"
                    style={{ fontWeight: "bold", color: "#555", height: "40px" }} // Augmenter la hauteur du champ
                  >
                    <option value="" disabled selected style={{ color: "#999", fontWeight: "bold", height: "40px" }}>
                      Select Company
                    </option>
                    {companyDB.map(item => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </Input>
                  <br></br>
                  <Label for="account" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Account manager of the company</Label>
                  <Input
                    type="select"
                    name="accountManager"
                    id="accountManager"
                    onChange={handleChangeForm}
                    value={user.role != null && user.role != "" ? user.role.some(role => role === "account manager") : ""}>
                    <option value="false" selected>No</option>
                    <option value="true">Yes</option>
                  </Input>

                </FormGroup>
              </div>
            )}
            <h4 style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '1.1rem', marginBottom: '25px' }} onClick={toggleSprint}>
              Sprint
              {isSprintOpen ? <FaAngleDown style={{ marginLeft: '5px', marginTop: '5px' }} /> : <FaAngleRight style={{ marginLeft: '5px', marginTop: '5px' }} />}
            </h4>

            {isSprintOpen && (
              <div>
                <FormGroup>
                  <Label style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Subscribe to Ellipse Sprint</Label>
                  <Input
                    type="select"
                    name="subscribeBootCamp"
                    id="subscribeBootCamp"
                    onChange={handleChangeForm}
                    value={user.subscribeBootCamp}
                    style={{ fontWeight: "bold", color: "#555", height: "50px" }} // Augmenter la hauteur du champ

                  >	<option value="" disabled selected style={{ color: "#999", fontWeight: "bold", height: "50px" }}>
                      Select a value
                    </option>
                    <option>Monthly</option>
                    <option>Yearly</option>
                    <option>Company</option>
                    <option>No</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Sprint Start Date</Label>
                  <Input
                    type="date"
                    min={new Date()}
                    name="bootCampStartDate"
                    id="bootCampStartDate"
                    onChange={handleChangeForm}
                    value={user.bootCampStartDate}
                    style={{ fontWeight: "bold", color: "#555", height: "40px" }} // Augmenter la hauteur du champ

                  />
                </FormGroup>

                <FormGroup>
                  <Label style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Sprint End Date</Label>
                  <Input
                    type="date"
                    min={new Date()}
                    name="bootCampEndDate"
                    id="bootCampEndDate"
                    onChange={handleChangeForm}
                    value={user.bootCampEndDate}
                    style={{ fontWeight: "bold", color: "#555", height: "40px" }} // Augmenter la hauteur du champ

                  />
                </FormGroup>
                <FormGroup>
                  <Label style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Language access Sprint</Label>
                  <Input
                    type="select"
                    name="sprintLanguageAccess"
                    id="sprintLanguageAccess"
                    onChange={handleChangeForm}
                    value={user.sprintLanguageAccess}
                    style={{ fontWeight: "bold", color: "#555", height: "40px" }} // Augmenter la hauteur du champ

                  >	<option value="" disabled selected style={{ color: "#999", fontWeight: "bold", height: "40px" }}>
                      Select a value
                    </option>
                    <option value="en">English</option>
                    <option value="fr">French</option>
                    <option value="all">Both</option>
                  </Input>
                </FormGroup>

              </div>
            )}
            <h4 style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '1.1rem', marginBottom: '25px' }} onClick={toggleConfidence}>
              Confidence objectives
              {isConfidenceOpen ? <FaAngleDown style={{ marginLeft: '5px', marginTop: '5px' }} /> : <FaAngleRight style={{ marginLeft: '5px', marginTop: '5px' }} />}
            </h4>

            {isConfidenceOpen && (
              <div>
                <FormGroup>
                  <br></br>
                  <Label for="confidenceObjective1" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Objective 1</Label>
                  <Input
                    type="textarea"
                    name="confidenceObjective1"
                    id="confidenceObjective1"
                    onChange={handleChangeForm}
                    value={user.confidenceObjective1 == null ? "" : user.confidenceObjective1}
                    rows={2}>
                  </Input>
                  <br></br>
                  <Label for="confidenceObjective2" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Objective 2</Label>
                  <Input
                    type="textarea"
                    name="confidenceObjective2"
                    id="confidenceObjective2"
                    onChange={handleChangeForm}
                    value={user.confidenceObjective2 == null ? "" : user.confidenceObjective2}
                    rows={2}>
                  </Input>
                  <br></br>
                  <Label for="confidenceObjective3" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Objective 3</Label>
                  <Input
                    type="textarea"
                    name="confidenceObjective3"
                    id="confidenceObjective3"
                    onChange={handleChangeForm}
                    value={user.confidenceObjective3 == null ? "" : user.confidenceObjective3}
                    rows={2}>
                  </Input>

                </FormGroup>
              </div>
            )}

            <h4 style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '1.1rem', marginBottom: '75px' }} onClick={toggleActionPlan}>
              Confidence Action Plan
              {isActionPlanOpen ? <FaAngleDown style={{ marginLeft: '5px', marginTop: '5px' }} /> : <FaAngleRight style={{ marginLeft: '5px', marginTop: '5px' }} />}
            </h4>

            {isActionPlanOpen && (
              <div>
                <FormGroup>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Label for="actionPlan" style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>Action</Label>
                      <Input
                        type="textarea"
                        name="confidenceActionsPlan"
                        id="confidenceActionsPlan"
                        onChange={(e) => setNouvelleValeurActionPlan({ ...nouvelleValeurActionPlan, description: e.target.value, id: uuidv4() })}
                        value={nouvelleValeurActionPlan.description}
                        rows={2}
                        style={{ width: '750px' }}
                      />
                    </Grid>
                    <Grid item>
                      <Button color="primary" onClick={ajouterActionPlan}>
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  <ul>
                    {user.actionPlans && user.actionPlans
                      .filter(actionPlan => actionPlan.retired === false || !actionPlan.retired)
                      .map((mapObject, index) => {
                        //const isChecked = (user.actionPlans && user.actionPlans.filter(actionPlanUser => actionPlanUser.id === mapObject.id).length > 0) || false;
                        return (

                          <li
                            key={mapObject.id}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginBottom: '5px',
                              padding: '8px',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: '#f8f8f8',
                              position: 'relative',
                              maxWidth: '750px', // Adjust the width as needed
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <span>{mapObject.description}</span>
                            <IconButton
                              style={{
                                position: 'absolute',
                                top: '50%', // Ajuster le positionnement vertical
                                transform: 'translateY(-50%)', // Centrer verticalement l'icône
                                right: '-40px', // Ajuster cette valeur pour déplacer le bouton vers la droite
                                padding: '8px',

                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Empêcher le comportement par défaut du bouton
                                retireActionPlan(mapObject.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>

                          </li>
                        )
                      })}
                  </ul>
                </FormGroup>
              </div>
            )}


            <Button type="button" style={{ marginRight: "10px" }} disabled={false} onClick={handleSave}>
              Save
            </Button>
            <Button type="button" onClick={() => {
              onClose()
            }}>
              Cancel
            </Button>
          </Form>
        )}

        <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} type={type} id={idTodelete} message={deleteMessage} />

      </div >
    );
  }
});
userAdministration.propTypes = {
  edit: PropTypes.bool,
  profile: PropTypes.object,
  student: PropTypes.bool,
  onCancel: PropTypes.func,
};


export default (userAdministration);



