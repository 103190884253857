import React, { useEffect, useState } from 'react';
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import Card2 from './card.js'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import Container from '@mui/material/Container';
import { Spinner } from "reactstrap";
import RessoucesView from "./RessourcesView.js";
import DeleteConfirmation from "./deleteConfirmation";
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import beta from "./images/Beta.jpg";




function FreeResources(props) {

  const { ressources } = props;

  const [ressourcesState, setRessourcesState] = useState([])
  const [sectionVisibleCategory, setSectionVisibleCategory] = useState(true);
  const [sectionVisibleRessources, setSectionVisibleRessources] = useState(false);
  const [RessourceCategory, setRessourceCategory] = useState(null);
  const handleClickCategory = (ressourceCategory) => {

    setSectionVisibleCategory(false)
    setSectionVisibleRessources(true)
    setRessourceCategory(ressourceCategory)

  }

  const handleUpdateRessource = () => {

    setSectionVisibleCategory(true)
    setSectionVisibleRessources(false)
    setRessourceCategory(null)

  }

    // Handle the displaying of the modal based on type and id
    const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);
    const [deleteMessage, setDeleteMessage] = React.useState(null);
    const [deleteRessource, setDeleteRessource] = React.useState(null);
    const [categoryNameDelete, setCategoryNameDelete] = React.useState(null);
  
    const showDeleteModal = (ressource, categoryName) => {
  
      let title = ressource ? ressource.title : "";
  
      setDeleteMessage(`Are you sure you want to delete the ressource '${title}' in the resource category '${categoryName}' ?`);
  
      setDisplayConfirmationModal(true);
      setDeleteRessource(ressource);
      setCategoryNameDelete(categoryName);
  
    };
  
    // Hide the modal
    const hideConfirmationModal = () => {
      setDisplayConfirmationModal(false);
    };
  
    // Handle the actual deletion of the item

    const handleDeleteClick = async () => {

      // Logique de votre fonction à exécuter lors du clic sur l'action
      const firestore = firebase.firestore();
      let categories = []
      let ressourceTmp = []
      let docid = ""
      await firestore.collection('freeRessources', 'category').get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            docid = doc.id
  
            doc.data().categories.forEach((category) => {
              if (category.name === categoryNameDelete) {
                category.ressources.forEach((ressourceDB) => {
                  if (ressourceDB.uid != deleteRessource.uid)
                    ressourceTmp.push(ressourceDB)
  
                })
                category.ressources = ressourceTmp
                categories.push(category)
  
              } else
                categories.push(category)
  
            })
          });
        })
  
  
      if (docid !== "" && docid !== undefined && categories !== []) {
        firestore.collection('freeRessources').doc(docid).update({
          categories
        }).catch((error) => {
          console.log(error)
        }
        )
      }
      hideConfirmationModal();
      handleUpdateRessource();
    };
  
    const useStyles = makeStyles((theme) => ({
      backButton: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        padding: '8px 24px',
        fontSize: '15px',
      },
    }));

    const classes = useStyles();


  if (!isLoaded(ressources)) {
    return (
      <div className="text-center mt-3">
        <Spinner />
      </div>
    )
  } else {

    return (

      <div className="app" name='upload_file'>
        <img src={beta} width="8%" className="logo" alt="Ellipse"  style={{ marginLeft: '75px',marginTop: '20px' }}/><br></br>
        {sectionVisibleRessources &&<button style={{ marginLeft: '75px',marginTop: '20px' }} onClick={() => {setSectionVisibleCategory(true); setRessourceCategory(null); setSectionVisibleRessources(false)}} className={classes.backButton} startIcon={<ArrowBackIcon />}>Go Back</button>} 
        {sectionVisibleCategory &&
          ressources && 
          <div>
            <Container>
              <Box sx={{ m: 2 }}>
                <Grid container justifyContent="center" spacing="2">
                  {ressources.map((ressource,index) => (
                    ressource.categories.map((ressource,index) => (
                      <Grid key={index} item md={4} >
                        <Card2 title={ressource.title} 
                               description={ressource.description} 
                               urlImage={ressource.urlImage} 
                               onClick={() => handleClickCategory(ressource)} 
                               imageHeight={150} 
                               showDelete={false}
                               showAvatar={false}
                               cardheight = {"300px"}
                               avatarText={[]}
                               isCategories={true}
                           maxLenght={400}

                               />
                      </Grid>
                    ))
                  ))}
                </Grid>
              </Box>
            </Container>
          </div>
        }
        {sectionVisibleCategory && ressources===null  && <div>Il n'y a pas de ressources</div>}
        {sectionVisibleRessources  &&
          <div>
            <RessoucesView ressourceCategory={RessourceCategory} onUpdate={() => handleUpdateRessource()} handleDelete={showDeleteModal}/>
            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDeleteClick} hideModal={hideConfirmationModal} message={deleteMessage} />

          </div>
        }


      </div>

    );
  }
}

const mapToProps = state => {
  return {
    ressources: state.firestore.ordered.freeRessources,
    profile: state.firebase.profile
  };
};

export default compose(
  connect(mapToProps),
  firestoreConnect([{ collection: "freeRessources" }])
)
  (FreeResources);