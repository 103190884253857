const initState = {
    authError: null,
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            console.log('Login Error reducer', action.err)
            return {
                ...state,
                authError: action.err
            }
        case 'LOGIN_SUCCESS':
            console.log('Login Success')
            return {
                ...state,
                authError: null
            }
        case 'LOGIN_CHANGE_SUCCESS':
            console.log('Login Success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Logout Success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_SUCCESS':
            console.log('Signup Success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('Signup Error')
            return {
                ...state,
                authError: action.err.message
            }
        case 'GOOGLE_SIGNIN_SUCCESS':
            console.log('Google Signin Success')
            return {
                ...state,
                authError: null
            }
        case 'GOOGLE_SIGNIN_ERROR':
            console.log('Google Sign Error')
            return {
                ...state,
                authError: action.err.message
            }
        default:
            return state
    }
}

export default authReducer