
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect, useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Carousel } from "react-responsive-carousel";
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { PolarArea } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import { Redirect, Link } from "react-router-dom";

import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    Spinner,
} from "reactstrap";
import SidePanel from "./sidePanel";


ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


class MyConfidence extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            error: false,
            selectStudent: '',
            showGoogleForm: false, // Ajout de l'état pour le formulaire Google
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.toggleGoogleForm = this.toggleGoogleForm.bind(this); // Bind de la fonction toggle
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value

        });

    }
    onFormSubmit(e) {
        e.preventDefault();
    }

    // Fonction pour basculer l'affichage du formulaire Google
    toggleGoogleForm() {
        this.setState((prevState) => ({
            showGoogleForm: !prevState.showGoogleForm
        }));
    }

    // Fonction pour vérifier si le bouton doit être affiché
    canDisplayButton(profile) {
        const hasMastermind = profile.mastermind || false; // Vérifie si l'utilisateur a un mastermind
        const hoursPurchased = profile.hoursPurchased || []; // Vérifie si des heures ont été achetées
        const today = new Date(); // Date actuelle

    const roles = Array.isArray(profile.role) ? profile.role.map(role => role.toLowerCase()) : [];

    // Si l'utilisateur n'est ni admin ni studentadmin (en ignorant la casse), retourner false
    if (roles.indexOf("admin") === -1 && roles.indexOf("studentadmin") === -1) {
        console.log("User is neither admin nor studentadmin (case insensitive). Button will not be displayed.");
        return false;
    }else {
        console.log("User is neither admin nor studentadmin (case insensitive). Button will  be displayed.");
        return true;
    }

        // Vérifie si `hoursPurchased` existe et contient des données
        if (!hoursPurchased || hoursPurchased.length === 0) {
            return false;
        }

        // Calcul du total des heures achetées actives à partir du tableau hoursPurchased
        const totalActiveHours = hoursPurchased.reduce((total, purchase, index) => {
            const startDate = new Date(purchase.datePurchase); // Date de début de l'achat
            const endDate = new Date(purchase.endDate); // Date de fin de l'achat

            // Vérifie si les heures sont actives (la date actuelle doit être comprise entre la date de début et la date de fin)
            if (today >= startDate && today <= endDate) {
                return total + purchase.numberOfHours;
            } else {
                return total; // Ignorer si les heures ne sont pas actives
            }
        }, 0);


        // Condition d'affichage du bouton : 40h actives avec mastermind, 20h actives sans mastermind
        if (hasMastermind && totalActiveHours >= 40) {
            return true;
        } else if (!hasMastermind && totalActiveHours >= 20) {
            return true;
        }

        return false;
    }




    render() {


        const { auth, profile, users, t } = this.props;
        let userIDSearch = ""

        const datatest = {
            labels: ["Spring", "Summer", "Fall", "Winter"],
            datasets: [{
                data: [1200, 1700, 800, 200],
                backgroundColor: [
                    "rgba(255, 0, 0, 0.5)",
                    "rgba(100, 255, 0, 0.5)",
                    "rgba(200, 50, 255, 0.5)",
                    "rgba(0, 100, 255, 0.5)"
                ]
            }]
        };

        const optionst = {
            scales: {
                r: {
                    beginAtZero: true,
                    max: 100 // Set the maximum value
                },
            },
        };


        const googleFormURL = "https://docs.google.com/forms/d/e/1FAIpQLSfZSFslZm7TiLuoEtTJ_4RDxTpJV1EYC7y2iTNNCpXK_rZXww/viewform?usp=pp_url&entry.848336626=" + profile.firstname + " " + profile.lastname + "&entry.307596140=" + profile.email + "&embedded=true";



        if (!auth.uid) {
            return <Redirect to="/signin" />;
        }


        if (!isLoaded(this.props.confidenceResults) || !isLoaded(this.props.confidenceQuiz) || !isLoaded(this.props.roles)) {
            return (
                <div className="text-center mt-3">
                    <Spinner />
                </div>
            )
        } else {


            let userFor = ""
            if (this.state.ConfidenceFor) {
                userIDSearch = this.state.ConfidenceFor

            } else {
                userIDSearch = profile.id
            }

            userFor = users.find(user => user.id === userIDSearch)

            const confidenceResults1 = this.props.confidenceResults.filter(confidenceResult => confidenceResult.idUser === userIDSearch);

            const confidenceQuiz = this.props.confidenceQuiz[0]
            const userSort = [...users];

            // Vérifier si le bouton doit être affiché en fonction des heures achetées
            const displayButton = this.canDisplayButton(profile);


            return (
                <div className="dashboard container mx-auto">

                    <h4 className="my-4" style={{ fontSize: '24px', fontWeight: 'bold', marginTop: '20px', marginBottom: '20px' }}>
                        My confidence wheel results
                    </h4>
                    <div><SidePanel profile={userFor} />
                        {(profile.role != null && profile.role !== undefined && (profile.role.indexOf("admin") > -1 || profile.role.indexOf("professor") > -1)) && (
                            <div>
                                <form onSubmit={(e) => this.onFormSubmit(e)}>
                                    <FormGroup row style={{ marginBottom: '20px' }}>
                                        <Label sm={3} style={{ fontWeight: 'bold' }}>Choose</Label>
                                        <Col sm={9}>
                                            <Input
                                                type="select"
                                                name="ConfidenceFor"
                                                id="ConfidenceFor"
                                                onChange={this.onInputchange}
                                                value={this.state.ConfidenceFor}
                                                style={{ borderRadius: '4px', padding: '5px', maxWidth: '200px' }} // Définir une largeur maximale de 200px
                                            >
                                                <option value="">
                                                    Choose
                                                </option>
                                                {userSort
                                                    .filter(item => this.props.confidenceResults.some(user => user.idUser === item.uid))
                                                    .sort((a, b) => a.firstname.localeCompare(b.firstname))
                                                    .map(item => {
                                                        return <option value={item.uid}>{item.firstname}  {item.lastname} - ({item.email})</option>;
                                                    })}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </form>
                            </div>
                        )
                        }

                        {/* Bouton conditionnel basé sur les heures achetées et le mastermind */}
                        {displayButton && (
                            <>
                                {/* Bouton pour afficher/masquer le Google Form */}
                                <Button color="primary" onClick={this.toggleGoogleForm} style={{ marginBottom: '20px' }}>
                                    {this.state.showGoogleForm ? "Hide" : "Complete My Confidence Wheel Assessment"}
                                </Button>
                            </>
                        )}

                        {/* Affichage conditionnel du formulaire Google */}
                        {this.state.showGoogleForm && (
                            <div style={{ marginBottom: '20px' }}>
                                <iframe
                                    src={googleFormURL}
                                    width="100%"
                                    height="600px"
                                    frameBorder="0"
                                    marginHeight="0"
                                    marginWidth="0"
                                    title="Google Form"
                                    style={{ border: "1px solid #ccc" }}
                                >
                                    Loading...
                                </iframe>
                            </div>
                        )}


                        <div>
                            {confidenceResults1 && confidenceResults1.length > 0 ? (
                                <div>
                                    {confidenceResults1 && confidenceResults1.map((confidenceResult) => {
                                        const options = {
                                            maintainAspectRatio: false,
                                            scales: {
                                                r: {
                                                    beginAtZero: true,
                                                    max: 100 // Set the maximum value
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    position: 'left',
                                                    display: true,
                                                },
                                                datalabels: {
                                                    anchor: 'end',
                                                    backgroundColor: function (context) {
                                                        return context.dataset.backgroundColor;
                                                    },
                                                    borderColor: 'white',
                                                    borderRadius: 25,
                                                    borderWidth: 2,
                                                    color: 'white',
                                                    font: {
                                                        weight: 'bold'
                                                    },
                                                    formatter: Math.round,
                                                    padding: 6
                                                },
                                                responsive: false,
                                                title: {
                                                    display: true,
                                                    text: 'Result from ' + confidenceResult.resultDate,
                                                    font: {
                                                        size: 25
                                                    },

                                                },
                                            },
                                        };
                                        let data = {
                                            labels: [confidenceQuiz.namePilier1 + " (" + confidenceResult.pilier1 + ")",
                                            confidenceQuiz.namePilier2 + " (" + confidenceResult.pilier2 + ")",
                                            confidenceQuiz.namePilier3 + " (" + confidenceResult.pilier3 + ")",
                                            confidenceQuiz.namePilier4 + " (" + confidenceResult.pilier4 + ")",
                                            confidenceQuiz.namePilier5 + " (" + confidenceResult.pilier5 + ")",
                                            confidenceQuiz.namePilier6 + " (" + confidenceResult.pilier6 + ")",
                                            confidenceQuiz.namePilier7 + " (" + confidenceResult.pilier7 + ")"],
                                            datasets: [
                                                {
                                                    label: 'Result',
                                                    data: [confidenceResult.pilier1,
                                                    confidenceResult.pilier2,
                                                    confidenceResult.pilier3,
                                                    confidenceResult.pilier4,
                                                    confidenceResult.pilier5,
                                                    confidenceResult.pilier6,
                                                    confidenceResult.pilier7],
                                                    backgroundColor: [
                                                        'rgba(255, 99, 132, 0.5)',
                                                        'rgba(54, 162, 235, 0.5)',
                                                        'rgba(255, 206, 86, 0.5)',
                                                        'rgba(75, 192, 192, 0.5)',
                                                        'rgba(153, 102, 255, 0.5)',
                                                        'rgba(248, 148, 6, 0.9)',
                                                        'rgba(255, 159, 64, 0.5)',
                                                    ],
                                                    borderWidth: 2,
                                                },
                                            ],
                                        };

                                        return (
                                            <div>
                                                <PolarArea options={options} plugins={[ChartDataLabels]} data={data} width={400}
                                                    height={400} />
                                            </div>



                                        );
                                    })} </div>) : (

                                <div className="center red-text-big" style={{ textAlign: 'center', marginTop: '20px' }}>
                                    {!displayButton && (
                                        <>
                                            Welcome! Notre 'Wheel of Confidence' est un outil d'évaluation unique et novateur que nous déploierons sous peu. Envie de participer aux essais en mode BETA ? Envoyez-nous un message ou dites-le à vos coachs. Ça en vaudra grandement la peine!
                                        </>
                                    )}

                                </div>


                            )}

                        </div>
                    </div>

                </div >

            );
        }
    }
}



const mapState = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        users: state.firestore.ordered.users,
        confidenceResults: state.firestore.ordered.confidenceResults,
        confidenceQuiz: state.firestore.ordered.confidenceQuiz,
        roles: state.firestore.ordered.roles,
    };
};



export default compose(
    firestoreConnect([{ collection: "users" }, { collection: "confidenceResults" }, { collection: "confidenceQuiz" }, { collection: "roles" }]),
    connect(mapState)
)(MyConfidence);

