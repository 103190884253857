import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import DataTable from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import firebase from "firebase/app";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import {
    FormGroup,
    Label,
    Button,
    Modal, ModalHeader, ModalBody,
} from "reactstrap";
import input from "./input";
import AddStudent from "./addStudentModal2";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import DeleteConfirmation from "./deleteConfirmation";
import './DatePickerCustom.css';
import Holidays from 'date-holidays';
import emailjs from '@emailjs/browser';

const CustomDatePicker = ({ field, form }) => {
    // Vérifie le type de `field.value` pour le traiter correctement
    const dateValue = field.value
        ? typeof field.value === 'string' 
            ? moment(field.value, 'YYYY-MM-DD').toDate() // Si c'est une chaîne, la convertir
            : field.value.toDate ? field.value.toDate() // Si c'est un timestamp Firestore, utilise toDate()
            : new Date(field.value) // Si c'est un timestamp standard
        : null;


    return (
        <DatePicker
            selected={dateValue}
            onChange={(date) => {
                // Convertit la date en chaîne au format 'YYYY-MM-DD'
                const formattedDate = moment(date).format('YYYY-MM-DD');

                // Stocke la date formatée dans le formulaire
                form.setFieldValue(field.name, formattedDate);
            }}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select Start Date"
            className="custom-date-picker"
            popperPlacement="bottom-end"
        />
    );
};


// Initialisation des jours fériés pour le Québec
const hd = new Holidays('CA', 'QC');

const generateEventWeeks = (startDate, duration) => {
    const weeks = [];
    let currentDate = moment(startDate);
    let weeksAdded = 0;

    while (weeksAdded < duration) {
        const formattedDate = currentDate.format("YYYY-MM-DD");

        // Vérifie automatiquement si la date est un jour férié au Québec
        const isHoliday = !!hd.isHoliday(new Date(formattedDate));

        weeks.push({
            date: formattedDate,
            presentationLink: "",
            subject: isHoliday ? "Congé" : "",
            isHoliday: isHoliday, // Indique si c'est un congé
        });

        // Si c'est un congé, ajoute une semaine en fin de durée
        if (isHoliday) {
            duration += 1;
        }

        // Passe à la semaine suivante, même jour de la semaine
        currentDate = currentDate.add(1, "week");
        weeksAdded++;
    }

    return weeks;
};

function MastermindsList(props) {
    const [isAddStudent, setIsAddStudent] = useState(false);
    const [isModifyStudent, setIsModifyStudent] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [idRowClicked, setIdRowClicked] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [isModify, setIsModify] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [type, setType] = useState(null);
    const [idTodelete, setIdTodelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Log chaque fois que isAddStudent change
    useEffect(() => {
    }, [isAddStudent]);

    // Fonction pour ajouter ou modifier un étudiant dans Firestore
    const onSaveStudent = (student, isModifyStudent) => {

        const updatedParticipants = isModifyStudent
            ? mastermindObj.participants.map((participant) =>
                participant.email === student.email ? student : participant
            )
            : [...mastermindObj.participants, student];

        setMastermindObj((prev) => ({
            ...prev,
            participants: updatedParticipants,
        }));


        setIsAddStudent(false); // Ferme la modale
        setIsModifyStudent(false);
    };


    const updateParticipants = async (mastermindId, mastermindName, participants,startDate) => {
        const firestore = firebase.firestore();

        for (const participant of participants) {
            // Vérifie si l'utilisateur existe déjà
            const userSnapshot = await firestore.collection("users").where("email", "==", participant.email).get();

            const dateAdded = new Date(); // Obtenir la date actuelle

            if (!userSnapshot.empty) {
                // Si l'utilisateur existe
                const userDoc = userSnapshot.docs[0];
                const userId = userDoc.id;
                const userData = userDoc.data();

                // Vérifie si le mastermind existe déjà
                const masterminds = userData.masterminds || [];
                const mastermindExists = masterminds.some((m) => m.id === mastermindId);

                if (!mastermindExists) {
                    // Ajoute l'ID du mastermind, le nom et la date au champ 'masterminds' si non présent
                    await firestore.collection("users").doc(userId).update({
                        masterminds: firebase.firestore.FieldValue.arrayUnion({
                            id: mastermindId,
                            name: mastermindName,  // Ajout du nom du mastermind
                            dateAdded: dateAdded,
                        }),
                    });
                }
            } else {
                console.log("User does not exist:", participant);
                // Si l'utilisateur n'existe pas, crée un nouvel utilisateur avec l'ID du mastermind, le nom et la date
                await firestore.collection("users").add({
                    firstname: participant.firstName,
                    lastname: participant.lastName,
                    email: participant.email,
                    masterminds: [
                        {
                            id: mastermindId,
                            name: mastermindName,  // Ajout du nom du mastermind
                            dateAdded: dateAdded,
                        },
                    ],
                });

                /*var templateParams = {
                    name: participant.firstname,
                    to_email: 'Isabelle@groupellipse.com',
                    to_name: participant.firstName,
                    mm_name: mastermindName,
                    start_date: startDate,
                };

                emailjs.send('service_x9s33ur', 'template_mm', templateParams, '5Sygx-dSIpMv3Ykh2')
                    .then(function(response) {
                       console.log('SUCCESS!', response.status, response.text);
                    }, function(error) {
                       console.log('FAILED...', error);
                    });*/
            }
        }
    };



    // Afficher la modale pour ajouter un étudiant
    const showAddStudentModal = () => {
        setSelectedStudent(null);
        setIsAddStudent(true);
        setIsModifyStudent(false);
    };

    // Afficher la modale pour modifier un étudiant
    const showModifyStudentModal = (student) => {
        setSelectedStudent(student);
        setIsAddStudent(true);
        setIsModifyStudent(true);
    };

    // Supprimer un étudiant
    const deleteStudent = () => {
        const updatedParticipants = mastermindObj.participants.filter(
            participant => participant.email !== selectedStudent.email
        );
        setMastermindObj(prev => ({
            ...prev,
            participants: updatedParticipants,
        }));

        // Mise à jour Firestore
        const firestore = firebase.firestore();
        firestore.collection("masterminds").doc(idRowClicked).update({
            participants: updatedParticipants
        }).catch(error => console.log("Erreur lors de la suppression de l'étudiant :", error));

        setDisplayConfirmationModal(false); // Fermer la modale de confirmation
    };

    // Afficher la modale de confirmation de suppression
    const showDeleteConfirmation = (student) => {
        setSelectedStudent(student);
        setDeleteMessage(`Are you sure you want to delete ${student.firstName} ${student.lastName}?`);
        setDisplayConfirmationModal(true);
    };

    const [mastermindObj, setMastermindObj] = useState({
        duration: "",
        eventWeeks: [],
        name: "",
        participants: [],
        startDate: "",
        createdTime: "",
        updatedTime: "",
    });

    // Fonction pour ajouter un objet week
    const addWeekObj = () => {
        const newWeek = { date: "", presentationLink: "", subject: "" };
        setMastermindObj((prevMastermindObj) => ({
            ...prevMastermindObj,
            eventWeeks: [...prevMastermindObj.eventWeeks, newWeek],
        }));
    };

    const addRow = () => {
        setIsAdd(true);
        setIdRowClicked(null); // Réinitialise l'ID pour une nouvelle entrée
    
        // Créer un nouvel objet sans `id`
        const newMastermindObj = {
            name: "",
            createdTime: new Date(),
            updatedTime: new Date(),
            duration: 0,
            eventWeeks: [],
            participants: [],
            startDate: new Date(),
        };
    
        setMastermindObj(newMastermindObj);
    };
    const onRowClickedDelete = (row) => {
        showDeleteModal(row.name, row.id);
    };

    const deleteParticipant = async (participant) => {
        const firestore = firebase.firestore();

        // Filtrer les participants pour exclure celui à supprimer
        const updatedParticipants = mastermindObj.participants.filter(
            (p) => p.email !== participant.email
        );

        // Mettre à jour l'état local
        setMastermindObj((prev) => ({
            ...prev,
            participants: updatedParticipants,
        }));

        // Mettre à jour Firestore pour le mastermind
        await firestore.collection("masterminds").doc(idRowClicked).update({
            participants: updatedParticipants,
        }).catch(error => console.error("Erreur lors de la suppression du participant du mastermind :", error));

        // Retirer l'ID du mastermind dans le document de l'utilisateur
        try {
            const userSnapshot = await firestore.collection("users").where("email", "==", participant.email).get();
            if (!userSnapshot.empty) {
                const userId = userSnapshot.docs[0].id;
                const userData = userSnapshot.docs[0].data();

                // Filtrer pour retirer l'objet contenant l'ID du mastermind
                const updatedMasterminds = (userData.masterminds || []).filter(
                    (m) => m.id !== idRowClicked
                );

                // Mettre à jour le champ 'masterminds' avec le tableau filtré
                await firestore.collection("users").doc(userId).update({
                    masterminds: updatedMasterminds
                });
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du mastermind de l'utilisateur :", error);
        }
    };


    const showDeleteParticipantConfirmation = (participant) => {
        setDeleteMessage(`Are you sure you want to delete ${participant.firstName} ${participant.lastName}?`);
        setSelectedStudent(participant);
        setDisplayConfirmationModal(true);
    };

    const confirmDeleteParticipant = () => {
        deleteParticipant(selectedStudent);
        setDisplayConfirmationModal(false);
    };

    const onRowClicked = (row) => {
        setIsModify(true);
        setIsAdd(false);
        setMastermindObj({
            ...row, // Charge les données du mastermind sélectionné pour l'édition
        });
        setIdRowClicked(row.id); // Garde la trace de l'ID pour une mise à jour future

    };

    const showDeleteModal = (name, id) => {
        setDeleteMessage(`Are you sure you want to delete the mastermind '${name}'?`);
        setIdTodelete(id);
        setDisplayConfirmationModal(true);
    };

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const submitDelete = () => {
        deleteMastermind(idTodelete);
        setDisplayConfirmationModal(false);
    };

    const hideStudent = () => {
        setIsAddStudent(false);
    };


    const handleSubmit = async (values) => {
        // Génère `eventWeeks` uniquement si on est en mode ajout
        const eventWeeks = isAdd ? generateEventWeeks(values.startDate, values.duration) : mastermindObj.eventWeeks;

        const updatedMastermindObj = {
            ...mastermindObj,
            ...values,
            eventWeeks: eventWeeks,
            participants: mastermindObj.participants || [],
            createdTime: mastermindObj.createdTime || firebase.firestore.Timestamp.now(),
            updatedTime: firebase.firestore.Timestamp.now(),
            startDate: values.startDate,
        };

        let mastermindId;

        if (isAdd) {
            mastermindId = await createMastermind(updatedMastermindObj);
        } else if (isModify) {
            mastermindId = await updateMastermind(updatedMastermindObj);
        }

        // Met à jour chaque participant avec l'ID du mastermind
        await updateParticipants(mastermindId, updatedMastermindObj.name, updatedMastermindObj.participants,updatedMastermindObj.startDate);

        setIsAdd(false);
        setIsModify(false);
    };



    const createMastermind = async (mastermindObj) => {
        const firestore = firebase.firestore();
        const docRef = await firestore.collection("masterminds").add(mastermindObj);
        return docRef.id; // Retourne l'ID du document créé
    };

    const updateMastermind = async (mastermindObj) => {
        const firestore = firebase.firestore();
        await firestore.collection("masterminds").doc(idRowClicked).update(mastermindObj);
        return idRowClicked; // Retourne l'ID du document existant
    };


    const deleteMastermind = (id) => {
        const firestore = firebase.firestore();
        return firestore.collection("masterminds").doc(id).delete()
            .catch(error => console.log(error.message));
    };


    const columnsMaterminds = [
        {
            cell: (row) => (
                <div>
                    <i className="icon-container fas fa-trash" onClick={() => onRowClickedDelete(row)} />
                </div>
            ),
            width: "50px",
        },
        {
            cell: (row) => (
                <div>
                    <i className="icon-container fas fa-edit" onClick={() => onRowClicked(row)} />
                </div>
            ),
            width: "50px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Start Date',
            selector: row => moment(row.startDate.toDate ? row.startDate.toDate() : row.startDate).format('YYYY-MM-DD'),
            sortable: true,
            width: "200px",
        },
        {
            name: 'Duration (weeks)',
            selector: row => row.duration,
            sortable: true,
            width: "300px",
        },
    ];

    const schemaMastermind = yup.object({
        name: yup.string().required("Name is required"),
        duration: yup.number().required("Duration is required").min(1, "Duration must be at least 1"),
        startDate: yup.date().required("Start Date is required"),
    });

    const columnsEventWeeks = [
        {
            cell: (row) => (
                <div>
                    <i className="icon-container fas fa-trash" onClick={() => onRowClickedDeleteEvent(row)} />
                </div>
            ),
            width: "50px",
        },
        {
            cell: (row) => (
                <div>
                    <i className="icon-container fas fa-edit" onClick={() => onRowClickedEvent(row)} />
                </div>
            ),
            width: "50px",
        },
        {
            name: 'Date',
            selector: week => moment(week.date).format('YYYY-MM-DD'),
            sortable: true,
            width: "150px",
        },
        {
            name: 'Day of the Week',
            selector: week => moment(week.date).format('dddd'),
            sortable: true,
            width: "150px",
        },
        {
            name: 'Presentation Link',
            selector: week => week.presentationLink || "-",
            width: "200px",
        },
        {
            name: 'Subject',
            selector: week => week.subject || "-",
            width: "200px",
        },
    ];

    const columnsParticipants = [
        {
            cell: (row) => (
                <div>
                    <i className="icon-container fas fa-trash" onClick={() => showDeleteParticipantConfirmation(row)} />
                </div>
            ),
            width: "50px",
        },
        {
            cell: (row) => (
                <div>
                    <i className="icon-container fas fa-edit" onClick={() => showModifyStudentModal(row)} />
                </div>
            ),
            width: "50px",
        },
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "300px",
        },
    ];

    const { masterMindDB, usersDB, profile } = props;
    const AddStudentModal = props => {
        const { isOpen } = props;
        // If we only put the modal in the render tree when it's open, multiple modals
        // will open in the expected order
        return isOpen ? <Modal {...props} /> : null;
    };

    if (!isLoaded(masterMindDB) || !isLoaded(profile) || !isLoaded(usersDB)) {
        return (
            <div className="text-center mt-3">
                <Spinner />
            </div>
        );
    } else {
        return (
            <div className="dashboard container">
                <center>
                    <h4 className="my-4">Masterminds</h4>
                    <div align="right">
                        <i className="icon-container fas fa-plus-circle" onClick={addRow}>&nbsp;Add</i>
                    </div>
                </center>
                {(isAdd || isModify) && (
                    <Formik
                        initialValues={{
                            name: mastermindObj.name || "",
                            startDate: mastermindObj.startDate || "",
                            duration: mastermindObj.duration || "",
                            participants: mastermindObj.participants || [],
                            eventWeeks: mastermindObj.eventWeeks || [],
                        }}
                        validationSchema={schemaMastermind} // Ajout du schéma de validation ici
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit, values, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label>Name of the mastermind</Label>
                                    <Field name="name" type="text" component={input} placeholder="Name" />
                                </FormGroup>
                                <FormGroup className="start-date-label">
                                    <Label className="start-date-input">Start Date</Label>
                                    <Field name="startDate" component={CustomDatePicker} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Duration (weeks)</Label>
                                    <Field
                                        name="duration"
                                        type="number"
                                        component={input}
                                        placeholder="Enter Duration in weeks"
                                    />
                                    {values.duration > 30 && (
                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                            Warning: Duration exceeds recommended maximum of 12 weeks.
                                        </div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Participant for the Mastermind</b></Label>
                                    <i className="icon-container fas fa-plus-circle" onClick={() => setIsAddStudent(true)} />
                                    <DataTable columns={columnsParticipants} data={mastermindObj.participants} />
                                </FormGroup>
                                <FormGroup>
                                    <Label><b>Event weeks</b></Label>
                                    <i className="icon-container fas fa-plus-circle" onClick={() => addWeekObj()} />
                                    <DataTable columns={columnsEventWeeks} data={mastermindObj.eventWeeks} />
                                </FormGroup>
                                <Button type="submit" style={{ marginRight: "10px" }}>Save</Button>
                                <Button type="button" onClick={() => { setIsAdd(false); setIsModify(false); }}>Cancel</Button>
                            </Form>
                        )}
                    </Formik>
                )}
                {/* Modale pour Ajouter ou Modifier un étudiant */}
                <Modal isOpen={isAddStudent} toggle={() => setIsAddStudent(false)} size="lg">
                    <ModalHeader toggle={() => setIsAddStudent(false)}>
                        {isModifyStudent ? "Modify Student" : "Add Student"}
                    </ModalHeader>
                    <ModalBody>
                        {errorMessage && (
                            <div style={{ color: 'red', marginBottom: '10px' }}>
                                {errorMessage}
                            </div>
                        )}
                        <AddStudent
                            onCancel={() => setIsAddStudent(false)}
                            onSaveStudent={onSaveStudent}  // Utilisation de onSaveStudent
                            student={selectedStudent}
                            displayAddStudent={isAddStudent}
                            isModify={isModifyStudent}
                            existingUsers={mastermindObj.participants}
                        />
                    </ModalBody>
                </Modal>
                {!isAdd && !isModify && (

                    <DataTable
                        columns={columnsMaterminds}
                        data={masterMindDB.map((item, index) => ({ ...item, uniqueKey: `${item.id}-${index}` }))} // Ajouter une clé unique
                        pagination
                        highlightOnHover
                        striped
                        keyField="uniqueKey" // Utilisez uniqueKey comme champ de clé
                    />
                )}

                <DeleteConfirmation
                    showModal={displayConfirmationModal}
                    confirmModal={selectedStudent ? confirmDeleteParticipant : submitDelete}
                    hideModal={hideConfirmationModal}
                    message={deleteMessage}
                    btnLabel="Delete"
                />

            </div>
        );
    }
}

const mapToProps = state => ({
    masterMindDB: state.firestore.ordered.masterminds,
    usersDB: state.firestore.ordered.users,
    profile: state.firebase.profile,
});

export default compose(
    connect(mapToProps),
    firestoreConnect([{ collection: "masterminds" }, { collection: "users" }])
)(MastermindsList);
