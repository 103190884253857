import React, { Component } from "react";
import ProfessorList from "../professors/ProfessorList";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { withTranslation } from 'react-i18next';

class Dashboard extends Component {
	render() {
		const { professors, auth, profile, bootCamp,t } = this.props;

		let userProfessors = [];
		let profLoaded = false;
		if (profile.Professors && professors) {
			profile.Professors.forEach(p => {
				const isProfessor = professors.find(prof => prof.id === p);
				if (isProfessor) {
					userProfessors.push(isProfessor);
				}
			});
			profLoaded = true;
		}
		let userBootCamp
		if (bootCamp) {
			userBootCamp = bootCamp.find(boot => boot.id === profile.bootCamp);
		}

		if (!auth.uid) {
			return <Redirect to="/signin" />;
		}

		if (!isLoaded(professors) || !profLoaded) {
			return (
				<div className="text-center mt-3">
					<Spinner />
				</div>
			)
		} else {
			if ((userProfessors && userProfessors.length > 0) || this.props.location.pathname==="/coaches") {
				return (
					<div className="dashboard container">
						<div className="row">
							<div className="col s12">
								<ProfessorList
									professors={userProfessors}
									profile={profile}
									loaded={isLoaded(professors) && profLoaded}
									bootCamp={userBootCamp}
								/>
							</div>
						</div>
						<br></br>
											<center><b>{this.props.t('sprint_need_help')}<a href="mailto:hello@groupellipse.com"> hello@groupellipse.com</a></b></center>
											<br></br>
					</div>
				);
			}
			return <Redirect to="/sprint" />;
		}
	}
}

const mapState = state => {
	return {
		bootCamp: state.firestore.ordered.bootCamp,
		professors: state.firestore.ordered.professor,
		sprints: state.firestore.ordered.sprints,
		sprintsHoliday: state.firestore.ordered.sprintsHoliday,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		roles: state.firebase.roles,
	};
};

export default compose(
	connect(mapState),
	firestoreConnect([{ collection: "professor" }, { collection: "users" }, { collection: "bootCamp" }, { collection: "sprints" }, { collection: "sprintsHoliday" },{ collection: "roles" }]),
	withTranslation()
)(Dashboard);
