import fire from "firebase/app";
require("firebase/firestore");

export const fetchCompany = () => async dispatch => {
	const firestore = fire.firestore();
	firestore.collection("company").onSnapshot(querySnapshot => {
		let temp_arrs = [];
		querySnapshot.forEach(doc => {
			temp_arrs.push({ ...doc.data(), id: doc.id });
		});
		dispatch({
			payload: temp_arrs,
			type: "FETCH_COMPANY",
		});
	});
};

export const fetchBootCamp= () => async dispatch => {
	const firestore = fire.firestore();
	firestore.collection("bootCamp").onSnapshot(querySnapshot => {
		let temp_arrs = [];
		querySnapshot.forEach(doc => {
			temp_arrs.push({ ...doc.data(), id: doc.id });
		});
		dispatch({
			payload: temp_arrs,
			type: "FETCH_BOOT_CAMP",
		});
	});
};

export const fetchRoles= () => async dispatch => {
	const firestore = fire.firestore();
	firestore.collection("roles").onSnapshot(querySnapshot => {
		let temp_arrs = [];
		querySnapshot.forEach(doc => {
			temp_arrs.push({ ...doc.data(), id: doc.id });
		});
		dispatch({
			payload: temp_arrs,
			type: "FETCH_ROLES",
		});
	});
};


export const fetchUsers = () => async dispatch => {
	const firestore = fire.firestore();
	firestore.collection("users").onSnapshot(querySnapshot => {
		let temp_arrs = [];
		querySnapshot.forEach(doc => {
			temp_arrs.push({ ...doc.data(), id: doc.id });
		});
		dispatch({
			payload: temp_arrs,
			type: "FETCH_USERS",
		});
	});
};

export const searchUsers = searchFields => dispatch => {};

export const fetchMastermind = () => async dispatch => {
	const firestore = fire.firestore();
	firestore.collection("mastermind").onSnapshot(querySnapshot => {
		let temp_arrs = [];
		querySnapshot.forEach(doc => {
			temp_arrs.push({ ...doc.data(), id: doc.id });
		});
		dispatch({
			payload: temp_arrs,
			type: "FETCH_MASTER_MIND",
		});
	});
};


export const fetchProfessor = () => async dispatch => {
	const firestore = fire.firestore();
	firestore.collection("professor").onSnapshot(querySnapshot => {
		let temp_arrs = [];
		querySnapshot.forEach(doc => {
			temp_arrs.push({ ...doc.data(), id: doc.id });
		});
		dispatch({
			payload: temp_arrs,
			type: "FETCH_PROFESSOR",
		});
	});
};

export const fetchContracts = () => async dispatch => {
	const firestore = fire.firestore();
	firestore.collection("contracts")
	.onSnapshot(querySnapshot => {
		let temp_arrs = [];
		querySnapshot.forEach(doc => {
			temp_arrs.push({ ...doc.data(), id: doc.id });
		});
		dispatch({
			payload: temp_arrs,
			type: "FETCH_CONTRACTS",
		});
	});
};
